.container-input-search-veiculo-carroceria {
  width: 100%;
  margin-top: 20px;
}

.container-input-search-veiculo-carroceria .input-search-veiculo-carroceria {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 10px;
  background-color: #f1f4fa;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-input-search-veiculo-carroceria .input-search-veiculo-carroceria input {
  flex: 1;
  border: none;
  background-color: #f1f4fa;
}

.container-input-search-veiculo-carroceria .input-search-veiculo-carroceria input::placeholder {
  color: gray;
}

.container-input-search-veiculo-carroceria .input-search-veiculo-carroceria .icone {
  margin-left: 5px;
  font-size: 22px;
  color: #9ab2c6;
}

.container-input-search-veiculo-carroceria .no-suggestions {
  color: #9ab2c6;
  padding: 0.5rem;
}

.container-input-search-veiculo-carroceria .suggestions {
  border: 1px solid #9ab2c6;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 160px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
}

.container-input-search-veiculo-carroceria .suggestions li {
  padding: 0.5rem;
}

.container-input-search-veiculo-carroceria .suggestion-active,
.container-input-search-veiculo-carroceria .suggestions li:hover {
  cursor: pointer;
  background-color: #f1f4fa;
}

.container-input-search-veiculo-carroceria .suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #9ab2c6;
}

@media (max-width: 670px) {
  .container-input-search-veiculo-carroceria {
    width: 100%;
  }
}