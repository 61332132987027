.container-cadastro-simplificado-motorista {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-cadastro-simplificado-motorista .btn-voltar-pagina {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  font-weight: bold;
  margin-bottom: 20px;
}

.container-cadastro-simplificado-motorista .btn-voltar-pagina:hover {
  filter: brightness(120%);
}

.container-cadastro-simplificado-motorista .novo-motorista .titulo-pagina {
  font-size: 20px;
  font-weight: bold;
}

.container-cadastro-simplificado-motorista .novo-motorista .subtitulo-pagina {
  margin-top: 5px;
  font-weight: bold;
  color: #001b48;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria {
  margin-top: 30px;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .titulo-categoria {
  font-size: 16px;
  font-weight: bold;
  color: #9ab2c6;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria label.input-file .container-foto-perfil {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  width: 225px;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria label.input-file .container-foto-perfil img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria label.input-file .container-foto-perfil .foto {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  background-color: white;
  border: 1px solid #9ab2c6;
  border-radius: 10px;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria label.input-file .container-foto-perfil p {
  margin-left: 15px;
  font-weight: bold;
  color: #001b48;
  cursor: pointer;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria label.input-file .container-foto-perfil p:hover {
  text-decoration: underline;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria label.input-file input {
  display: none;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input {
  position: relative;
  margin-top: 20px;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input input,
.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input select {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input input[type="date"]:invalid::-webkit-datetime-edit {
  color: transparent;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input input[type="file"]:disabled {
  color: transparent;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input input[type="file"]:disabled::-webkit-file-upload-button {
  display: none;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input input[type="date"] + span {
  top: 16px;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input select {
  padding: 11.6px 10px;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input span {
  position: absolute;
  top: 14px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input span i {
  color: red;
  font-style: normal;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input input:focus + span,
.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input select:focus + span,
.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input input:valid + span,
.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input select:valid + span,
.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input input:disabled + span,
.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input select:disabled + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input .nome-anexo {
  font-size: 12px;
  cursor: pointer;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input .nome-anexo:hover {
  text-decoration: underline;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-inputs-1col {
  width: 45%;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-inputs-2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-fotos-caminhao {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-fotos-caminhao .foto-caminhao {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  object-fit: cover;
  pointer-events: all;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input-checkbox {
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input-checkbox {
  font-weight: bold;
  user-select: none;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input-checkbox .checkbox {
  cursor: pointer;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input-checkbox .checkbox input[type="checkbox"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.container-cadastro-simplificado-motorista .novo-motorista .categoria .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  margin-right: 10px;
}

.container-cadastro-simplificado-motorista .novo-motorista .container-botoes {
  display: flex;
  justify-content: center;
  margin: 80px auto 20px auto; 
}

.container-cadastro-simplificado-motorista .novo-motorista .container-botoes button {
  width: 250px;
  padding: 12px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

.container-cadastro-simplificado-motorista .novo-motorista .container-botoes button:hover {
  filter: brightness(120%);
}

.container-cadastro-simplificado-motorista .novo-motorista .container-botoes button.btn-cancelar {
  margin-right: 30px;
}

.container-cadastro-simplificado-motorista .novo-motorista .container-botoes button.btn-cadastrar {
  background-color: #001b48;
  border-color: #001b48;
  color: #fff;
}

.container-alert-adicionar {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 30px;
  animation-duration: 0.2s;
}

.container-alert-adicionar h1 {
  text-align: center;
  margin-bottom: 30px;
}

.container-alert-adicionar p {
  margin-bottom: 10px;
  font-weight: bold;
  color: #001b48;
}

.container-alert-adicionar input {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-alert-adicionar .container-btn-grupo-permissoes {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 40px;
}

.container-alert-adicionar .container-btn-grupo-permissoes button {
  width: 180px;
  padding: 12px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

.container-alert-adicionar .container-btn-grupo-permissoes button:hover {
  filter: brightness(120%);
}

.container-alert-adicionar .container-btn-grupo-permissoes button.btn-adicionar {
  background-color: #001b48;
  border-color: #001b48;
  color: #fff;
}

.container-cadastro-simplificado-motorista .novo-motorista .msg-erro {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.container-visualizar-foto-caminhao {
  width: 90%;
  height: 90%;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 30px;
  animation-duration: 0.2s;
}

.container-visualizar-foto-caminhao img {
  height: 100%;
  width: 100%;
}

.container-visualizar-foto-caminhao .container-btn-visualizar-foto-caminhao {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.container-visualizar-foto-caminhao .container-btn-visualizar-foto-caminhao button {
  width: 180px;
  padding: 12px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

.container-visualizar-foto-caminhao .container-btn-visualizar-foto-caminhao button:hover {
  filter: brightness(120%);
}

@media (max-width: 670px) {
  .container-cadastro-simplificado-motorista {
    padding: 25px 10px;
  }
  .container-cadastro-simplificado-motorista .novo-motorista .categoria .container-inputs-2col {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
  .container-cadastro-simplificado-motorista .novo-motorista .categoria .container-inputs-1col {
    width: 100%;
  }
  .container-cadastro-simplificado-motorista .novo-motorista .categoria .container-fotos-caminhao {
    margin-top: 0;
    flex-wrap: wrap;
  }
  .container-cadastro-simplificado-motorista .novo-motorista .categoria .container-fotos-caminhao .foto-caminhao {
    margin-top: 10px;
  }
  .container-alert-adicionar {
    width: 100%;
  }
  .container-alert-adicionar .container-btn-grupo-permissoes {
    flex-direction: column;
  }
  .container-alert-adicionar .container-btn-grupo-permissoes button {
    width: 100%;
  }
  .container-alert-adicionar .container-btn-grupo-permissoes button:first-of-type {
    margin-bottom: 10px;
  }
}

@media (max-width: 470px) {
  .container-cadastro-simplificado-motorista .novo-motorista .container-botoes {
    flex-direction: column;
    margin: 80px auto 10px auto; 
  }
  .container-cadastro-simplificado-motorista .novo-motorista .container-botoes button {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 450px) {
  .container-cadastro-simplificado-motorista .novo-motorista .categoria .container-search {
    flex-direction: column;
    width: 100%;
  }
  .container-cadastro-simplificado-motorista .novo-motorista .categoria .container-search .input-search {
    width: 100%;
  }
  .container-cadastro-simplificado-motorista .novo-motorista .categoria .container-search .btn-buscar {
    width: 100%;
    background-color: #001b48;
    margin: 10px 0 0 0;
  }
}