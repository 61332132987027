.dashboard-page {
  margin: 10px 10px 15px 0;
  flex: 1;

  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;

  overflow: hidden;

  .dashboard-inner {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
