.container-input-search-cidades {
  margin: 20px 0 10px 0;
}

.container-input-search-cidades .input-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 10px 9px 10px;
  background-color: #f1f4fa;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-input-search-cidades .input-search .icone-ajuda {
  color: #bacad9;
  margin-right: 5px;
}

.container-input-search-cidades .input-search input {
  flex: 1;
  border: none;
  background-color: #f1f4fa;
}

.container-input-search-cidades .input-search input::placeholder {
  color: gray;
}

.container-input-search-cidades .input-search span {
  position: absolute;
  top: 14px;
  left: 9px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.container-input-search-cidades .input-search span i {
  color: red;
  font-style: normal;
}

.container-input-search-cidades .input-search input:focus+span,
.container-input-search-cidades .input-search input:disabled+span,
.container-input-search-cidades .input-search input:valid+span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.container-input-search-cidades .input-search .icone {
  margin-left: 5px;
  font-size: 22px;
  color: #9ab2c6;
}

.container-input-search-cidades .no-suggestions {
  color: #9ab2c6;
  padding: 0.5rem;
}

.container-input-search-cidades .suggestions {
  border: 1px solid #9ab2c6;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 160px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
}

.container-input-search-cidades .suggestions li {
  padding: 0.5rem;
  display: flex;
  column-gap: 30px;
}

.container-input-search-cidades .suggestions .suggestion-name {
  max-width: 330px;
  width: 100%;
  display: inline-block;
}

.container-input-search-cidades .suggestion-active,
.container-input-search-cidades .suggestions li:hover {
  cursor: pointer;
  background-color: #f1f4fa;
}

.container-input-search-cidades .suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #9ab2c6;
}