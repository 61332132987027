.modal-tutorial-transacoes {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 75vh;
    width: 75%;
    background-color: #fff;
    border-radius: 5px;
    overflow: auto;
    z-index: 99;
}

.modal-tutorial {
    height: 80%;
}

.modal-tutorial-header {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #018abe;
    gap: 10px;
}

.modal-tutorial-header .tag {
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 16px;
    border-radius: 5px;
    margin-right: 20px;
}

.modal-tutorial-header h2 {
    flex: 1;
    color: #fff;
    margin-left: 20px;
}

.modal-tutorial-header button {
    margin-right: 20px;
    background-color: #018abe;
    border: none;
    color: #fff;
    font-size: 20px;
}

.modal-tutorial-body {
    height: 100%;
    overflow-y: scroll;
}

.modal-tutorial-body-item {
    margin: 15px;
    margin: 15px;
    border: 1px solid rgb(89 89 89 / 42%);
    min-height: 100%;
    border-radius: 3px;
}

.modal-tutorial-body-item-container-videos {
    margin: 0 25px;
}

.modal-tutorial-body-item-header {
    padding: 15px 20px;
    background-color: #3d8af7;
    color: #fff;
}

.modal-tutorial-body-item-header-conclude {
    margin: 30px 58px 15px;
    color: #5c5c5c;
    font-weight: 500;
    font-size: 1rem;
}

.modal-tutorial-body-item-videos {
    display: flex;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 15px 20px;
}

.body-item-green{
    background: #F6F6F6;
}

.body-item-ligth{
    background: #fff;
}

.modal-tutorial-body-item-videos-propriedades {
    display: flex;
    max-width: 300px;
    align-items: center;
    width: 100%;
    display: flex;
    max-width: 560px;
    align-items: center;
    width: 100%;
    gap: 42px;
}

.modal-tutorial-body-item-videos-propriedades .video-conclude {
    background-color: #29f329b3;
}

.modal-tutorial-body-item-videos-play {
    display: flex;
    align-items: center;
    background: transparent;
    gap: 5px;
    border: 1px solid rgb(89 89 89 / 42%);
    padding: 8px 10px;
    border-radius: 3px;
}

.modal-tutorial-body-item-videos-contador {
    background-color: #E6E6E6;
    border: 1px solid rgb(89 89 89 / 42%);
    border-radius: 3px;
    padding: 5px 10px;
    background-color: #E6E6E6;
    border: 1px solid rgb(89 89 89 / 42%);
    border-radius: 3px;
    width: 100%;
    padding: 0px 10px 0px 0px;
    max-width: 150px;
    display: flex;
    height: 38px;
    align-items: center;
}

.modal-tutorial-body-item-videos-contador span {
    text-align: center;
    margin: 0 auto;
    display: block;
}

.modal-tutorial-body-item-videos-contador span:first-child {
    width: 15px;
    background: #fff;
    border: 1px solid rgb(89 89 89 / 42%);
    height: 20px;
    margin: initial;
    height: 100%;
}

.modal-tutorial-body-item-videos-title{
    color: #5c5c5c;
}

.message-sem-videos{
    text-align: center;
    font-size: 1.3rem;
    margin-top: 65px;
}