
.modal-adicionar-motorista-grupo {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 95vh;
  width: 700px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  overflow: auto;
  z-index: 99;
}

.overlay-modal-adicionar-motorista-grupo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.840);
  z-index: 1010;
}

.modal-adicionar-motorista-grupo .titulo {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.modal-adicionar-motorista-grupo .titulo p {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.modal-adicionar-motorista-grupo .titulo .icone {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.modal-adicionar-motorista-grupo .titulo .icone:hover {
  filter: brightness(120%);
}

.modal-adicionar-motorista-grupo .conteudo {
  padding: 0 15px;
}

.modal-adicionar-motorista-grupo .conteudo .titulo-lista {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0 10px 0;
}

.modal-adicionar-motorista-grupo .conteudo .container-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.modal-adicionar-motorista-grupo .conteudo .container-search .input-search-adicionar-motorista {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: #f1f4fa;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.modal-adicionar-motorista-grupo .conteudo .container-search .input-search-adicionar-motorista input {
  flex: 1;
  border: none;
  background-color: #f1f4fa;
}

.modal-adicionar-motorista-grupo .conteudo .container-search .input-search-adicionar-motorista input::placeholder {
  color: gray;
}

.modal-adicionar-motorista-grupo .conteudo .container-search .input-search-adicionar-motorista .icone {
  margin-left: 5px;
  font-size: 22px;
  color: #9ab2c6;
}

.modal-adicionar-motorista-grupo .conteudo .container-search .btn-buscar {
  width: 120px;
  background-color: #001b48;
  margin-left: 25px;
  height: 40px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.modal-adicionar-motorista-grupo .conteudo .container-search .btn-buscar:hover {
  filter: brightness(120%);
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .titulo-dados {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #9ab2c6;
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .titulo-dados p {
  flex: 1;
  font-weight: bold;
  text-align: center;
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .titulo-dados p.titulo-acoes {
  flex: 0.2;
  margin-right: 10px;
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .linha {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f1f4fa;
  margin-top: 5px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .linha:hover {
  filter: brightness(98%);
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .linha p {
  flex: 1;
  text-align: center;
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .linha.escolhido {
  cursor: text;
  padding: 7.4px;
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .linha.escolhido:hover {
  filter: none;
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .linha.escolhido .icone-remover-motorista {
  flex: 0.2;
  font-size: 22px;
  color: red;
  cursor: pointer;
}

.modal-adicionar-motorista-grupo .conteudo .lista-motoristas .linha.escolhido .icone-remover-motorista:hover {
  transform: scale(1.1);
}

.modal-adicionar-motorista-grupo .conteudo .msg-motorista-vazio {
  text-align: center;
}

.modal-adicionar-motorista-grupo .conteudo .btn-adicionar-motoristas {
  display: block;
  width: 230px;
  margin: 10px auto;
  background-color: #001b48;
  height: 40px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.modal-adicionar-motorista-grupo .conteudo .btn-adicionar-motoristas:hover {
  filter: brightness(120%);
}

.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>.active>a {
  background-color: #0d78f2;
  color: black;
}

.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>li>a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>.active>a,
.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>.active>span,
.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>.active>a:hover,
.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>.active>span:hover,
.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>.active>a:focus,
.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>.active>span:focus {
  background-color: #001b48;
  color: #fff;
  border-color: #fff;
  outline: none;
}

.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>li>a,
.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>li>span {
  background-color: #9ab2c6;
  color: black;
}

.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>li>a:hover {
  filter: brightness(120%);
}

.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>li:first-child>a,
.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>li:first-child>span,
.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>li:last-child>a,
.modal-adicionar-motorista-grupo .container-paginacao-adicionar-motorista .pagination>li:last-child>span {
  border-radius: 5px
}

.alert-motorista-adicionado {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-motorista-adicionado .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-motorista-adicionado button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #001b48;
}

@media (max-width: 730px) {
  .modal-adicionar-motorista-grupo {
    width: 96%;
  }
}

@media (max-width: 490px) {
  .modal-adicionar-motorista-grupo .conteudo .container-search {
    flex-direction: column;
  }
  .modal-adicionar-motorista-grupo .conteudo .container-search .input-search-adicionar-motorista {
    width: 100%;
    margin-bottom: 10px;
  }  
}