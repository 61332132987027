.container-documentos-gerais {
  min-height: 96vh;
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-documentos-gerais .quadro {
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-documentos-gerais .container-radio-tipo-documento {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  margin: 20px auto;
  padding-bottom: 10px;
  border-bottom: 2px solid #f1f4fa;
  font-weight: bold;
}

.container-documentos-gerais .container-radio-tipo-documento .container-input-radio {
  position: relative;
  user-select: none;
}

.container-documentos-gerais .container-radio-tipo-documento .container-input-radio .radio {
  cursor: pointer;
}

.container-documentos-gerais .container-radio-tipo-documento .container-input-radio .radio input[type="radio"] {
  display: none;
}

.container-documentos-gerais .container-radio-tipo-documento .container-input-radio .radio input[type="radio"]+span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.container-documentos-gerais .container-radio-tipo-documento .container-input-radio .radio input[type="radio"]:checked+span.checked {
  background: #3d8af7 -19px top no-repeat;
}

.container-documentos-gerais .container-radio-tipo-documento .container-input-radio .radio input[type="radio"]+span.checked {
  margin-right: 5px;
}

.container-documentos-gerais .container-filtros {
  display: inline-flex;
  width: 100%;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.116) 0px 3px 8px;
}

.container-documentos-gerais .container-filtros .input-filtro {
  width: 230px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f1f4fa;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.container-documentos-gerais .container-filtros .input-filtro input {
  width: 80%;
  margin: 0 3px 0 10px;
  background-color: #f1f4fa;
  border: none;
}

.container-documentos-gerais .container-filtros .input-filtro select {
  flex: 1;
  background-color: #f1f4fa;
  border-color: #f1f4fa;
  color: gray;
}

.container-documentos-gerais .container-filtros .input-filtro input::placeholder {
  font-size: 12px;
}

.container-documentos-gerais .container-filtros .input-filtro input[type="date"]::-webkit-inner-spin-button,
.container-documentos-gerais .container-filtros .input-filtro input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.container-documentos-gerais .container-filtros .input-filtro .icone-filtro {
  font-size: 18px;
}

.container-documentos-gerais .container-filtros .input-filtro .icone-limpar {
  font-size: 20px;
  color: #f1f4fa;
  background-color: #ced9e5;
  border-radius: 50%;
  cursor: pointer;
}

.container-documentos-gerais .container-filtros .input-filtro .icone-limpar:hover {
  filter: brightness(105%);
}

.container-documentos-gerais .container-filtros .container-rodape-filtros {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.container-documentos-gerais .container-filtros .container-rodape-financeiro {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.container-documentos-gerais .container-filtros .container-rodape-filtros .container-checkboxes {
  display: flex;
}

.container-documentos-gerais .container-filtros .container-input-checkbox {
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  font-weight: bold;
  color: #001b48;
}

.container-documentos-gerais .container-filtros .container-input-checkbox .checkbox {
  display: flex;
}

.container-documentos-gerais .container-filtros .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.container-documentos-gerais .container-filtros .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.container-documentos-gerais .container-filtros .container-input-checkbox .checkbox input[type="checkbox"]:checked + span.checked {
  background: #3d8af7 -19px top no-repeat;
}

.container-documentos-gerais .container-filtros .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  margin-right: 10px;
}

.container-documentos-gerais .container-filtros .btn-buscar {
  margin: 0;
  padding: 8px 40px;
  font-size: 16px;
  color: #fff;
  background-color: #02457a;
  border: none;
  border-radius: 8px;
  font-weight: bold;
}

.container-documentos-gerais .paginacao-documentos-gerais {
  margin-top: 20px;
}

.container-documentos-gerais .paginacao-documentos-gerais .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.container-documentos-gerais .paginacao-documentos-gerais .pagination>.active>a {
  background-color: #0d78f2;
  color: black;
}

.container-documentos-gerais .paginacao-documentos-gerais .pagination>li>a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

.container-documentos-gerais .paginacao-documentos-gerais .pagination>.active>a,
.container-documentos-gerais .paginacao-documentos-gerais .pagination>.active>span,
.container-documentos-gerais .paginacao-documentos-gerais .pagination>.active>a:hover,
.container-documentos-gerais .paginacao-documentos-gerais .pagination>.active>span:hover,
.container-documentos-gerais .paginacao-documentos-gerais .pagination>.active>a:focus,
.container-documentos-gerais .paginacao-documentos-gerais .pagination>.active>span:focus {
  background-color: #001b48;
  color: #fff;
  border-color: #fff;
  outline: none;
}

.container-documentos-gerais .paginacao-documentos-gerais .pagination>li>a,
.container-documentos-gerais .paginacao-documentos-gerais .pagination>li>span {
  background-color: #9ab2c6;
  color: black;
}

.container-documentos-gerais .paginacao-documentos-gerais .pagination>li>a:hover {
  filter: brightness(120%);
}

.container-documentos-gerais .paginacao-documentos-gerais .pagination>li:first-child>a,
.container-documentos-gerais .paginacao-documentos-gerais .pagination>li:first-child>span,
.container-documentos-gerais .paginacao-documentos-gerais .pagination>li:last-child>a,
.container-documentos-gerais .paginacao-documentos-gerais .pagination>li:last-child>span {
  border-radius: 5px
}

.container-add-documento {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.container-add-documento .btn-documento-avulso {
  margin: 0;
  padding: 8px 40px;
  font-size: 16px;
  color: #000;
  background-color: #9ab2c6;
  border: none;
  border-radius: 8px;
  font-weight: normal;
}

@media (max-width: 1220px) {
  .container-documentos-gerais .container-filtros .container-rodape-filtros {
    flex-direction: column;
  }
  .container-documentos-gerais .container-filtros .container-rodape-filtros .container-checkboxes {
    justify-content: space-between;
  }
  .container-documentos-gerais .container-filtros .btn-buscar {
    display: block;
    margin: 10px auto;
    font-size: 14px;
    width: 250px;
  }
}

@media (max-width: 890px) {
  .container-documentos-gerais .container-filtros .container-rodape-filtros .container-checkboxes {
    flex-direction: column;
    align-items: center;
  }
  .container-documentos-gerais .container-filtros .container-rodape-filtros .container-checkboxes .container-input-checkbox {
    margin: 5px 0;
  }
}

@media (max-width: 630px) {
  .container-documentos-gerais .container-filtros .container-rodape-filtros {
    margin: 0;
  }
  .container-documentos-gerais .container-radio-tipo-documento {
    width: 60%;
  }
  .container-documentos-gerais .container-filtros {
    display: flex;
    justify-content: center;
  }
  .container-documentos-gerais .container-filtros .btn-buscar {
    display: block;
    margin: 10px auto;
    width: 180px;
  }
}