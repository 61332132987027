.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .titulo {
  background-color: #3eedf7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .linha-atraso {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .linha-atraso .icone-atraso {
  font-size: 12px;
  color: #ef2d41;
  margin-top: 2px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .cards::-webkit-scrollbar-thumb {
  background: #3eedf7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .cards::-webkit-scrollbar-thumb:hover {
  background: #3eeef7a8;
  border-radius: 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .detalhes p:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .detalhes div.negociacao {
  flex: 2;
  flex-direction: column;
  border-left: 1px solid #99b2c6;
  border-right: 1px solid #99b2c6;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .detalhes div .total span {
  color: #3eedf7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .detalhes div .em-negociacao span {
  color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .detalhes div .sem-negociacao span {
  color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .cards .acoes-card.novas-cargas {
  background: linear-gradient(rgba(62, 237, 247, 255) 10%, transparent);
  background-color: #3c5169;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards:hover .acoes-card.novas-cargas.hover {
  opacity: 1;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .linha .status-pendente {
  color: #3c89f5;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.novas-cargas .linha .status-negada {
  color: #ef2d41;
}

.container-alert-compartilhar-carga,
.container-alert-remover-carga {
  width: 500px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
  animation-duration: 0.1s;
}

.container-alert-compartilhar-carga .titulo,
.container-alert-remover-carga .titulo {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.container-alert-compartilhar-carga .titulo .icone.fechar,
.container-alert-remover-carga .titulo .icone.fechar {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.container-alert-compartilhar-carga .titulo .icone.fechar:hover,
.container-alert-remover-carga .titulo .icone.fechar:hover {
  filter: brightness(120%);
}

.modal-visualizar-dados-carga {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 95vh;
  width: 500px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  overflow: auto;
}

.overlay-modal-visualizar-dados-carga {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.100);
  z-index: 10001;
}

.modal-visualizar-dados-carga .titulo {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.modal-visualizar-dados-carga .titulo p {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.modal-visualizar-dados-carga .titulo .icone {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.modal-visualizar-dados-carga .titulo .icone:hover {
  filter: brightness(120%);
}

.modal-visualizar-dados-carga .conteudo {
  margin-top: 20px;
  padding: 0 12%;
}

.modal-visualizar-dados-carga .conteudo .acoes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-visualizar-dados-carga .conteudo .acoes p {
  font-size: 16px;
}

.modal-visualizar-dados-carga .conteudo .acoes p span {
  font-weight: bold;
}

.modal-visualizar-dados-carga .conteudo .acoes div {
  margin-top: 8px;
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.modal-visualizar-dados-carga .conteudo .acoes div .icone {
  font-size: 22px;
  cursor: pointer;
}

.modal-visualizar-dados-carga .conteudo .acoes div .icone:hover {
  transform: scale(1.1);
}

.modal-visualizar-dados-carga .conteudo .acoes div .icone.compartilhar {
  color: #3c89f5;
}

.modal-visualizar-dados-carga .conteudo .acoes div .icone.lixo {
  color: #ef2d41;
}

.modal-visualizar-dados-carga .conteudo .detalhes {
  margin: 20px 0;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha {
  padding: 10px 0;
  border-bottom: 1px solid #ced9e5;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha p span {
  font-weight: bold;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha p a {
  margin-left: 5px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  color: #333;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha .cidades {
  display: flex;
  justify-content: space-between;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha .abrir-lista {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha .abrir-lista .icone {
  font-size: 18px;
  cursor: pointer;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha .abrir-lista .icone:hover {
  transform: scale(1.1);
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha .pontos-parada p {
  margin-bottom: 5px;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha .distancia {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha .distancia .icone {
  font-size: 22px;
  color: #3c89f5;
  margin-right: 5px;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha .distancia p {
  font-weight: bold;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha p span.caminhao {
  display: inline-block;
  width: 102px;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha p span.carroceria {
  display: inline-block;
  width: 105px;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha.col-2 {
  display: flex;
  justify-content: space-between;
}

.modal-visualizar-dados-carga .conteudo .detalhes .linha .real {
  font-weight: normal;
  margin-left: 5px;
}

.modal-visualizar-dados-carga .conteudo .detalhes .fechamento {
  margin: 10px 0 20px;
}

.modal-visualizar-dados-carga .conteudo .detalhes .fechamento p {
  color: #ef2d41;
  text-align: center;
}

.modal-visualizar-dados-carga .conteudo .detalhes .fechamento p span {
  font-weight: bold;
}

.container-alert-compartilhar-carga .titulo,
.container-alert-remover-carga .titulo {
  padding: 0 10px;
}

.container-alert-compartilhar-carga .titulo,
.container-alert-remover-carga .titulo {
  justify-content: space-between;
}

.container-alert-compartilhar-carga .titulo .icone,
.container-alert-remover-carga .titulo .icone {
  font-size: 24px;
  color: #fff;
}

.container-alert-compartilhar-carga .titulo .detalhes,
.container-alert-remover-carga .titulo .detalhes {
  text-align: center;
}

.container-alert-compartilhar-carga .titulo .detalhes p,
.container-alert-remover-carga .titulo .detalhes p {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.container-alert-compartilhar-carga .titulo .detalhes p span,
.container-alert-remover-carga .titulo .detalhes p span {
  font-weight: lighter;
}

.container-alert-compartilhar-carga .titulo .detalhes div,
.container-alert-remover-carga .titulo .detalhes div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.container-alert-compartilhar-carga .titulo .detalhes div p,
.container-alert-remover-carga .titulo .detalhes div p {
  font-size: 12px;
}

.container-alert-compartilhar-carga .titulo .detalhes div .icone.id,
.container-alert-remover-carga .titulo .detalhes div .icone.id {
  font-size: 16px;
  color: black;
  margin-right: 5px;
}

.container-alert-compartilhar-carga .conteudo,
.container-alert-remover-carga .conteudo {
  padding: 30px 15%;
}

.container-alert-compartilhar-carga .conteudo p,
.container-alert-remover-carga .conteudo p {
  font-weight: bold;
}

.container-alert-compartilhar-carga .conteudo .botoes,
.container-alert-remover-carga .conteudo .botoes {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

.container-alert-compartilhar-carga .conteudo button,
.container-alert-remover-carga .conteudo button {
  width: 125px;
  padding: 12px 0;
  background-color: #9ab2c6;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.container-alert-compartilhar-carga .conteudo button.btn-compartilhar {
  background-color: #92d36e;
}

.container-alert-compartilhar-carga .conteudo button:hover,
.container-alert-remover-carga .conteudo button:hover {
  filter: brightness(110%);
}

.container-alert-remover-carga .conteudo button.btn-remover {
  background-color: #ef2d41;
}

@media (max-width: 800px) {
  .react-confirm-alert-overlay {
    overflow-x: scroll;
  }
  .container-alert-compartilhar-carga, 
  .container-alert-remover-carga {
    width: 400px;
    background-color: #fff;
    border: 2px solid #3c89f5;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 0;
    animation-duration: 0.1s;
  }
  .container-alert-compartilhar-carga .conteudo,
  .container-alert-remover-carga .conteudo {
    padding: 30px 10px;
    overflow: scroll;
  }
}

@media (max-width: 580px) {
  .modal-visualizar-dados-carga {
    width: 96%;
  }
}