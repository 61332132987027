.container-novo-contrato {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}


.container-novo-contrato .btn-voltar-pagina {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  font-weight: bold;
  margin-bottom: 20px;
}

.container-novo-contrato .btn-voltar-pagina:hover {
  filter: brightness(120%);
}

.container-novo-contrato .novo-contrato {
  margin-bottom: 20px;
}

.container-novo-contrato .novo-contrato .titulo-pagina {
  font-size: 20px;
  font-weight: bold;
}

.container-novo-contrato .novo-contrato .subtitulo-pagina {
  margin-top: 5px;
  font-weight: bold;
  color: #001b48;
}

.container-novo-contrato .lista-variaveis {
  position: relative;
  width: 300px;
  margin: 20px 0 -1px 1px;
  padding: 8px 0;
  background-color: #3c89f5;
  color: #fff;
  cursor: pointer;
}

.container-novo-contrato .lista-variaveis .box-placeholder {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.container-novo-contrato .lista-variaveis .placeholder {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.container-novo-contrato .lista-variaveis .box-placeholder .icone {
  font-size: 20px;
}

.container-novo-contrato .lista-variaveis .lista {
  position: absolute;
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px 15px;
  background-color: #3c89f5;
  z-index: 9999;
}

.container-novo-contrato .lista-variaveis .lista .opcao {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid#fff;
}

.container-novo-contrato .lista-variaveis .lista .opcao:last-of-type {
  border-bottom: none;
}

.container-novo-contrato .lista-variaveis .lista .opcao:hover {
  text-decoration: underline;
}

.container-novo-contrato .lista-variaveis .lista .opcao .icone {
  font-size: 20px;
}


.container-novo-contrato .editor-texto {
  height: 55vh;
  margin-bottom: 15px;
}

.container-novo-contrato .editor-texto .quill {
  box-shadow: rgba(0, 0, 0, 0.116) 0px 3px 8px;
}

.container-novo-contrato .editor-texto .ql-toolbar.ql-snow {
  background-color: #3c89f5;
}

.container-novo-contrato .editor-texto .ql-editor {
  background-color: #f1f4fa;
  height: 50vh;
}

.container-novo-contrato .container-botoes {
  display: flex;
  justify-content: center;
  margin: 80px auto 20px auto; 
}

.container-novo-contrato .container-botoes button {
  width: 250px;
  padding: 12px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

.container-novo-contrato .container-botoes button:hover {
  filter: brightness(120%);
}

.container-novo-contrato .container-botoes button.btn-cancelar {
  margin-right: 30px;
}

.container-novo-contrato .container-botoes button.btn-cadastrar {
  background-color: #001b48;
  border-color: #001b48;
  color: #fff;
}