.overlay-modal-avaliacao-motorista {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10000;
}

.modal-avaliacao-motorista {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  background-color: rgba(18, 19, 20, 0.400);
  overflow: auto;
  z-index: 99;
}

.modal-avaliacao-motorista .avaliacao-motorista {
  width: 380px;
  border-radius: 10px;
  background-color: #f8f8f8;
  margin: auto;
  padding: 20px 30px;
}

.modal-avaliacao-motorista .avaliacao-motorista img {
  display: block;
  height: 45px;
  width: 80px;
  margin: 10px auto;
}

.modal-avaliacao-motorista .avaliacao-motorista p {
  font-weight: bold;
}

.modal-avaliacao-motorista .avaliacao-motorista .rating-motorista {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.modal-avaliacao-motorista .avaliacao-motorista .rating-motorista .icone {
  font-size: 25px;
  margin: 0 3px;
}

.modal-avaliacao-motorista .avaliacao-motorista .rating-motorista .icone.vazio {
  color: #fac917;
}

.modal-avaliacao-motorista .avaliacao-motorista .rating-motorista .icone.preenchido {
  color: #fac917
}

.modal-avaliacao-motorista .avaliacao-motorista textarea {
  width: 100%;
  resize: none;
  border: 1px solid gray;
  border-radius: 8px;
  padding: 10px;
}

.modal-avaliacao-motorista .avaliacao-motorista .botoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.modal-avaliacao-motorista .avaliacao-motorista .botoes button {
  width: 200px;
  margin-bottom: 10px;
  background-color: #02457a;
  padding: 8px;
  border-radius: 8px;
  font-weight: bold;
  color: #fff;
  border: none;
}

.modal-avaliacao-motorista .avaliacao-motorista .botoes button:hover {
  filter: brightness(110%);
}

.modal-avaliacao-motorista .avaliacao-motorista .botoes p {
  font-size: 12px;
  color: #02457a;
  cursor: pointer;
}

.modal-avaliacao-motorista .avaliacao-motorista .botoes p:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .modal-avaliacao-motorista .avaliacao-motorista {
    padding: 10px;
  }
}
