.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards::-webkit-scrollbar-thumb {
  background: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards::-webkit-scrollbar-thumb:hover {
  background: #3374cf;
  border-radius: 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .acoes-card.embarque {
  background: linear-gradient(rgba(61, 138, 247, 255) 10%, transparent);
  background-color: #3c5169;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards:hover .acoes-card.embarque.hover {
  opacity: 1;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .transportadora .icone {
  color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .embarque .icone,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .entrega .icone {
  color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .valor div {
  background-color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .caminhao .icone,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .carga .icone {
  color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .data {
  color: #333;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .data span {
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .carga.status {
  justify-content: flex-end;
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .carga.status span {
  font-weight: lighter;
  text-transform: capitalize;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .pedido .cor.carregando {
  background-color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .pedido .cor.total {
  background-color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .pedido .cor.sem-embarcar {
  background-color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .pedido .cor.portaria {
  background-color: #3eedf7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .pedido .cor.aguardando-doc {
  background-color: #fec63d;
}


.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .carga.status .carregando {
  color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .carga.status .total {
  color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .carga.status .sem-embarcar {
  color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .carga.status .portaria {
  color: #3eedf7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha .carga.status .aguardando-doc {
  color: #fec63d;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha-dados-motorista {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 15px;
  border-radius: 20px;
  border: 1px solid #99b2c6;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha-dados-motorista .foto-nome {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha-dados-motorista .foto-nome .icone {
  font-size: 20px;
  border-radius: 50%;
  color: #3d8af7;
  margin-right: 15px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .linha-dados-motorista .foto-nome .nome {
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards .card .veiculo {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
}