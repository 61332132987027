.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards::-webkit-scrollbar-thumb {
  background: #172b4d;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards::-webkit-scrollbar-thumb:hover {
  background: #080f1b;
  border-radius: 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .acoes-card.em-transito {
  background: linear-gradient(rgba(23, 43, 77, 255) 10%, transparent);
  background-color: #000000;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards:hover .acoes-card.em-transito.hover {
  opacity: 1;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .icone {
  color: #172b4d !important;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha.cidades .cidade{
  text-align: right;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .data {
  color: #172b4d;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .data span {
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .carga.status {
  justify-content: flex-end;
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .carga.status span {
  font-weight: lighter;
  text-transform: capitalize;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .pedido .cor.no-prazo {
  background-color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .pedido .cor.atrasado {
  background-color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .pedido .cor.possivel-atraso {
  background-color: #fec63d;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .carga.status .no-prazo {
  color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .carga.status .atrasado {
  color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .carga.status .possivel-atraso {
  color: #fec63d;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha-dados-motorista {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 15px;
  border-radius: 20px;
  border: 1px solid #99b2c6;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha-dados-motorista .foto-nome {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha-dados-motorista .foto-nome .icone {
  font-size: 20px;
  border-radius: 50%;
  color: #172b4d !important;
  margin-right: 15px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha-dados-motorista .foto-nome .nome {
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .veiculo {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
}