.select-filtro {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f1f4fa;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
  
  select {
    margin: 0 3px 0 10px;
    background-color: #f1f4fa;
    border: none;
    flex: 1;
  }
  
  .icone-limpar {
    font-size: 22px;
    color: #f1f4fa;
    background-color: #ced9e5;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      filter: brightness(105%);
    }
  }

  .icone-filtro {
    font-size: 18px;
  }
}
