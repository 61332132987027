.modal-pagamento-coins {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 85vh;
  width: 47%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 30px 30px 30px;
  overflow: auto;
  z-index: 99;
}

.overlay-modal-pagamento-coins {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.840);
  z-index: 10002;
}

@media (max-width: 800px) {
  .modal-pagamento-coins {
    width: 96%;
  }
}