.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards::-webkit-scrollbar-thumb {
  background: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards::-webkit-scrollbar-thumb:hover {
  background: #74a858;
  border-radius: 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .acoes-card.entregue {
  background: linear-gradient(rgba(146, 211, 110, 255) 10%, transparent);
  background-color: #2e4424;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards:hover .acoes-card.entregue.hover {
  opacity: 1;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .icone {
  color: #92d36e !important;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha.cidades .cidade{
  text-align: right;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .data {
  color: #333;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .data span {
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .carga.status {
  justify-content: flex-end;
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .carga.status span {
  font-weight: lighter;
  text-transform: capitalize;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .pedido .cor.no-prazo {
  background-color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .carga.status .no-prazo {
  color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .pedido .cor.atrasado,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .carga.status .atrasado {
  color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .pedido .cor.ocorrencia,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha .carga.status .ocorrencia {
  color: #c58b00;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha-dados-motorista {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 15px;
  border-radius: 20px;
  border: 1px solid #99b2c6;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha-dados-motorista .foto-nome {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha-dados-motorista .foto-nome .icone {
  font-size: 20px;
  border-radius: 50%;
  color: #92d36e !important;
  margin-right: 15px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .linha-dados-motorista .foto-nome .nome {
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards .card .veiculo {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
}

.alert-realizar-pagamento  {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-realizar-pagamento  .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-realizar-pagamento button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-realizar-pagamento button:hover {
  filter: brightness(120%);
}

.alert-realizar-pagamento button.botao-confirmar {
  background-color: #cc3300;
}

.alert-realizar-pagamento button.botao-cancelar {
  background-color: #001b48;
}
