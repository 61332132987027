:root {
  --toastify-color-progress-light: #3d8af7;
}

.notificacao-retificacao-documento {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificacao-retificacao-documento img {
  height: 70px;
  width: 70px;
  margin-right: 5px;
}

.notificacao-retificacao-documento .conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notificacao-retificacao-documento .conteudo p {
  color: #001b48;
}

.notificacao-retificacao-documento .conteudo .aviso {
  font-size: 12px;
  color: #333;
}

.notificacao-retificacao-documento .botoes {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.notificacao-retificacao-documento .botoes button {
  border-radius: 5px;
  border: none;
  margin: 0 10px;
  padding: 3px 10px;
  font-weight: bold;
  color: #fff;
}

.notificacao-retificacao-documento .botoes button:hover {
  filter: brightness(110%);
}

.notificacao-retificacao-documento .botoes button.sim {
  background-color: #92d36e;
}

.notificacao-retificacao-documento .botoes button.nao {
  background-color: #ef2d41;
}