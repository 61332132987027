.container-tabela-mdfs {
  margin-top: 20px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-tabela-mdfs table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.container-tabela-mdfs table thead tr th {
  padding: 10px 3px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  border-right: 1px solid #fff;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.container-tabela-mdfs table thead tr th:first-of-type {
  border-top-left-radius: 5px;
}

.container-tabela-mdfs table thead tr th:last-of-type {
  border-top-right-radius: 5px;
}

.container-tabela-mdfs table tbody tr td {
  padding: 15px;
  font-size: 12px;
  border-right: 1px solid #fff;
}

.container-tabela-mdfs table tbody tr.linha td {
  background-color: #d6e8ee;
}

.container-tabela-mdfs table tbody tr.linha.impar td {
  background-color: #99b2c6;
}

.container-tabela-mdfs table .coluna-acoes {
  padding: 5px;
}

.container-tabela-mdfs table .coluna-acoes img {
  height: 25px;
}

.container-tabela-mdfs table .coluna-acoes .icone {
  font-size: 25px;
}

.container-tabela-mdfs table .coluna-acoes .icone.remover {
  color: #ff0000;
}

.container-tabela-mdfs table .coluna-acoes .cte-icon {
  margin: 0 2px;
}

.container-tabela-mdfs table .coluna-acoes img:hover,
.container-tabela-mdfs table .coluna-acoes .icone:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.container-tabela-mdfs table td.coluna-informacoes {
  text-align: left;
}

.container-tabela-mdfs table td.coluna-informacoes span {
  color: #3d8af7;
}

.container-tabela-mdfs table td.coluna-informacoes .status-manifesto {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.container-tabela-mdfs table td.coluna-motorista span {
  color: #ff0000;
  text-decoration: underline;
  cursor: pointer;
}

.alert-remover-mdf {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-remover-mdf .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-remover-mdf .react-confirm-alert-body {
  color: #1c2732;
}

.alert-remover-mdf button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-remover-mdf button:hover {
  filter: brightness(120%);
}

.alert-remover-mdf button.botao-confirmar {
  background-color: #cc3300;
}

.alert-remover-mdf button.botao-cancelar {
  background-color: #001b48;
}

.tabela-mdfs .btn-financeiro {
  display: flex;
  align-items: center;
  background-color: #3c89f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 5px 5px 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.tabela-mdfs .btn-financeiro:hover {
  filter: brightness(110%);
}

.tabela-mdfs .btn-financeiro .icone {
  margin-left: 20px;
  font-weight: bold;
}

.alert-certificado-digital {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-certificado-digital .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-certificado-digital .react-confirm-alert-body {
  color: #1c2732;
}

.alert-certificado-digital button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #001b48;
}

.alert-certificado-digital button:hover {
  filter: brightness(120%);
}