.modal-perfil-motorista-carga-financeiro {
  padding: 20px;
}

.modal-perfil-motorista-carga-financeiro .dados-pedido {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-perfil-motorista-carga-financeiro .dados-pedido .informacoes div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-perfil-motorista-carga-financeiro .dados-pedido .informacoes div .icone {
  font-size: 15px;
  color: #3c89f5;
  margin-right: 5px;
}

.modal-perfil-motorista-carga-financeiro .dados-pedido .informacoes div p span,
.modal-perfil-motorista-carga-financeiro .dados-pedido .informacoes .pedido span {
  font-weight: bold;
}

.modal-perfil-motorista-carga-financeiro .dados-pedido .informacoes .pedido {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

.modal-perfil-motorista-carga-financeiro .dados-pedido .informacoes .message-app {
  color: #FF0000;
  margin-top: 15px;
}

.modal-perfil-motorista-carga-financeiro .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
}

.modal-perfil-motorista-carga-financeiro .titulo.mdf {
  margin: 30px 0 10px 0;
}

.modal-perfil-motorista-carga-financeiro .tabelas-fretes {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.modal-perfil-motorista-carga-financeiro .tabelas-fretes .container-tabela {
  flex: 1;
}

.modal-perfil-motorista-carga-financeiro .tabelas-fretes .container-tabela:first-of-type {
  margin-right: 20px;
}

.modal-perfil-motorista-carga-financeiro .observacoes {
  margin: 30px 0 20px 0;
}

.modal-perfil-motorista-carga-financeiro .observacoes {
  background-color: #3eedf7;
  padding: 12px 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-perfil-motorista-carga-financeiro .observacoes p {
  font-weight: bold;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes {
  display: flex;
  justify-content: space-between;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes .opcao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  background-color: #d6e8ee;
  padding: 12px 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes .opcao:first-of-type {
  margin-right: 10%;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes .opcao p {
  font-weight: bold;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes .opcao .nome {
  font-weight: normal;
  color: #3c89f5;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes .opcao .container-input-checkbox {
  position: relative;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes .opcao .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes .opcao .container-input-checkbox .checkbox input[type="checkbox"] + span {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes .opcao .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  background: #95d470 -19px top no-repeat;
}

.modal-perfil-motorista-carga-financeiro .container-opcoes .opcao .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  margin-right: 10px;
}

.modal-perfil-motorista-carga-financeiro .informacoes {
  margin-top: 20px;
}

.modal-perfil-motorista-carga-financeiro .informacoes .container-informacoes {
  display: flex;
  flex-direction: column;
}

.modal-perfil-motorista-carga-financeiro .informacoes .container-informacoes .quadro {
  display: flex;
  width: 50%;
  margin: 12px auto;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-perfil-motorista-carga-financeiro .informacoes .container-informacoes .quadro:first-of-type {
  margin-top: 0;
}

.modal-perfil-motorista-carga-financeiro .informacoes .container-informacoes .quadro div {
  flex: 1;
}

.modal-perfil-motorista-carga-financeiro .informacoes .container-informacoes .quadro div:first-of-type {
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
  padding: 12px 0 12px 20px;
  color: #fff;
  border-radius: 5px 0 0 5px;
}

.modal-perfil-motorista-carga-financeiro .informacoes .container-informacoes .quadro div:last-of-type {
  background-color: #d6e8ee;
  padding: 12px 0 12px 20px;
  border-radius: 0 5px 5px 0;
}

@media (max-width: 1110px) {
  .modal-perfil-motorista-carga-financeiro .tabelas-fretes {
    flex-direction: column;
  }
  .modal-perfil-motorista-carga-financeiro .tabelas-fretes .container-tabela:first-of-type {
    margin-right: 0;
  }
  .modal-perfil-motorista-carga-financeiro .tabelas-fretes .container-tabela:last-of-type {
    margin-top: 20px;
  }
  
}

@media (max-width: 980px) {
  .modal-perfil-motorista-carga-financeiro {
    padding: 10px;
  }
  .modal-perfil-motorista-carga-financeiro .informacoes .container-informacoes .quadro {
    width: 100%;
  }
  .modal-perfil-motorista-carga-financeiro .container-opcoes .opcao:first-of-type {
    margin-right: 5%;
  }
}