.form-select {
  position: relative;

  &:not(.small) {
    margin-top: 20px;
    margin-bottom: 5px;

    .form_select__control {
      background: #f1f4fa;
      border-color: #9ab2c6;
      border-radius: 8px;
    }

    .form_select--is-disabled {
      opacity: 0.6;
    }
  }

  &.small {
    margin-top: 0;
    max-height: 29px;

    .form_select__control {
      min-height: 29px;
      height: 29px !important;
  
      padding: 0 auto;

      background: #f1f4fa;
      border-color: #9ab2c6;
  
      .form_select__indicator {
        padding: 2px 4px;
      }
    }
  }

  span {
    position: absolute;
    top: 12px;
    left: 15px;
    letter-spacing: 0.05em;
    pointer-events: none;
    transition: 0.4s;
    font-size: 13px;
    color: gray;

    i {
      color: red;
      font-style: normal;
    }
  }

  &.with-value, &.with-search {
    span {
      top: -8px;
      left: 10px;
      font-size: 12px;
      color: #9ab2c6;
      background-color: #fff;
    }
  }

  &.small {
    span {
      display: none;
    }
  }

  &.white {
    .form_select__control {
      background: #FFF;
    }
  }
  &.borderless {
    .form_select__control {
      border: none;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
}

.form_select__menu-portal {
  z-index: 10005 !important;
}
