.container-historico-notificacoes {
  width: 100vw;
  margin: 10px 10px 0 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px 0 40px;
}

.container-historico-notificacoes .titulo {
  display: flex;
  align-items: center;
}

.container-historico-notificacoes .titulo .container-icone {
  display: flex;
  position: relative;
  margin-right: 10px;
}

.container-historico-notificacoes .titulo .container-icone .icone {
  font-size: 30px;
}

.container-historico-notificacoes .titulo .container-icone .quantidade {
  position: absolute;
  bottom: 0;
  right: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}

.container-historico-notificacoes .titulo p {
  font-size: 20px;
  font-weight: bold;
}

.container-historico-notificacoes .container-lista-notificacoes {
  min-height: 68vh;
  margin: 40px 0 20px;
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.116) 0px 3px 8px;
  margin-bottom: 20px;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.visualizada {
  opacity: 0.7;
  pointer-events: none;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .icone {
  min-width: 20px;
  font-size: 15px;
  color: #001b48;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .icone.visualizar:not(.icone.visualizar.fechamento):hover {
  transform: scale(1.2);
  cursor: pointer;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao img {
  height: 45px;
  width: 45px;
  margin: 0 20px;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .texto {
  flex: 1;
  font-weight: bold;
  margin-right: 20px;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .data {
  flex: 0.1;
  text-align: right;
  color: #808080;
  white-space: nowrap;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .aviso {
  font-size: 12px;
  color: #333;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .botoes {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .botoes button {
  border-radius: 5px;
  border: none;
  margin: 0 10px;
  padding: 3px 10px;
  font-weight: bold;
  color: #fff;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .botoes button:hover {
  filter: brightness(110%);
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .botoes button.sim {
  background-color: #92d36e;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .botoes button.nao {
  background-color: #ef2d41;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto p {
  margin-bottom: 5px;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto span {
  font-weight: bold;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto .pergunta {
  margin-top: 10px;
  font-weight: bold;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto .container-input {
  position: relative;
  margin-top: 15px;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto .container-input input {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto .container-input span {
  position: absolute;
  top: -8px;
  left: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto .botoes-alterar-data {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto .botoes-alterar-data button {
  border-radius: 5px;
  border: none;
  margin: 0 10px;
  padding: 3px 10px;
  font-weight: bold;
  color: #fff;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto .botoes-alterar-data button:hover {
  filter: brightness(110%);
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto .botoes-alterar-data button.salvar {
  background-color: #92d36e;
}

.container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao.fechamento .texto .botoes-alterar-data button.cancelar {
  background-color: #ef2d41;
}

.container-loading-historico-notificacoes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.container-historico-notificacoes .container-paginacao-notificacoes .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.container-historico-notificacoes .container-paginacao-notificacoes .pagination>.active>a {
  background-color: #0d78f2;
  color: black;
}

.container-historico-notificacoes .container-paginacao-notificacoes .pagination>li>a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

.container-historico-notificacoes .container-paginacao-notificacoes .pagination>.active>a,
.container-historico-notificacoes .container-paginacao-notificacoes .pagination>.active>span,
.container-historico-notificacoes .container-paginacao-notificacoes .pagination>.active>a:hover,
.container-historico-notificacoes .container-paginacao-notificacoes .pagination>.active>span:hover,
.container-historico-notificacoes .container-paginacao-notificacoes .pagination>.active>a:focus,
.container-historico-notificacoes .container-paginacao-notificacoes .pagination>.active>span:focus {
  background-color: #001b48;
  color: #fff;
  border-color: #fff;
  outline: none;
}

.container-historico-notificacoes .container-paginacao-notificacoes .pagination>li>a,
.container-historico-notificacoes .container-paginacao-notificacoes .pagination>li>span {
  background-color: #9ab2c6;
  color: black;
}

.container-historico-notificacoes .container-paginacao-notificacoes .pagination>li>a:hover {
  filter: brightness(120%);
}

.container-historico-notificacoes .container-paginacao-notificacoes .pagination>li:first-child>a,
.container-historico-notificacoes .container-paginacao-notificacoes .pagination>li:first-child>span,
.container-historico-notificacoes .container-paginacao-notificacoes .pagination>li:last-child>a,
.container-historico-notificacoes .container-paginacao-notificacoes .pagination>li:last-child>span {
  border-radius: 5px
}

@media (max-width: 670px) {
  .container-historico-notificacoes {
    padding: 25px 10px;
  }

  .container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao {
    padding: 10px;
  }
}

@media (max-width: 580px) {
  .container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao {
    flex-direction: column;
  }

  .container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao img {
    margin: 10px 0;
  }

  .container-historico-notificacoes .container-lista-notificacoes .quadro-notificacao .texto {
    text-align: center;
    margin-bottom: 10px;
    margin-right: 0;
  }
}