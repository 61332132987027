.overlay-modal-nova-carga {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10001;
}

.modal-nova-carga  {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 70%;
  max-height: 90vh;
  background-color: #f7fafc;
  border-radius: 5px;
  overflow: auto;
}

.modal-nova-carga .container-nova-carga {
  padding: 15px;
}

.modal-nova-carga .container-nova-carga .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #3a4959;
  cursor: pointer;
}

.modal-nova-carga .container-nova-carga .icone-fechar:hover {
  filter: brightness(130%);
}

.modal-nova-carga .container-nova-carga .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
}

.modal-nova-carga .container-nova-carga .input-radio {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-top: 20px;
  padding: 8px 25px;
  border-radius: 8px;
  background-color:#f1f4fa;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio {
  position: relative;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio {
  user-select: none;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio {
  cursor: pointer;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio input[type="radio"] {
  display: none;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio input[type="radio"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio input[type="radio"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio input[type="radio"] + span.checked {
  margin-right: 5px;
}

.modal-nova-carga .container-nova-carga .btn-upload {
  position: relative;
  width: 40%;
  margin-top: 20px;
}

.modal-nova-carga .container-nova-carga .btn-upload input[type="file"] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  padding: 8px 25px;
  width: 100%;
  cursor: pointer;
}

.modal-nova-carga .container-nova-carga .btn-upload label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 25px;
  background-color: #f1f4fa;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
  cursor: pointer;
}

.modal-nova-carga .container-nova-carga .btn-upload label .icone {
  font-size: 20px;
}

.modal-nova-carga .container-nova-carga .container-inputs {
  margin: 20px 0;
  background-color: #f1f4fa;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
  border-radius: 5px;
  padding: 30px 15px 20px 15px;
}

.modal-nova-carga .container-nova-carga .container-inputs .container-inputs-3col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2%;
  margin-top: 15px;
}

.modal-nova-carga .container-nova-carga .container-inputs .container-inputs-1col {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
}

.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum {
  position: relative;
}

.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum input,
.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum select {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum input:focus + span,
.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum input:disabled + span,
.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum input:valid + span,
.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum select:focus + span,
.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum select:disabled + span,
.modal-nova-carga .container-nova-carga .container-inputs .container-input-comum select:valid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.modal-nova-carga .container-nova-carga .container-inputs .container-input-select {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-nova-carga .container-nova-carga .container-inputs .container-input-select select {
  background-color: #d6e8ee;
  padding: 9px 5px 9px 10px;
  border: none;
  border-radius: 8px;
  color: #8094aa;
}

.modal-nova-carga .container-nova-carga .container-inputs .container-input-select input {
  width: 100%;
  background-color: #fff;
  padding: 10px 5px;
  border: none;
  border-radius: 0 8px 8px 0;
}

@media (max-width: 980px) {
  .modal-nova-carga {
    width: 98%;
  }
  .modal-nova-carga .container-nova-carga {
    margin-right: 5px;
  }
}

@media (max-width: 400px) {
  .modal-nova-carga .container-nova-carga .input-radio,
  .modal-nova-carga .container-nova-carga .btn-upload {
    width: 60%;
  }
}