:root {
  --toastify-color-progress-light: #3d8af7;
}

.notificacao-entrega-finalizada {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificacao-entrega-finalizada img {
  height: 70px;
  width: 70px;
  margin-right: 5px;
}

.notificacao-entrega-finalizada .conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notificacao-entrega-finalizada .conteudo p {
  color: #001b48;
}

.notificacao-entrega-finalizada button {
  background-color: #018abe;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
  padding: 3px 10px;
  font-weight: bold;
  color: #fff;
}

.notificacao-entrega-finalizada button:hover {
  filter: brightness(120%);
}