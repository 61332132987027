.overlay-modal-dados-nfe {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10001;
}

.modal-dados-nfe {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 70%;
  max-height: 90vh;
  background-color: #f7fafc;
  border-radius: 5px;
  overflow: auto;
}

.modal-dados-nfe .dados-nfe .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #3a4959;
  cursor: pointer;
}

.modal-dados-nfe .dados-nfe .icone-fechar:hover {
  filter: brightness(130%);
}

.modal-dados-nfe .dados-nfe .container-acoes {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 10px 0;
}

.modal-dados-nfe .dados-nfe .container-acoes img {
  height: 37px;
}

.modal-dados-nfe .dados-nfe .container-acoes img:first-of-type  {
  margin-right: 10px;
}

.modal-dados-nfe .dados-nfe .container-acoes img:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.modal-dados-nfe .dados-nfe .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
  text-align: center;
}

.modal-dados-nfe .dados-nfe {
  padding: 20px 15px;
}

.modal-dados-nfe .dados-nfe .container-formulario {
  position: relative;
  margin-top: 20px;
  background-color: #f1f4fa;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 15px;
  border-radius: 8px;
}

.modal-dados-nfe .dados-nfe .container-inputs-3col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  margin-top: 20px;
}

.modal-dados-nfe .dados-nfe .container-inputs-2col {
  display: grid;
  grid-template-columns: 0.95fr 2.05fr;
  grid-column-gap: 5%;
  margin-top: 20px;
}

.modal-dados-nfe .dados-nfe .container-input-comum {
  position: relative;
}

.modal-dados-nfe .dados-nfe .container-input-comum input {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-dados-nfe .dados-nfe .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.modal-dados-nfe .dados-nfe .container-input-comum input:focus + span,
.modal-dados-nfe .dados-nfe .container-input-comum input:disabled + span,
.modal-dados-nfe .dados-nfe .container-input-comum input:valid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.modal-dados-nfe .dados-nfe .container-input-comum input:disabled {
  color: #3d8af7;
}

@media (max-width: 720px) {
  .modal-dados-nfe .dados-nfe .container-inputs-3col {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
  }
}

@media (max-width: 420px) {
  .modal-dados-nfe .dados-nfe .container-inputs-3col,
  .modal-dados-nfe .dados-nfe .container-inputs-2col {
    grid-template-columns: 1fr;
  }
  .modal-dados-nfe .dados-nfe .container-inputs-2col {
    grid-row-gap: 20px;
  }
}