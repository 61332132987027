.painel-load-more-container {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.painel-load-more-container button {
    padding: 10px 60px;
    border-radius: 10px;
    border: none;
    appearance: none;
    background: rgba(0, 0, 0, 0.1);
}
.painel-load-more-container button:hover {
    background: rgba(0, 0, 0, 0.2);
}