.container-novo-destinatario {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-novo-destinatario .btn-voltar-pagina {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  font-weight: bold;
  margin-bottom: 20px;
}

.container-novo-destinatario .btn-voltar-pagina:hover {
  filter: brightness(120%);
}

.container-novo-destinatario .novo-destinatario .titulo-pagina {
  font-size: 20px;
  font-weight: bold;
}

.container-novo-destinatario .novo-destinatario .subtitulo-pagina {
  margin-top: 5px;
  font-weight: bold;
  color: #001b48;
}

.container-novo-destinatario .novo-destinatario .categoria {
  margin-top: 30px;
}

.container-novo-destinatario .novo-destinatario .categoria .titulo-categoria {
  font-size: 16px;
  font-weight: bold;
  color: #9ab2c6;
}

.container-novo-destinatario .novo-destinatario .categoria .container-inputs-2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
}

.container-novo-destinatario .novo-destinatario .categoria .container-inputs-1col {
  width: 45%;
}

.container-novo-destinatario .novo-destinatario .container-botoes {
  display: flex;
  justify-content: center;
  margin: 80px auto 20px auto; 
}

.container-novo-destinatario .novo-destinatario .container-botoes button {
  width: 250px;
  padding: 12px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

.container-novo-destinatario .novo-destinatario .container-botoes button:hover {
  filter: brightness(120%);
}

.container-novo-destinatario .novo-destinatario .container-botoes button.btn-cancelar {
  margin-right: 30px;
}

.container-novo-destinatario .novo-destinatario .container-botoes button.btn-cadastrar {
  background-color: #001b48;
  border-color: #001b48;
  color: #fff;
}

.container-novo-destinatario .novo-destinatario .novo-embarcador .msg-erro {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

@media (max-width: 670px) {
  .container-novo-destinatario {
    padding: 25px 10px;
  }
  .container-novo-destinatario .novo-destinatario .categoria .container-inputs-2col {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
  .container-novo-destinatario .novo-destinatario .categoria .container-inputs-1col {
    width: 100%;
  }
}

@media (max-width: 470px) {
  .container-novo-destinatario .novo-destinatario .container-botoes {
    flex-direction: column;
    margin: 80px auto 10px auto; 
  }
  
  .container-novo-destinatario .novo-destinatario .container-botoes button {
    width: 100%;
    margin-bottom: 15px;
  }
}