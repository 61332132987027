.button-support {
    width: 158px;
    border-radius: 10px;
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.button-support-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #fff;
    gap: 10px;
}

.button-support-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    left: 25px;
    background-color: #25D366;
    border-radius: 50%;
    height: 45px;
    width: 45px;
}

.button-support-link svg {
    font-size: 28px;
    color: #fff;
}

.button-support-text {
    background-color: #25D366;
    font-weight: bold;
    font-size: 12px;
    padding: 10px 10px;
    border-radius: 0 5px 5px 0;
    position: relative;
    z-index: 1;
}