.card-agendamento.embarcado .linha button.liberar-viagem {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 0px 10px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.116) 0px 3px 8px;
}

.card-agendamento.embarcado .linha button.liberar-viagem {
  background-color: #3eedf7;
}

.card-agendamento.embarcado .linha button.liberar-entrada {
  background-color: #c58b00;
}

.card-agendamento.embarcado .linha button.liberar-viagem:hover {
  filter: brightness(105%);
}

.card-agendamento.embarcado .linha button.liberar-viagem .icone {
  margin-right: 3px;
}