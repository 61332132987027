.pagination-container {
  display: flex;
  list-style: none;
  outline: none;
  float: right;

  > .active > a {
    background-color: #001b48;
    color: #fff;
  }

  > li > a {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    margin: 0 2px;
  }

  > .active > span,
  > .active > a:hover,
  > .active > span:hover,
  > .active > a:focus,
  > .active > span:focus {
    background-color: #001b48;
    color: #fff;
    border-color: #fff;
    outline: none;
  }

  > li > a,
  > li > span {
    background-color: #9ab2c6;
    color: black;
  }

  > li > a:hover {
    filter: brightness(120%);
  }
}
