.form-input-peso {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
  margin-top: 20px;
  margin-bottom: 5px;
  
  select {
    background-color: #d6e8ee;
    padding: 9px 5px 9px 10px;
    border: none;
    border-radius: 8px;
    color: #8094aa;
  }

  input {
    width: 100%;
    background-color: #fff;
    padding: 10px 5px;
    border: none;
    border-radius: 0 8px 8px 0;
  }
}
