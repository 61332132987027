.registro-carga {
  display: flex;
  justify-content: space-between;
  padding: 20px 4%;
}

.registro-carga .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
}

.registro-carga .novo-registro {
  flex: 1;
  margin-right: 25px;
}

.registro-carga .novo-registro textarea {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #99b2c6;
  border-radius: 8px;
  padding: 10px;
}

.registro-carga .novo-registro .container-btn-enviar {
  display: flex;
  justify-content: flex-end;
}

.registro-carga .novo-registro .container-btn-enviar button {
  text-align: right;
  padding: 5px 12px;
  border: none;
  border-radius: 5px;
  background-color: #92d36e;
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
}

.registro-carga .historico-registros {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.registro-carga .historico-registros .quadro {
  flex: 1;
}

.registro-carga .historico-registros .lista-registros {
  max-height: 192px;
  overflow: auto;
}

.registro-carga .historico-registros .lista-registros p {
  margin-top: 10px;
  font-size: 12px;
}

.registro-carga .historico-registros .lista-registros p span.open-image {
  font-weight: bold;
  cursor: pointer;
  color: #001b48;
}

.registro-carga .historico-registros .lista-registros p span.open-image:hover {
  text-decoration: underline;
}

.registro-carga .historico-registros .lista-registros span.data {
  font-size: 10px;
  margin-left: 5px;
}

.container-visualizar-imagem-registro {
  width: 90%;
  height: 90%;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  animation-duration: 0.2s;
}

.container-visualizar-imagem-registro img {
  height: 100%;
  width: 100%;
}

.container-visualizar-imagem-registro .container-btn-visualizar-imagem-registro {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.container-visualizar-imagem-registro .container-btn-visualizar-imagem-registro button {
  width: 130px;
  padding: 5px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-weight: bold;
}

.container-visualizar-imagem-registro .container-btn-visualizar-imagem-registro button:hover {
  filter: brightness(120%);
}

@media (max-width: 980px) {
  .registro-carga {
    padding: 10px;
  }
}

@media (max-width: 750px) {
  .registro-carga {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .registro-carga .novo-registro {
    margin: 0 0 10px 0;
  }
  .registro-carga .novo-registro,
  .registro-carga .historico-registros {
    width: 100%;
  }
}
