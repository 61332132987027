.overlay-modal-selecionar-cte {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10002;
}

.modal-selecionar-cte {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-height: 90vh;
  background-color: #f7fafc;
  border-radius: 5px;
  overflow: auto;
}

.modal-selecionar-cte .selecionar-cte .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #3a4959;
  cursor: pointer;
}

.modal-selecionar-cte .selecionar-cte .icone-fechar:hover {
  filter: brightness(130%);
}

.modal-selecionar-cte .selecionar-cte .titulo {
  font-weight: bold;
  color: #001b48;
}

.modal-selecionar-cte .container-campos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5%;
  margin: 20px 0 40px 0;
}

.modal-selecionar-cte .container-campos .primeira-etapa {
  display: flex;
  justify-content: space-between;
}

.modal-selecionar-cte .container-campos .segunda-etapa {
  display: flex;
  justify-content: space-between;
}

.modal-selecionar-cte .container-campos .primeira-etapa .container-input-search-cte,
.modal-selecionar-cte .container-campos .primeira-etapa .container-input-comum {
  flex: 1;
}

.modal-selecionar-cte .container-campos .segunda-etapa .separador {
  font-size: 25px;
  font-weight: bold;
}

.modal-selecionar-cte .container-campos .segunda-etapa .serie input {
  width: 115px;
  margin-right: 10px;
}

.modal-selecionar-cte .container-campos .segunda-etapa .serie1 input {
  width: 130px;
  margin: 0 10px;
}

.modal-selecionar-cte .container-campos .container-input-search-cte {
  display: flex;
  align-items: center;
  background-color: #018abe;
  padding: 8px;
  border-radius: 8px;
  margin-right: 3%;
}

.modal-selecionar-cte .container-campos .container-input-search-cte input {
  background-color: #018abe;
  color: #fff;
  border: none;
}

.modal-selecionar-cte .container-campos .container-input-search-cte input::placeholder {
  font-size: 12px;
  color: #fff;
}

.modal-selecionar-cte .container-campos .container-input-search-cte .icone {
  font-size: 18px;
  margin-left: 5px;
  color: #fff;
}

.modal-selecionar-cte .selecionar-cte {
  padding: 20px 15px;
}

.modal-selecionar-cte .selecionar-cte .container-input-comum {
  position: relative;
}

.modal-selecionar-cte .selecionar-cte .container-input-comum input,
.modal-selecionar-cte .selecionar-cte .container-input-comum select {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-selecionar-cte .selecionar-cte .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.modal-selecionar-cte .selecionar-cte .container-input-comum input:focus+span,
.modal-selecionar-cte .selecionar-cte .container-input-comum input:disabled+span,
.modal-selecionar-cte .selecionar-cte .container-input-comum input:valid+span,
.modal-selecionar-cte .selecionar-cte .container-input-comum select:focus+span,
.modal-selecionar-cte .selecionar-cte .container-input-comum select:disabled+span,
.modal-selecionar-cte .selecionar-cte .container-input-comum select:valid+span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes {
  overflow: auto;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table thead tr th {
  padding: 10px 3px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  border-right: 1px solid #fff;
  background-color: #3d8af7;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table thead tr th:first-of-type {
  border-top-left-radius: 5px;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table thead tr th:last-of-type {
  border-top-right-radius: 5px;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table tbody tr {
  border-top: 1px solid #fff;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table tbody tr td {
  padding: 12px 15px;
  font-size: 12px;
  border-right: 1px solid #fff;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table tbody tr td {
  background-color: #d6e8ee;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table tbody tr td.coluna-destaque {
  font-weight: bold;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table tbody tr td.coluna-total {
  text-align: right;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table tbody tr input {
  width: 60px;
  border-radius: 5px;
  border: none;
  padding: 0 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes table tbody tr.linha-totais {
  border-top: 1px solid #fff;
  font-weight: bold;
  color: #001b48;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes .container-input-checkbox {
  position: relative;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes .container-input-checkbox {
  user-select: none;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes .container-input-checkbox .checkbox {
  cursor: pointer;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes .container-input-checkbox .checkbox input[type="checkbox"]+span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.modal-selecionar-cte .selecionar-cte .container-tabela-buscar-ctes .container-input-checkbox .checkbox input[type="checkbox"]:checked+span.checked {
  background: #3d8af7 -19px top no-repeat;
}

.modal-selecionar-cte .selecionar-cte .btn-selecionar {
  display: block;
  margin: 30px auto 0 auto;
  padding: 12px 40px;
  background-color: #018abe;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
}

.modal-selecionar-cte .selecionar-cte .btn-selecionar:hover {
  filter: brightness(120%);
}

@media (max-width: 1040px) {
  .modal-selecionar-cte .container-campos .segunda-etapa .serie1 input {
    width: 90px;
  }
}

@media (max-width: 920px) {
  .modal-selecionar-cte .container-campos {
    display: flex;
    flex-direction: column;
  }

  .modal-selecionar-cte .container-campos .segunda-etapa {
    margin-top: 10px;
    justify-content: flex-start;
  }

  .modal-selecionar-cte .container-campos .segunda-etapa .serie input {
    width: auto;
  }

  .modal-selecionar-cte .container-campos .segunda-etapa .serie1 input {
    width: auto;
    margin: 0 10px;
  }
}

@media (max-width: 690px) {
  .modal-selecionar-cte .container-campos .primeira-etapa {
    flex-direction: column;
  }
  .modal-selecionar-cte .container-campos .primeira-etapa .container-input-search-cte {
    margin: 0 0 10px 0;
  }
  .modal-selecionar-cte .container-campos .segunda-etapa {
    flex-direction: column;
  }
  .modal-selecionar-cte .container-campos .segunda-etapa .serie input {
    width: 100%;
  }
  .modal-selecionar-cte .container-campos .segunda-etapa .separador {
    display: block;
    margin: 0 auto;
  }
  .modal-selecionar-cte .container-campos .primeira-etapa .container-input-search-cte input,
  .modal-selecionar-cte .container-campos .segunda-etapa .serie1 input,
  .modal-selecionar-cte .container-campos .segunda-etapa .serie2 input {
    width: 100%;
  }
  .modal-selecionar-cte .container-campos .segunda-etapa .serie1 input {
    margin: 0 0 5px;
  }
}