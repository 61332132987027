.container-rastro-coins {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.rastro-coins {
  display: flex;
  gap: 40px
}

.rastro-coins .coluna-precos {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.rastro-coins .quadro-compra .opcoes {
  display: flex;
}

.rastro-coins .quadro-compra .opcoes div.opcao {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f4fa;
  height: 45px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
  cursor: pointer;
}

.rastro-coins .quadro-compra .opcoes div.opcao.selected {
  background-color: #3eedf7;
}

.rastro-coins .quadro-compra .opcoes div.opcao p {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
  margin-right: 10px;
}

.rastro-coins .quadro-compra .opcoes div.opcao.selected p {
  color: #fff;
}

.rastro-coins .quadro-compra .opcoes .checkbox-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.rastro-coins .quadro-compra .opcoes input:checked {
  background-color: #fff;
  position: relative;
}

.rastro-coins .quadro-compra .opcoes input:checked::before {
  content: "\2B24";
  font-size: 1.2em;
  color: #3eedf7;
  position: absolute;
  right: 4.3px;
  top: -1.3px;
}

.rastro-coins .coluna-precos .background {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rastro-coins .coluna-precos .background img {
  width: 58%;
}

.rastro-coins .coluna-precos table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.rastro-coins .coluna-precos table thead tr th {
  padding: 18px 3px;
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
  text-align: center;
  background: #f1f4fa;
  border-right: 1px solid #ced9e5;
}

.rastro-coins .coluna-precos table thead tr th:first-of-type {
  border-top-left-radius: 5px;
}

.rastro-coins .coluna-precos table thead tr th:last-of-type {
  border-top-right-radius: 5px;
}

.rastro-coins .coluna-precos table tbody tr {
  text-transform: capitalize;
}

.rastro-coins .coluna-precos table tbody tr.linha-destaque {
  background: #f1f4fa;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.rastro-coins .coluna-precos table tbody tr td {
  padding: 18px;
  border-right: 1px solid #ced9e5;
}
