.carga-etapa-2 {
  display: flex;
  flex-direction: column;
  margin: 0 20px 5px 20px;
}

.carga-etapa-2 .conteudo {
  flex: 1;
}

.carga-etapa-2 .navegadores {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.carga-etapa-2 .navegadores .icone {
  font-size: 30px;
  color: #bacad9;
}

.carga-etapa-2 .navegadores .icone.active {
  color: #20477e;
}

.carga-etapa-2 .navegadores .icone.active:hover {
  color: #20477e;
  transform: scale(1.1);
  cursor: pointer;
}

.carga-etapa-2 .conteudo .titulo-etapa {
  font-weight: bold;
}

.carga-etapa-2 .conteudo .quadro-informacoes {
  margin-top: 20px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.116) 0px 3px 8px;
}

.carga-etapa-2 .conteudo .quadro-informacoes .titulo-quadro {
  display: flex;
  align-items: center;
}

.carga-etapa-2 .conteudo .quadro-informacoes .titulo-quadro .icone {
  font-size: 16px;
  color: #bacad9;
  margin-right: 5px;
}

.carga-etapa-2 .conteudo .quadro-informacoes .titulo-quadro p {
  font-weight: bold;
}

.carga-etapa-2 .conteudo .quadro-informacoes .titulo-quadro p span {
  color: red;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  justify-content: space-between;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro {
  flex:  1;
  margin-top: 20px;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .titulo-opcao {
  font-weight: bold;
  margin-bottom: 10px;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .lista-opcoes {
  display: flex;
  flex-direction: column;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .lista-opcoes .container-input-checkbox {
  position: relative;
  margin-top: 5px;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .lista-opcoes .container-input-checkbox {
  user-select: none;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .lista-opcoes .container-input-checkbox .checkbox {
  cursor: pointer;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .lista-opcoes .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .lista-opcoes .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 15px;
  height: 15px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .lista-opcoes .container-input-checkbox .checkbox input[type="checkbox"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .lista-opcoes .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  margin-right: 3px;
}

.carga-etapa-2 .conteudo .container-inputs-3col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3%;
}

.carga-etapa-2 .conteudo .container-input {
  position: relative;
  margin-top: 15px;
}

.carga-etapa-2 .conteudo .container-input .icone {
  color: #bacad9;
  margin-right: 5px;
}

.carga-etapa-2 .conteudo .container-input input {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.carga-etapa-2 .conteudo .container-input span {
  position: absolute;
  top: 14px;
  left: 9px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.carga-etapa-2 .conteudo .container-input span i {
  color: red;
  font-style: normal;
}

.carga-etapa-2 .conteudo .container-input input:focus + span,
.carga-etapa-2 .conteudo .container-input input:disabled + span,
.carga-etapa-2 .conteudo .container-input input:valid + span,
.carga-etapa-2 .conteudo .container-input input[type="datetime-local"]:focus + span,
.carga-etapa-2 .conteudo .container-input input[type="datetime-local"]:disabled + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.carga-etapa-2 .conteudo .container-input input[type="datetime-local"]:invalid::-webkit-datetime-edit,
.carga-etapa-2 .conteudo .container-input input[type="datetime-local"]:disabled::-webkit-datetime-edit {
  color: transparent;
}

.carga-etapa-2 .conteudo .container-input input[type="datetime-local"]:focus::-webkit-datetime-edit {
  color: black !important;
}

@media (max-width: 800px) {
  .carga-etapa-2 .conteudo .quadro-informacoes .container-opcoes .quadro .lista-opcoes .container-input-checkbox {
    white-space: nowrap;
  }
  .carga-etapa-2 .conteudo .container-inputs-3col {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 3%;
  }
}