.carga-etapa-3 {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 20px 5px 20px;
}

.is-loading-etapa-3 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  
  z-index: 455;

  background: rgba(255, 255, 255, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  font-weight: bold;
}

.carga-etapa-3 .conteudo {
  flex: 1;
}

.carga-etapa-3 .navegadores {
  position: absolute;
  bottom: 6px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.carga-etapa-3 .navegadores .icone {
  font-size: 30px;
  color: #bacad9;
}

.carga-etapa-3 .navegadores .icone.active {
  color: #20477e;
}

.carga-etapa-3 .navegadores .icone.active:hover {
  color: #20477e;
  transform: scale(1.1);
  cursor: pointer;
}

.carga-etapa-3 .conteudo .titulo-etapa {
  font-weight: bold;
}

.carga-etapa-3 .conteudo .container-input-radio {
  position: relative;
  margin-top: 5px;
}

.carga-etapa-3 .conteudo .container-input-radio {
  user-select: none;
}

.carga-etapa-3 .conteudo .container-input-radio .radio {
  cursor: pointer;
}

.carga-etapa-3 .conteudo .container-input-radio .radio input[type="radio"] {
  display: none;
}

.carga-etapa-3 .conteudo .container-input-radio .radio input[type="radio"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 15px;
  height: 15px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.carga-etapa-3 .conteudo .container-input-radio .radio input[type="radio"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.carga-etapa-3 .conteudo .container-input-radio .radio input[type="radio"] + span.checked {
  margin-right: 3px;
}

.carga-etapa-3 .conteudo .container-inputs-3col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3%;
}

.carga-etapa-3 .conteudo .container-input {
  position: relative;
  margin-top: 15px;
}

.carga-etapa-3 .conteudo .container-input .icone {
  color: #bacad9;
  margin-right: 5px;
}

.carga-etapa-3 .conteudo .container-input input,
.carga-etapa-3 .conteudo .container-input select,
.carga-etapa-3 .conteudo .container-input textarea  {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.carga-etapa-3 .conteudo .container-input select {
  padding: 11px 10px 11px 2px;
}

.carga-etapa-3 .conteudo .container-input textarea {
  resize: none;
  border-radius: 20px;
}

.carga-etapa-3 .conteudo .container-input span {
  position: absolute;
  top: 14px;
  left: 9px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.carga-etapa-3 .conteudo .container-input span i {
  color: red;
  font-style: normal;
}

.carga-etapa-3 .conteudo .container-input input:focus + span,
.carga-etapa-3 .conteudo .container-input input:disabled + span,
.carga-etapa-3 .conteudo .container-input select:focus + span,
.carga-etapa-3 .conteudo .container-input select:disabled + span,
.carga-etapa-3 .conteudo .container-input input:valid + span,
.carga-etapa-3 .conteudo .container-input select:valid + span,
.carga-etapa-3 .conteudo .container-input textarea:focus + span,
.carga-etapa-3 .conteudo .container-input textarea:disabled + span,
.carga-etapa-3 .conteudo .container-input textarea:valid + span,
.carga-etapa-3 .conteudo .container-input input[type="datetime-local"]:focus + span,
.carga-etapa-3 .conteudo .container-input input[type="datetime-local"]:disabled + span,
.carga-etapa-3 .conteudo .container-input input[type="datetime-local"]:invalid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.carga-etapa-3 .conteudo .container-input input[type="datetime-local"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.carga-etapa-3 .conteudo .input-radio {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.carga-etapa-3 .conteudo .input-radio .label {
  display: flex;
  align-items: center;
}

.carga-etapa-3 .conteudo .input-radio .label p {
  color: gray;
}

.carga-etapa-3 .conteudo .input-radio .label .icone {
  margin-left: 5px;
  color: #bacad9;
}

.carga-etapa-3 .conteudo .input-radio .container-radio {
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin-top: 5px;
}

.carga-etapa-3 .conteudo .input-radio.col-1 .container-radio {
  width: 365px;
}

.carga-etapa-3 .conteudo .input-radio .container-radio .container-input-radio {
  position: relative;
}

.carga-etapa-3 .conteudo .input-radio .container-radio .container-input-radio {
  font-weight: bold;
  user-select: none;
}

.carga-etapa-3 .conteudo .input-radio .container-radio .container-input-radio .radio {
  cursor: pointer;
}

.carga-etapa-3 .conteudo .input-radio .container-radio .container-input-radio .radio input[type="radio"] {
  display: none;
}

.carga-etapa-3 .conteudo .input-radio .container-radio .container-input-radio .radio input[type="radio"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.carga-etapa-3 .conteudo .input-radio .container-radio .container-input-radio .radio input[type="radio"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.carga-etapa-3 .conteudo .input-radio .container-radio .container-input-radio .radio input[type="radio"] + span.checked {
  margin-right: 5px;
}

.carga-etapa-3 .conteudo .container-inputs-2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3%;
}

.carga-etapa-3 .conteudo .container-botoes {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 85%;
  margin: 40px auto 0 auto;
  z-index: 3;
}

.carga-etapa-3 .conteudo .container-botoes button {
  width: 150px;
  padding: 12px 0;
  background-color: #ef2d41;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.carga-etapa-3 .conteudo .container-botoes button:hover {
  filter: brightness(120%);
}

.carga-etapa-3 .conteudo .container-botoes button.btn-criar {
  background-color: #71a355;
  border-color: #001b48;
  color: #fff;
}

.carga-etapa-3 .conteudo .msg-erro {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

@media (max-width: 800px) {
  .carga-etapa-3 .conteudo .container-inputs-2col {
    grid-template-columns: 1fr;
  }
}