.container-historico {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.116) 0px 3px 8px;
}

.container-historico .titulo {
  font-size: 20px;
  font-weight: bold;
  color: #172b4d;
}

.container-historico .container-quadros-historico-cargas {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -20px;
}

.container-historico .container-quadros-historico-cargas .quadro {
  border: 1px solid #99b2c6;
  box-shadow: rgba(0, 0, 0, 0.185) 0px 1px 8px;
  height: 80px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  padding: 8px;
  margin-right: 10px;
}

.container-historico .container-quadros-historico-cargas .quadro.col-2 {
  display: flex;
  align-items: center;
} 

.container-historico .container-quadros-historico-cargas .quadro.col-2 .numero {
  color: #3d8af7;
}

.container-historico .container-quadros-historico-cargas .quadro.ctes {
  padding: 8px 20px;
}

.container-historico .container-quadros-historico-cargas .quadro.frete div {
  display: flex;
  align-items: center;
}

.container-historico .container-quadros-historico-cargas .quadro.frete div p {
  flex: 1;
  text-align: center;
  color: #3d8af7;
}

.container-historico .container-quadros-historico-cargas .quadro.cadastros {
  padding: 8px 15px;
}

.container-historico .container-quadros-historico-cargas .quadro img {
  height: 40px;
  width: 50px;
}

.container-historico .container-quadros-historico-cargas .quadro .titulo {
  font-size: 12px;
  color: #99b2c6;
}


.container-historico .container-quadros-historico-cargas .quadro .qte-solicitadas {
  color: #3d8af7;
}

.container-historico .container-quadros-historico-cargas .quadro .qte-entregues {
  color: #92d36e;
}

.container-historico .container-quadros-historico-cargas button {
  border: 1px solid #99b2c6;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  border-radius: 5px;
}

.container-historico .container-quadros-historico-cargas button:hover {
  filter: brightness(110%);
}

.container-historico .container-quadros-historico-cargas button .icone {
  color: #3d8af7;
  font-size: 20px;
  margin-right: 10px;
}

.container-historico .container-quadros-historico-cargas button p {
  font-weight: bold;
  color: #3d8af7;
}

.container-historico .container-tabela-historico-cargas {
  margin-top: 20px;
  max-width: 83vw;
  overflow: auto;
}

.container-historico .container-tabela-historico-cargas table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.container-historico .container-tabela-historico-cargas table thead tr th {
  padding: 10px 3px;
  color: gray;
  background-color: #f1f3f9;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  border-right: 1px solid #fff;
}

.container-historico .container-tabela-historico-cargas table thead tr th div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.container-historico .container-tabela-historico-cargas table thead tr th .icone-ordenacao {
  margin-left: 2px;
}

.container-historico .container-tabela-historico-cargas table tbody tr td {
  padding: 15px;
  font-size: 12px;
  border-right: 1px solid #fff;
}

.container-historico .container-tabela-historico-cargas table tbody tr td.coluna-status {
  display: flex;
  align-items: center;
}

.container-historico .container-tabela-historico-cargas table tbody tr td.coluna-status .icone-entrega {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #99b2c6;
}

.container-historico .container-tabela-historico-cargas table tbody tr td.coluna-status .icone-entrega.no-prazo {
  background-color: #92d36e;
}

.container-historico .container-tabela-historico-cargas table tbody tr td.coluna-status .icone-entrega.atraso {
  background-color: #ef2d41;
}

.container-historico .container-tabela-historico-cargas table tbody tr td.coluna-status .icone-entrega.pendencia {
  background-color: #fec63d;
}

.container-historico .container-tabela-historico-cargas table tbody tr td.coluna-status .icone-entrega.ocorrencia {
  background-color: #c58b00;
}

.container-historico .container-tabela-historico-cargas table tbody tr td.coluna-status .icone-entrega.todos {
  background-color: #99b2c6;
}

.container-historico .container-tabela-historico-cargas table tbody tr.linha-impar td {
  background-color: #77adf9;
}

.container-historico .container-tabela-historico-cargas table tbody tr.modal td {
  cursor: pointer;
}

.container-historico .container-tabela-historico-cargas table tbody tr.modal:hover {
  transform: scale(1.001);
}

.container-historico .container-tabela-historico-cargas p.expandir-linha {
  cursor: pointer;
  text-decoration: underline;
  z-index: 3;
}

.container-historico .container-paginacao-historico {
  padding-bottom: 5px;
}

.container-historico .container-paginacao-historico .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.container-historico .container-paginacao-historico .pagination>.active>a {
  background-color: #3eeef7a8;
  color: black;
}

.container-historico .container-paginacao-historico .pagination>li>a {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

.container-historico .container-paginacao-historico .pagination>.active>a,
.container-historico .container-paginacao-historico .pagination>.active>span,
.container-historico .container-paginacao-historico .pagination>.active>a:hover,
.container-historico .container-paginacao-historico .pagination>.active>span:hover,
.container-historico .container-paginacao-historico .pagination>.active>a:focus,
.container-historico .container-paginacao-historico .pagination>.active>span:focus {
  color: #fff;
  border-color: #fff;
  outline: none;
}

.container-historico .container-paginacao-historico .pagination>li>a,
.container-historico .container-paginacao-historico .pagination>li>span {
  border: 1px solid #99b2c6;
  color: #99b2c6;
}

.container-historico .container-paginacao-historico .pagination>li>a:hover {
  filter: brightness(95%);
}

.container-historico .container-paginacao-historico .pagination>li:first-child>a,
.container-historico .container-paginacao-historico .pagination>li:first-child>span,
.container-historico .container-paginacao-historico .pagination>li:last-child>a,
.container-historico .container-paginacao-historico .pagination>li:last-child>span {
  border-radius: 50%;
}

.container-loading-historico-cargas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.alert-erro-planinhas {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-erro-planinhas .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-erro-planinhas .react-confirm-alert-body {
  color: #1c2732;
}

.alert-erro-planinhas button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-erro-planinhas button:hover {
  filter: brightness(120%);
}

.alert-erro-planinhas button.botao-confirmar {
  background-color: #3d8af7;
}

@media (max-width: 980px) {
  .container-historico {
    padding: 20px 10px;
  }
  .container-historico .container-paginacao-historico {
    padding-bottom: 10px;
  }
}