.container-gerenciar-embarcador {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-gerenciar-embarcador .btn-voltar-pagina {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  font-weight: bold;
  margin-bottom: 20px;
}

.container-gerenciar-embarcador .btn-voltar-pagina:hover {
  filter: brightness(120%);
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-topo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-topo .titulo-pagina {
  font-size: 20px;
  font-weight: bold;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-topo .subtitulo-pagina {
  margin-top: 5px;
  font-weight: bold;
  color: #001b48;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-topo .btn-novo-embarcador {
  display: flex;
  align-items: center;
  padding: 12px 8px;
  background-color: #001b48;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-topo .btn-novo-embarcador:hover {
  filter: brightness(120%);
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-topo .btn-novo-embarcador .icone {
  font-size: 25px;
  margin-right: 10px;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 50px 0;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-search .input-search {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 12px 10px 12px 10px;
  background-color: #f1f4fa;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-search .input-search input {
  flex: 1;
  border: none;
  background-color: #f1f4fa;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-search .input-search input::placeholder {
  color: gray;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-search .input-search .icone {
  margin-left: 5px;
  font-size: 22px;
  color: #9ab2c6;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-search .btn-buscar {
  width: 120px;
  background-color: #001b48;
  margin-left: 25px;
  height: 48px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-search .btn-buscar:hover {
  filter: brightness(120%);
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-tabela-listar-embarcadores {
  margin-top: 20px;
  max-width: 100%;
  overflow: auto;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-tabela-listar-embarcadores table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-tabela-listar-embarcadores table thead tr th {
  padding: 10px 0;
  border-bottom: 1px solid #9ab2c6;
  font-size: 16px;
  color: black;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-tabela-listar-embarcadores table tbody tr.divisoria-linhas-tabela {
  height: 10px;
  background-color: #fff;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-tabela-listar-embarcadores table tbody tr td {
  background-color: #f1f4fa;
  padding: 15px 10px;
  color: gray;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-tabela-listar-embarcadores table tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-tabela-listar-embarcadores table tbody tr td:last-child {
  border-radius: 0px 8px 8px 0px;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-tabela-listar-embarcadores table .coluna-acoes .icone {
  margin: 0 3px;
  font-size: 22px;
  color: #001b48;
  cursor: pointer;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-tabela-listar-embarcadores table .coluna-acoes .icone:hover {
  filter: brightness(120%);
}

.alert-remover-embarcador {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-remover-embarcador .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-remover-embarcador button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-remover-embarcador button:hover {
  filter: brightness(120%);
}

.alert-remover-embarcador button.botao-confirmar {
  background-color: #cc3300;
}

.alert-remover-embarcador button.botao-cancelar {
  background-color: #001b48;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador {
  margin-top: 20px;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>.active>a {
  background-color: #0d78f2;
  color: black;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>li>a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>.active>a,
.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>.active>span,
.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>.active>a:hover,
.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>.active>span:hover,
.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>.active>a:focus,
.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>.active>span:focus {
  background-color: #001b48;
  color: #fff;
  border-color: #fff;
  outline: none;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>li>a,
.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>li>span {
  background-color: #9ab2c6;
  color: black;
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>li>a:hover {
  filter: brightness(120%);
}

.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>li:first-child>a,
.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>li:first-child>span,
.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>li:last-child>a,
.container-gerenciar-embarcador .gerenciar-embarcador .container-paginacao-embarcador .pagination>li:last-child>span {
  border-radius: 5px
}

@media (max-width: 670px) {
  .container-gerenciar-embarcador {
    padding: 25px 10px;
    overflow-y: hidden;
  }
  .container-gerenciar-embarcador .gerenciar-embarcador .container-topo {
    flex-direction: column;
    text-align: center;
  }
  .container-gerenciar-embarcador .gerenciar-embarcador .container-topo .btn-novo-embarcador {
    width: 100%;
    justify-content: center;
    margin-top: 15px;
  }
  .container-gerenciar-embarcador .gerenciar-embarcador .container-search, 
  .container-gerenciar-embarcador .gerenciar-embarcador .container-search .input-search {
    width: 100%;
  }
  .container-gerenciar-embarcador .gerenciar-embarcador .container-search .btn-buscar {
    width: 120px;
    margin-left: 5px;
  }
}

@media (max-width: 450px) {
  .container-gerenciar-embarcador .gerenciar-embarcador .container-search {
    flex-direction: column;
    width: 100%;
  }
  .container-gerenciar-embarcador .gerenciar-embarcador .container-search .input-search {
    width: 100%;
  }
  .container-gerenciar-embarcador .gerenciar-embarcador .container-search .btn-buscar {
    width: 100%;
    margin: 10px 0 0 0;
  }
}