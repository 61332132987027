.form-checkbox {
  position: relative;
  font-weight: bold;
  user-select: none;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &:not(.small) {
    margin-top: 20px;
  }
  
  .checkbox {
    cursor: pointer;

    input[type="checkbox"] {
      display: none;

      + span.checked {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 20px;
        height: 20px;
        margin: -1px 0px 0 0;
        vertical-align: middle;
        background-color: #fff;
        border: 1px solid #9ab2c6;
        border-radius: 50%;
        margin-right: 10px;
      }

      &:checked {
        + span.checked {
          background: #001b48 -19px top no-repeat;
        }      
      }
    }
  }
}
