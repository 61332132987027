.container-login {
  position: relative;
  min-height: 100vh;
}

.container-login .background {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(70%);
  pointer-events: none;
}

.container-login .quadro-login {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: #fff;
  z-index: 2;
}

.container-login .quadro-login .titulo-quadro {
  text-align: center;
  padding: 20px 0;
  background-color: #d6e8ee;
  border-radius: 10px 10px 0 0;
}

.container-login .quadro-login .titulo-quadro img {
  height: 95px;
  width: 100px;
}

@media (max-width: 850px) {
  .container-login .quadro-login {
    width: 80%;
  }
}

@media (max-width: 435px) {
  .container-login .quadro-login {
    width: 95%;
  }
}