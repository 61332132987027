.alert-rastro {
  z-index: 2000;
  animation-duration: 0.2s;

  .react-confirm-alert-body {
    color: #1c2732;
    padding: 30px 50px;
  }
  
  button {
    font-size: 14px;
    font-weight: bold;
    color: #fff;

    &:hover {
      filter: brightness(120%);
    }

    &.botao-confirmar {
      background-color: #cc3300;
    }

    &.botao-cancelar {
      background-color: #001b48;
    }
  }
}
