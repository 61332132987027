.modal-perfil-motorista-carga-documentos .dados-pedido {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.modal-perfil-motorista-carga-documentos .dados-pedido .progresso {
  flex: 0.15;
  height: 60px;
}

.modal-perfil-motorista-carga-documentos .dados-pedido .progresso .CircularProgressbar-text {
  font-size: 30px;
  font-weight: bold;
  fill: #333;
}

.modal-perfil-motorista-carga-documentos .dados-pedido .progresso .CircularProgressbar-path {
  stroke: #008282;
}

.modal-perfil-motorista-carga-documentos .dados-pedido .informacoes div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-perfil-motorista-carga-documentos .dados-pedido .informacoes div .icone {
  font-size: 15px;
  color: #3c89f5;
  margin-right: 5px;
}

.modal-perfil-motorista-carga-documentos .dados-pedido .informacoes div p span,
.modal-perfil-motorista-carga-documentos .dados-pedido .informacoes .pedido span {
  font-weight: bold;
}

.modal-perfil-motorista-carga-documentos .dados-pedido .informacoes .pedido {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

.modal-perfil-motorista-carga-documentos .dados-pedido .informacoes .message-app {
  color: #FF0000;
  margin-top: 15px;
}

.modal-perfil-motorista-carga-documentos .separador {
  margin: 0 auto;
  width: 92%;
  background-color: #ced9e5;
  height: 0.5px;
  border: none;
}

.modal-perfil-motorista-carga-documentos .documentos-carga {
  padding: 20px 4%;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .lista-documentos {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  flex-wrap: wrap;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .lista-documentos .documento {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2%;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .lista-documentos .documento .icone-remover {
  position: absolute;
  right: 0;
  font-size: 20px;
  color: red;
  cursor: pointer;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .lista-documentos .documento .icone-remover:hover {
  transform: scale(1.1);
}

.modal-perfil-motorista-carga-documentos .documentos-carga .lista-documentos .documento .icone-documento {
  font-size: 50px;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .lista-documentos .documento p {
  font-size: 12px;
  text-align: center;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .btn-upload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .btn-upload input[type="file"] {
  position: absolute;
  top: 50%;
  left: 57%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .btn-upload label {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background-color: #fff;
  color: #3c89f5;
  border: 1px solid #3c89f5;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
  cursor: pointer;
}

.modal-perfil-motorista-carga-documentos .documentos-carga .btn-upload label .icone {
  font-size: 20px;
  color: #3c89f5;
  margin-right: 5px;
}

.container-visualizar-imagem-registro {
  width: 90%;
  height: 90%;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  animation-duration: 0.2s;
}

.container-visualizar-imagem-registro img {
  height: 100%;
  width: 100%;
}

.container-visualizar-imagem-registro .container-btn-visualizar-imagem-registro {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.container-visualizar-imagem-registro .container-btn-visualizar-imagem-registro button {
  width: 130px;
  padding: 5px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-weight: bold;
}

.container-visualizar-imagem-registro .container-btn-visualizar-imagem-registro button:hover {
  filter: brightness(120%);
}

.alert-remover-documento  {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-remover-documento  .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-remover-documento button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-remover-documento button:hover {
  filter: brightness(120%);
}

.alert-remover-documento button.botao-confirmar {
  background-color: #cc3300;
}

.alert-remover-documento button.botao-cancelar {
  background-color: #001b48;
}

@media (max-width: 980px) {
  .modal-perfil-motorista-carga-documentos .documentos-cargan{
    padding: 10px;
  }
}
