.overlay-modal-novo-manifesto {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10001;
}

.modal-novo-manifesto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-height: 90vh;
  background-color: #f7fafc;
  border-radius: 5px;
  overflow: auto;
}

.modal-novo-manifesto .novo-manifesto .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #3a4959;
  cursor: pointer;
}

.modal-novo-manifesto .novo-manifesto .icone-fechar:hover {
  filter: brightness(130%);
}

.modal-novo-manifesto .novo-manifesto .container-data-hora {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.modal-novo-manifesto .novo-manifesto .container-data-hora .container-input-comum {
  width: 20%;
  margin-right: 10px;
}

.modal-novo-manifesto .novo-manifesto .container-data-hora .container-input-comum span {
  color: #fff;
}

.modal-novo-manifesto .novo-manifesto .container-data-hora .container-input-comum:last-of-type {
  width: 15%;
  margin-right: 0;
}

.modal-novo-manifesto .novo-manifesto .container-data-hora .container-input-comum input {
  background-color: #97cadb;
  color: #fff;
}

.modal-novo-manifesto .novo-manifesto .container-data-hora .container-input-comum input:focus+span,
.modal-novo-manifesto .novo-manifesto .container-data-hora .container-input-comum input:disabled+span,
.modal-novo-manifesto .novo-manifesto .container-data-hora .container-input-comum input:valid+span {
  color: #fff;
  background-color: #97cadb;
}

.modal-novo-manifesto .novo-manifesto .container-data-hora .container-input-comum input:disabled {
  color: #fff;
}

.modal-novo-manifesto .novo-manifesto .icone-novo-vale {
  height: 37px;
}

.modal-novo-manifesto .novo-manifesto .icone-novo-vale:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.modal-novo-manifesto .novo-manifesto .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
  text-align: center;
}

.modal-novo-manifesto .novo-manifesto {
  padding: 20px 15px;
}

.modal-novo-manifesto .novo-manifesto .container-formulario {
  position: relative;
  margin-top: 20px;
  background-color: #f1f4fa;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 15px;
  border-radius: 8px;
}

.modal-novo-manifesto .novo-manifesto .container-formulario .container-titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-novo-manifesto .novo-manifesto .container-formulario .container-titulo .titulo-formulario {
  font-weight: bold;
  color: #001b48;
}

.modal-novo-manifesto .novo-manifesto .container-formulario .container-titulo .titulo-formulario.categoria {
  margin-top: 20px;
}

.modal-novo-manifesto .novo-manifesto .container-formulario .container-titulo .remover-vale {
  height: 20px;
  color: red;
  cursor: pointer;
}

.modal-novo-manifesto .novo-manifesto .container-formulario .container-titulo .remover-vale:hover {
  transform: scale(1.05);
}

.modal-novo-manifesto .novo-manifesto .container-formulario-duplo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5%;
}

.modal-novo-manifesto .novo-manifesto .container-inputs-4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3%;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.modal-novo-manifesto .novo-manifesto .container-inputs-3col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3%;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.modal-novo-manifesto .novo-manifesto .container-inputs-2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3%;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.modal-novo-manifesto .novo-manifesto .container-inputs-1col {
  grid-template-columns: 1fr;
  margin-top: 20px;
}

.modal-novo-manifesto .novo-manifesto .container-input-comum {
  position: relative;
}

.modal-novo-manifesto .novo-manifesto .container-input-comum input,
.modal-novo-manifesto .novo-manifesto .container-input-comum select,
.modal-novo-manifesto .novo-manifesto .container-input-comum textarea {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-novo-manifesto .novo-manifesto .container-input-comum textarea {
  resize: none;
}

.modal-novo-manifesto .novo-manifesto .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.modal-novo-manifesto .novo-manifesto .container-input-comum input:focus+span,
.modal-novo-manifesto .novo-manifesto .container-input-comum input:disabled+span,
.modal-novo-manifesto .novo-manifesto .container-input-comum input:valid+span,
.modal-novo-manifesto .novo-manifesto .container-input-comum select:focus+span,
.modal-novo-manifesto .novo-manifesto .container-input-comum select:disabled+span,
.modal-novo-manifesto .novo-manifesto .container-input-comum select:valid+span,
.modal-novo-manifesto .novo-manifesto .container-input-comum textarea:focus+span,
.modal-novo-manifesto .novo-manifesto .container-input-comum textarea:disabled+span,
.modal-novo-manifesto .novo-manifesto .container-input-comum textarea:valid+span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.modal-novo-manifesto .novo-manifesto .container-input-comum input:disabled,
.modal-novo-manifesto .novo-manifesto .container-input-comum select:disabled,
.modal-novo-manifesto .novo-manifesto .container-input-comum textarea:disabled {
  color: #3d8af7;
}

.modal-novo-manifesto .novo-manifesto .container-input-checkbox {
  position: relative;
  margin-top: 20px;
}

.modal-novo-manifesto .novo-manifesto .container-input-checkbox {
  user-select: none;
}

.modal-novo-manifesto .novo-manifesto .container-input-checkbox .checkbox {
  cursor: pointer;
}

.modal-novo-manifesto .novo-manifesto .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.modal-novo-manifesto .novo-manifesto .container-input-checkbox .checkbox input[type="checkbox"]+span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.modal-novo-manifesto .novo-manifesto .container-input-checkbox .checkbox input[type="checkbox"]:checked+span.checked {
  background: #3d8af7 -19px top no-repeat;
}

.modal-novo-manifesto .novo-manifesto .container-input-checkbox .checkbox input[type="checkbox"]+span.checked {
  margin-right: 10px;
}

.modal-novo-manifesto .novo-manifesto .btn-selecionar-cte {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin: 20px 0;
  padding: 8px 20px;
  background-color: #018abe;
  color: #fff;
  border: none;
  border-radius: 8px;
}

.modal-novo-manifesto .novo-manifesto .btn-selecionar-cte .icone {
  font-size: 20px;
}

.modal-novo-manifesto .novo-manifesto .btn-selecionar-cte:hover {
  filter: brightness(110%);
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto {
  margin-top: 20px;
  overflow: auto;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table thead tr th {
  padding: 10px 3px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  border-right: 1px solid #fff;
  background-color: #3d8af7;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table thead tr th:first-of-type {
  border-top-left-radius: 5px;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table thead tr th:last-of-type {
  border-top-right-radius: 5px;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table tbody tr td {
  padding: 15px;
  font-size: 12px;
  border-right: 1px solid #fff;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table tbody tr td {
  background-color: #d6e8ee;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table tbody tr td.coluna-destaque {
  font-weight: bold;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table tbody tr td.coluna-total {
  text-align: right;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table tbody tr td.coluna-acoes-cte .icone {
  height: 20px;
  color: #ff0000;
  cursor: pointer;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table tbody tr td.coluna-acoes-cte .icone:hover {
  transform: scale(1.05);
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table tbody tr input {
  width: 60px;
  border-radius: 5px;
  border: none;
  padding: 0 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
}

.modal-novo-manifesto .novo-manifesto .container-tabela-ctes-manifesto table tbody tr.linha-totais {
  border-top: 1px solid #fff;
  font-weight: bold;
  color: #001b48;
}

.modal-novo-manifesto .novo-manifesto .input-protocolo-averbacao {
  display: flex;
  align-items: center;
}

.modal-novo-manifesto .novo-manifesto .input-protocolo-averbacao .icone {
  margin-left:  10px;
}

.modal-novo-manifesto .novo-manifesto .input-protocolo-averbacao .icone:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.modal-novo-manifesto .novo-manifesto .container-botoes {
  margin-top: 30px;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.modal-novo-manifesto .novo-manifesto .container-botoes .btn {
  margin: 0 10px;
  padding: 8px 40px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
}

.modal-novo-manifesto .novo-manifesto .container-botoes .btn:hover {
  filter: brightness(120%);
}

.modal-novo-manifesto .novo-manifesto .container-botoes .btn.cancelar {
  background-color: #99b2c6;
}

.modal-novo-manifesto .novo-manifesto .container-botoes .btn.salvar {
  background-color: #018abe;
}

.modal-novo-manifesto .novo-manifesto .container-botoes .btn.enviar {
  background-color: #02457a;
}

.modal-novo-manifesto .novo-manifesto .btn-salvar:hover {
  filter: brightness(120%);
}

.errors-modal-body {
  width: 500px;
}
.errors-modal-part {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 30px;
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}
.errors-modal-part > div:not(:last-child) {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  padding: 8px;
}
.errors-modal-part > div h3 {
  padding-bottom: 8px;
}
.errors-modal-part > div p {
  padding: 4px 0;
}

@media (max-width: 920px) {
  .modal-novo-manifesto .novo-manifesto .container-inputs-4col {
    grid-template-columns: repeat(2, 1fr);
  }

  .modal-novo-manifesto .novo-manifesto .container-formulario-duplo .container-inputs-2col {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 760px) {
  .modal-novo-manifesto .novo-manifesto .container-botoes {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .modal-novo-manifesto .novo-manifesto .container-botoes .btn {
    padding: 8px 15px;
    font-size: 14px;
    width: 180px;
    margin-top: 10px;
  }
}

@media (max-width: 620px) {
  .modal-novo-manifesto .novo-manifesto .container-formulario-duplo {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 570px) {
  .modal-novo-manifesto .novo-manifesto .container-data-hora {
    margin-bottom: 10px;
  }

  .modal-novo-manifesto .novo-manifesto .container-data-hora .container-input-comum:first-of-type {
    width: 100%;
  }

  .modal-novo-manifesto .novo-manifesto .container-inputs-4col {
    grid-template-columns: 1fr;
  }
}