.modal-anexar-dfe {
  .modal-anexar-dfe-radio-container {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 20px;
    padding: 8px 25px;
    border-radius: 8px;
    background-color:#f1f4fa;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
  }

  .coleta-avulsa-form {
    margin-top: 20px;
  }

  .coleta-avulsa-submit-row {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btn-upload {
    position: relative;
    width: 40%;
    margin-top: 20px;

    input[type="file"] {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      padding: 8px 25px;
      width: 100%;
      cursor: pointer;
    }

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 25px;
      background-color: #f1f4fa;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
      cursor: pointer;

      .icone {
        font-size: 20px;
      }
    }
  }

  .nfe-item, .cte-item {
    position: relative;
    margin-top: 20px;
    background-color: #f1f4fa;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px 15px;
    border-radius: 8px;


    .close {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 25px !important;
      color: red !important;
      cursor: pointer;
      
      &:hover {
        filter: brightness(130%);
      }
    }
  }

  .header-block {
    display: flex;
    align-items: center;
    gap: 40px;

    .btn-import-cliente {
      margin-top: 24px;

      border: none;
      background: rgba(0, 0, 0, 0.2);
      color: #222;
      padding: 4px 8px;
      border-radius: 6px;
    }
  }
}
