.editar-coletas {
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #FFF;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 0 0 10px 10px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  z-index: 500;
}
.editar-coletas .fill {
  flex: 1;
}
.editar-coletas .selecionar-endereco {
  margin-top: 16px;

  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 0 16px;
}
.editar-coletas .selecionar-endereco.remove {
  grid-template-columns: 200px 1fr 48px;
}
.editar-coletas .selecionar-endereco.remove button {
  appearance: none;
  border: transparent;
  background: transparent;
  cursor: pointer;
}
.editar-coletas .selecionar-endereco .title {
  grid-column: 1 / -1;
  padding-bottom: 10px;
  color: gray;
}
.editar-coletas .submit-line {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.editar-coletas .submit-line button {
  width: 150px;
  padding: 12px 0;
  background-color: #99b2c6;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.editar-coletas .submit-line button:hover {
  filter: brightness(120%);
}
.editar-coletas .submit-line button.btn-criar {
  background-color: #71a355;
  border-color: #001b48;
  color: #fff;
}