.modal-selecionar-notas .titulo {
  margin-bottom: 40px;
}
.modal-selecionar-notas .selecionar-notas-box {
  height: 300px;
  overflow: auto;
}
.modal-selecionar-notas .selecionar-notas-box table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  text-align: center;
}
.modal-selecionar-notas .selecionar-notas-box table thead tr th {
  padding: 10px 3px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  text-align: center;
  border-right: 1px solid #fff;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}
.modal-selecionar-notas .selecionar-notas-box table thead tr th:first-of-type {
  border-top-left-radius: 5px;
}
.modal-selecionar-notas .selecionar-notas-box table thead tr th:last-of-type {
  border-top-right-radius: 5px;
}
.modal-selecionar-notas .selecionar-notas-box table .coluna-observacoes {
  min-width: 32%;
}
.modal-selecionar-notas .selecionar-notas-box table tbody tr td {
  padding: 15px;
  font-size: 12px;
  border-right: 1px solid #fff;
}
.modal-selecionar-notas .selecionar-notas-box table tbody tr td {
  background-color: #d6e8ee;
}
.modal-selecionar-notas .selecionar-notas-box table tbody tr:nth-child(2n) td {
  background-color: #99b2c6;
}
.modal-selecionar-notas .selecionar-notas-box table tbody tr.linha-selecionada td {
  background-color: #20477e;
}
.modal-selecionar-notas .checked-box,
.modal-selecionar-notas .unchecked-box {
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #9ab2c6;
}
.modal-selecionar-notas .checked-box {
  background: #001b48;
}
.modal-selecionar-notas .submit-line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.modal-selecionar-notas .submit-line button {
  margin: 0 10px;
  padding: 8px 40px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  background-color: #99b2c6;
}
.modal-selecionar-notas .submit-line button:hover {
  filter: brightness(120%);
}
.modal-selecionar-notas .submit-line button.btn-salvar {
  background-color: #018abe;
}


.modal-selecionar-notas .container-inputs-4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2%;
  grid-row-gap: 20px;
  margin-top: 20px;
}
.modal-selecionar-notas .container-inputs-2col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2%;
  grid-row-gap: 20px;
  margin-top: 20px;
}
.modal-selecionar-notas .container-inputs-2col.nopad {
  margin: 0;
}

.modal-selecionar-notas .container-input-comum {
  position: relative;
}

.modal-selecionar-notas .container-input-comum input,
.modal-selecionar-notas .container-input-comum select {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-selecionar-notas .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.modal-selecionar-notas .container-input-comum.cinza input {
  background-color: #9cb6cd;
}

.modal-selecionar-notas .container-input-comum.cinza span {
  color: #fff;
}

.modal-selecionar-notas .container-input-comum.vermelho span {
  color: #ff0000;
}

.modal-selecionar-notas .container-input-comum input:focus + span,
.modal-selecionar-notas .container-input-comum input:disabled + span,
.modal-selecionar-notas .container-input-comum input:valid + span,
.modal-selecionar-notas .container-input-comum select:focus + span,
.modal-selecionar-notas .container-input-comum select:disabled + span,
.modal-selecionar-notas .container-input-comum select:valid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.modal-selecionar-notas .container-input-comum.cinza input:focus + span,
.modal-selecionar-notas .container-input-comum.cinza input:disabled + span,
.modal-selecionar-notas .container-input-comum.cinza input:valid + span {
  background-color: #9cb6cd;
  color: #fff;
}

.modal-selecionar-notas .container-input-comum input:disabled,
.modal-selecionar-notas .container-input-comum select:disabled {
  color: #3d8af7;
}

.modal-selecionar-notas .titulo-formulario {
  font-weight: bold;
  color: #001b48;
  margin-top: 30px;
}

.modal-selecionar-notas .input-radio-gerar-cte {
  display: flex;
  background-color: #fff;
  padding: 8.3px 10px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.modal-selecionar-notas .input-radio-gerar-cte {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-selecionar-notas .input-radio-gerar-cte .container-input-radio {
  position: relative;
}
.modal-selecionar-notas .input-radio-gerar-cte .container-input-radio {
  user-select: none;
}

.modal-selecionar-notas .container-input-radio input[type="radio"] {
  display: none;
}

.modal-selecionar-notas .container-input-radio input[type="radio"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.modal-selecionar-notas .container-input-radio input[type="radio"]:checked + span.checked {
  background: #3d8af7 -19px top no-repeat;
}

.modal-selecionar-notas .container-input-radio input[type="radio"] + span.checked {
  margin-right: 5px;
}


.modal-selecionar-notas .informacoes-emissao .totals {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
}
.modal-selecionar-notas .informacoes-emissao .totals > div {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
}
.modal-selecionar-notas .informacoes-emissao .totals > div > span {
  display: block;
  font-weight: bold;
}


.modal-selecionar-notas .container-embutir-total {
  margin-bottom: 15px;
  margin-top: -15px;
}
.modal-selecionar-notas .container-embutir-total p {
  color: #001b48;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
}
.modal-selecionar-notas .input-radio-gerar-cte.azul {
  background-color: #d6e8ee;
}

@media (max-width: 630px) {
  .modal-selecionar-notas .container-inputs-4col {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 420px) {
  .modal-selecionar-notas .container-inputs-4col {
    grid-template-columns: 1fr;
  }
  .modal-selecionar-notas .container-inputs-2col {
    grid-template-columns: 1fr;
  }
}