.modal-perfil-motorista-carga-imagens .dados-pedido {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.modal-perfil-motorista-carga-imagens .dados-pedido .progresso {
  flex: 0.15;
  height: 60px;
}

.modal-perfil-motorista-carga-imagens .dados-pedido .progresso .CircularProgressbar-text {
  font-size: 30px;
  font-weight: bold;
  fill: #333;
}

.modal-perfil-motorista-carga-imagens .dados-pedido .progresso .CircularProgressbar-path {
  stroke: #008282;
}

.modal-perfil-motorista-carga-imagens .dados-pedido .informacoes div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-perfil-motorista-carga-imagens .dados-pedido .informacoes div .icone {
  font-size: 15px;
  color: #3c89f5;
  margin-right: 5px;
}

.modal-perfil-motorista-carga-imagens .dados-pedido .informacoes div p span,
.modal-perfil-motorista-carga-imagens .dados-pedido .informacoes .pedido span {
  font-weight: bold;
}

.modal-perfil-motorista-carga-imagens .dados-pedido .informacoes .pedido {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

.modal-perfil-motorista-carga-imagens .dados-pedido .informacoes .message-app {
  color: #FF0000;
  margin-top: 15px;
}

.modal-perfil-motorista-carga-imagens .separador {
  margin: 0 auto;
  width: 92%;
  background-color: #ced9e5;
  height: 0.5px;
  border: none;
}

.modal-perfil-motorista-carga-imagens .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
  margin: 20px 0;
  text-align: center;
}

.modal-perfil-motorista-carga-imagens .fotos-carga {
  display: flex;
  justify-content: space-between;
  padding: 20px 4%;
}

.modal-perfil-motorista-carga-imagens .fotos-carga p {
  text-align: center;
  font-weight: bold;
  color: #001b48;
  margin-bottom: 10px;
}

.modal-perfil-motorista-carga-imagens .fotos-carga .caminhao-carregado,
.modal-perfil-motorista-carga-imagens .fotos-carga .descarga,
.modal-perfil-motorista-carga-imagens .fotos-carga .canhoto {
  flex: 1;
  overflow-y: scroll;
  max-height: 250px;
}

.modal-perfil-motorista-carga-imagens .fotos-carga .fotos {
  display: flex;
  flex-wrap: wrap;
}

.modal-perfil-motorista-carga-imagens .fotos-carga .fotos .container-img {
  position: relative;
}

.modal-perfil-motorista-carga-imagens .fotos-carga .fotos .container-img .icone-remover {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 20px;
  color: red;
  cursor: pointer;
}

.modal-perfil-motorista-carga-imagens .fotos-carga .fotos .container-img .icone-remover:hover {
  transform: scale(1.1);
}

.modal-perfil-motorista-carga-imagens .fotos-carga .fotos .container-img img {
  height: 80px;
  width: 90px;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
}

.container-visualizar-foto-carga {
  width: 90%;
  height: 90%;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  animation-duration: 0.2s;
}

.container-visualizar-foto-carga img {
  max-height: 90vh;
  width: 100%;
}

.container-visualizar-foto-carga .container-btn-visualizar-foto-carga {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.container-visualizar-foto-carga .container-btn-visualizar-foto-carga button {
  width: 130px;
  padding: 5px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-weight: bold;
}

.container-visualizar-foto-carga .container-btn-visualizar-foto-carga button:hover {
  filter: brightness(120%);
}


.modal-perfil-motorista-carga-imagens .btn-upload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.modal-perfil-motorista-carga-imagens .btn-upload input[type="file"] {
  position: absolute;
  top: 50%;
  left: 57%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.modal-perfil-motorista-carga-imagens .btn-upload label {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background-color: #fff;
  color: #3c89f5;
  border: 1px solid #3c89f5;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
  cursor: pointer;
}

.modal-perfil-motorista-carga-imagens .btn-upload label .icone {
  font-size: 20px;
  color: #3c89f5;
  margin-right: 5px;
}

.modal-perfil-motorista-carga-imagens .btn-upload p {
  margin-bottom: 0;
}

.alert-remover-imagem  {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-remover-imagem  .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-remover-imagem button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-remover-imagem button:hover {
  filter: brightness(120%);
}

.alert-remover-imagem button.botao-confirmar {
  background-color: #cc3300;
}

.alert-remover-imagem button.botao-cancelar {
  background-color: #001b48;
}

@media (max-width: 980px) {
  .modal-perfil-motorista-carga-imagens .fotos-carga {
    padding: 10px;
  }
}

@media (max-width: 750px) {
  .modal-perfil-motorista-carga-imagens .fotos-carga {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .modal-perfil-motorista-carga-imagens .fotos-carga .descarga,
  .modal-perfil-motorista-carga-imagens .fotos-carga .canhoto {
    margin-top: 20px;
  }
}
