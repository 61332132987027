.form-input-container {
  .form-input {
    position: relative;
    
    &:not(.no-label) {
      margin-top: 20px;
      margin-bottom: 5px;
    }

    input, select {
      width: 100%;
      background-color: #f1f4fa;
      padding: 12px 10px;
      border: 1px solid #9ab2c6;
      border-radius: 8px;

      &:focus + span,
      &:disabled + span,
      &:valid + span {
        top: -8px;
        left: 10px;
        font-size: 12px;
        color: #9ab2c6;
        background-color: #fff;
      }
    }

    select {
      padding: 11.6px 10px;
    }
    
    span {
      position: absolute;
      top: 14px;
      left: 15px;
      letter-spacing: 0.05em;
      pointer-events: none;
      transition: 0.4s;
      font-size: 13px;
      color: gray;

      i {
        color: red;
        font-style: normal;
      }
    }

    &.small {
      input, select {
        width: 100%;
        padding: 6px 10px;
        border-radius: 3px;
      }  
    }

    &.white {
      background: #fff;

      input, select {
        background: #fff;
        color: #3d8af7;
      }
      span {
        background: #fff;
        color: #9ab2c6;
      }
    }

    &.borderless {
      input, select {
        border: none;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }

    &.disabled:not(.white) {
      opacity: 0.6;
    }
  }

  .form-input-error {
    color: red;
    font-size: 12px;
    opacity: 0.6;
  }

  .form-input-helper {
    font-size: 12px;
    color: #000;
    opacity: 0.6;
  }
}
