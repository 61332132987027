.tabela-fretes-pagar .btn-frete {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #3c89f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 5px 5px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} 

.tabela-fretes-pagar .btn-frete .icone {
  margin-left: 10px;
  font-weight: bold;
}

.tabela-fretes-pagar .btn-frete:hover {
  filter: brightness(110%);
}

.container-tabela-fretes-pagar {
  margin-top: 5px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-tabela-fretes-pagar table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  text-align: center;
}

.container-tabela-fretes-pagar table thead tr th {
  padding: 10px 3px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  text-align: center;
  border-right: 1px solid #fff;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.container-tabela-fretes-pagar table thead tr th:first-of-type {
  border-top-left-radius: 5px;
}

.container-tabela-fretes-pagar table thead tr th:last-of-type {
  border-top-right-radius: 5px;
}

.container-tabela-fretes-pagar table .coluna-observacoes {
  min-width: 32%;
}

.container-tabela-fretes-pagar table tbody tr td {
  padding: 15px;
  font-size: 12px;
  border-right: 1px solid #fff;
}

.container-tabela-fretes-pagar table tbody tr.linha td {
  background-color: #d6e8ee;
}

.container-tabela-fretes-pagar table tbody tr.linha.impar td {
  background-color: #99b2c6;
}

.container-tabela-fretes-pagar table tbody tr.linha-total td {
  background-color: #018abe;
  border-right: 1px solid #018abe;
  color: #fff;
}

.container-tabela-fretes-pagar table .coluna-acoes {
  padding: 0px;
}

.container-tabela-fretes-pagar table .coluna-acoes .icone {
  font-size: 20px;
  color: #fff;
}

.container-tabela-fretes-pagar table .coluna-acoes .icone:first-of-type {
  margin-right: 2px;
}

.container-tabela-fretes-pagar table .coluna-acoes .icone {
  background-color: #3c89f5;
  border-radius: 50%;
  padding: 2px;
}

.container-tabela-fretes-pagar table .coluna-acoes .icone.lixo {
  background-color: red;
}

.container-tabela-fretes-pagar table .coluna-acoes .icone:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.container-tabela-fretes-pagar table tbody tr.linha-total td {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.container-tabela-fretes-pagar table tbody tr.linha-total td.coluna-total {
  border-top: none;
  border-bottom: none;
}

.container-tabela-fretes-pagar table tbody tr.linha-total td:first-of-type {
  border-bottom-left-radius: 5px;
}

.container-tabela-fretes-pagar table tbody tr.linha-total td:last-of-type {
  border-bottom-right-radius: 5px;
}

.alert-remover-frete {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-remover-frete .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-remover-frete .react-confirm-alert-body {
  color: #1c2732;
}

.alert-remover-frete button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-remover-frete button:hover {
  filter: brightness(120%);
}

.alert-remover-frete button.botao-confirmar {
  background-color: #cc3300;
}

.alert-remover-frete button.botao-cancelar {
  background-color: #001b48;
}