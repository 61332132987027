:root{
  --toastify-color-progress-light: #3d8af7;
}

.notificacao-fechamento-carga p {
  margin-bottom: 5px;
}

.notificacao-fechamento-carga span {
  font-weight: bold;
}

.notificacao-fechamento-carga .pergunta {
  margin-top: 10px;
  font-weight: bold;
}

.notificacao-fechamento-carga .container-input {
  position: relative;
  margin-top: 15px;
}

.notificacao-fechamento-carga .container-input input  {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.notificacao-fechamento-carga .container-input span {
  position: absolute;
  top: -8px;
  left: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.notificacao-fechamento-carga .botoes-alterar-data {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.notificacao-fechamento-carga .botoes-alterar-data button {
  border-radius: 5px;
  border: none;
  margin: 0 10px;
  padding: 3px 10px;
  font-weight: bold;
  color: #fff;
}

.notificacao-fechamento-carga .botoes-alterar-data button:hover {
  filter: brightness(110%);
}

.notificacao-fechamento-carga .botoes-alterar-data button.salvar {
  background-color: #92d36e;
}

.notificacao-fechamento-carga .botoes-alterar-data button.cancelar {
  background-color: #ef2d41;
}