.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .titulo {
  background-color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards::-webkit-scrollbar-thumb {
  background: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .cards::-webkit-scrollbar-thumb:hover {
  background: #92d36e9c;
  border-radius: 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div {
  text-align: center;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes p:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.total {
  flex: 0.5;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.total,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.prazo,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.atrasado,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.ocorrencia {
  border-right: 1px solid #99b2c6;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.total p span {
  color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.prazo p span {
  color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.atrasado p span {
  color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.ocorrencia p span {
  color: #c58b00;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.entregue .detalhes div.icones {
  flex: 0.5;
}