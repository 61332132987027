.section-title {
  font-size: 22px;
  font-weight: bold;
  color: #9ab2c6;
  margin-bottom: 8px;
  margin-top: 32px;

  &.small {
    font-size: 16px;
  }
}
