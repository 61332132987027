.container-informacoes {
  padding: 20px;
}

.container-informacoes .categoria .titulo-categoria {
  font-weight: bold;
  color: #172b4d;
}

.container-informacoes .categoria .container-dados {
  padding: 10px 0 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ced9e5;
}

.container-informacoes .categoria .container-dados.veiculo {
  padding: 0 0 20px;
}

.container-informacoes .categoria .container-dados.veiculo .titulo-dados-veiculo {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.container-informacoes .categoria .container-dados.veiculo .titulo-dados-veiculo p {
  font-weight: bold;
  color: #172b4d;
  margin-right: 3%;
  white-space: nowrap;
}

.container-informacoes .categoria .container-dados.veiculo .titulo-dados-veiculo hr {
  width: 100%;
  border: none;
  height: 1px;
  background-color: #ced9e5;
}

.container-informacoes .categoria .container-dados.veiculo .col-4 {
  margin-top: 15px;
}

.container-informacoes .categoria .container-dados label,
.container-informacoes .categoria .container-dados .container-input {
  font-size: 12px;
  color: gray;
}
.container-informacoes .categoria .container-dados .container-input {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.container-informacoes .categoria .container-dados .container-input input,
.container-informacoes .categoria .container-dados .container-input select,
.container-informacoes .categoria .container-dados label input,
.container-informacoes .categoria .container-dados label select {
  width: 100%;
  margin-top: 2px;
  background-color: #f9fbfd;
  border: none;
  padding: 10px 5px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
}

.container-informacoes .categoria .container-dados .col-3 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: 4%;
  grid-row-gap: 15px;
}

.container-informacoes .categoria .container-dados .col-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 2%;
  margin-top: 15px;
}

.container-informacoes .categoria .container-dados .col-1 {
  margin-top: 15px;
}

.container-informacoes .categoria .container-dados .col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4%;
  grid-row-gap: 15px;
}

.container-informacoes .categoria .container-dados .col-3-link {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-column-gap: 4%;
  margin-top: 15px;
  padding-right: 4%;
}

.container-informacoes .categoria .container-dados .veiculo-ver-mais {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-weight: bold;
  text-decoration: underline;
  color: #172b4d;
  cursor: pointer;
  margin-top: 10px;
}

.container-informacoes .categoria .container-dados .col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4%;
  margin-top: 15px;
}

.container-informacoes .categoria .container-documentos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.container-informacoes .categoria .container-documentos .documento {
  height: 90px;
  width: 90px;
  margin-right: 2%;
  cursor: pointer;
}

.container-informacoes .categoria .container-documentos .documento:last-of-type {
  margin-right: 0;
}

.container-informacoes .categoria .container-documentos .documento img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.container-informacoes .categoria .container-dados .veiculo-ver-menos {
  text-align: right;
  font-weight: bold;
  text-decoration: underline;
  color: #172b4d;
  cursor: pointer;
}

.overlay-modal-visualizar-fotos {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10002;
}

.modal-visualizar-fotos {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  background-color: rgba(18, 19, 20, 0.400);
  overflow: auto;
  z-index: 99;
}

.modal-visualizar-fotos .conteudo .topo {
  display: flex;
  justify-content: flex-end;
}

.modal-visualizar-fotos .conteudo .topo .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #31435c;
  cursor: pointer;
}

.modal-visualizar-fotos .conteudo .topo .icone-fechar:hover {
  filter: brightness(120%);
}

.modal-visualizar-fotos .conteudo img {
  max-width: 100%;
  max-height: 100%;
}

.guep-editable {
  border: 1px dashed #222 !important;
}

.submit-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.submit-row.bottom {
  margin-top: 20px;
}

.submit-row button {
  margin: 0 10px;
  padding: 8px 40px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  background-color: #018abe;
}
.submit-row button:hover {
  filter: brightness(120%);
}

.gr-banner {
  margin-bottom: 20px;
  border: 2px solid #f9a825;
  background: #fff9c4;
  padding: 20px;
  font-size: 16px;
  color: #222;
}
.actions-banner {
  margin-bottom: 30px;
  border: 2px solid #20477e;
  background-color: #bbdefb;
  padding: 20px;
  font-size: 16px;
  color: #222;
}
.actions-banner .banner-title {
  font-weight: bold;
  font-size: 16px;
  color: #222;
  margin-bottom: 15px;
}
.actions-banner .errors-section {
  margin-bottom: 15px;
}
.actions-banner .errors-section ul {
  list-style-type: square;
  list-style-position: inside;
  margin-top: 10px;
}
.actions-banner .resend-button-row {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actions-banner .resend-button-row button {
  margin: 0 10px;
  padding: 8px 40px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  background-color: #018abe;
}

.actions-banner .resend-button-row button:hover {
  filter: brightness(120%);
}

.modal-input-tipo .container-input-search-veiculo-carroceria {
  margin-top: 0;
}
.modal-input-tipo .input-search-veiculo-carroceria {
  padding: 0 !important;
  background-color: transparent !important;
  border-color: none !important;
}
.modal-input-tipo .input-search-veiculo-carroceria input {
  background: transparent !important;
  box-shadow: none !important;
}
.modal-input-tipo.guep-editable {
  border: none !important;
}
.modal-input-tipo.guep-editable .input-search-veiculo-carroceria {
  border: 1px dashed #222 !important;
}
.status-checkbox {
  margin-top: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.status-checkbox input[type="checkbox"] {
  display: none;
}

.status-checkbox input[type="checkbox"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.status-checkbox input[type="checkbox"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.status-checkbox input[type="checkbox"] + span.checked {
  margin-right: 10px;
}


@media (max-width: 980px) {
  .container-informacoes {
    padding: 20px 10px;
  }
}

@media (max-width: 890px) {
  .container-informacoes .categoria .container-dados .col-5 {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 15px;
  }

  .container-informacoes .categoria .container-dados .col-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2%;
    grid-row-gap: 15px;
  }

  .container-informacoes .categoria .container-dados .col-3 {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 2%;
    grid-row-gap: 15px;
  }

  .container-informacoes .categoria .container-dados .col-3-link {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2%;
    grid-row-gap: 15px;
    padding-right: 0;
  }

  .container-informacoes .categoria .container-dados .col-3-link .veiculo-ver-mais {
    display: flex;
    justify-content: flex-start;
  }
}

@media (max-width: 700px) {

  .container-informacoes .categoria .container-dados .col-5,
  .container-informacoes .categoria .container-dados .col-4,
  .container-informacoes .categoria .container-dados .col-3,
  .container-informacoes .categoria .container-dados .col-3-link {
    grid-template-columns: repeat(1, 1fr);
  }
}
