.cards-motoristas {
  padding: 5px 8px 0 8px;
}

.cards-motoristas .card {
  display: flex;
  margin-bottom: 5px;
  border: 1px solid #99b2c6;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 8px;
}

.cards-motoristas .card .tag-lateral {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  border-radius: 9px 0 0 9px;
  background-color: #99b2c6;
  overflow: hidden;
}

.cards-motoristas .card .tag-lateral.recorrente {
  background-color: #85bf64;
}

.cards-motoristas .card .tag-lateral.agregado {
  background-color: #c58b00;
}

.cards-motoristas .card .tag-lateral.novo {
  background-color: #fec63d;
}

.cards-motoristas .card .tag-lateral .container-favorito {
  position: relative;
}

.cards-motoristas .card .tag-lateral .container-favorito .background {
  position: absolute;
  top: 5px;
  left: 7px;
  background-color: #ffd700;
  height: 20px;
  width: 20px;
  z-index: 1;
}

.cards-motoristas .card .tag-lateral .icone-estrela {
  position: relative;
  font-size: 35px;
  cursor: pointer;
  color: #000;
  z-index: 2;
}

.cards-motoristas .card .tag-lateral .icone-estrela:hover {
  color: #000000;
}

.cards-motoristas .card .tag-lateral .posicao {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background: linear-gradient(transparent, 50%, #00000071);
  background-color: transparent;
}

.cards-motoristas .card .tag-lateral .posicao p {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

.cards-motoristas .card .tag-lateral .tipo-motorista {
  width: 70px;
  text-align: center;
  margin-bottom: 20px;
  padding: 3px 0;
  transform: rotate(-10deg);
  background: linear-gradient(transparent, 50%, #00000071);
  background-color: transparent;
  text-transform: uppercase;
}

.cards-motoristas .card .tag-lateral .tipo-motorista p {
  font-size: 9px;
  font-weight: bold;
  color: #fff;
}

.cards-motoristas .card .conteudo {
  flex: 1;
  padding: 3px;
}

.cards-motoristas .card .conteudo .linha-perfil {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cards-motoristas .card .conteudo .linha-perfil img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 8px;
}

.cards-motoristas .card .conteudo .linha-perfil p {
  font-weight: bold;
  margin: 0 5px;
}

.cards-motoristas .card .conteudo .linha-perfil .icone {
  font-size: 20px;
}

.cards-motoristas .card .conteudo .linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  font-size: 12px;
}

.cards-motoristas .card .conteudo .linha p span {
  font-weight: bold;
}

.cards-motoristas .card .conteudo .linha .telefone {
  display: flex;
  align-items: center;
}

.cards-motoristas .card .conteudo .linha .telefone .icone {
  font-size: 16px;
  margin-right: 3px;
  color: #3d8af7;
}

.cards-motoristas .card .conteudo .linha .placas {
  display: flex;
  align-items: center;
}

.cards-motoristas .card .conteudo .linha .placas .placa {
  padding: 3px;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 8px;
}

.cards-motoristas .card .conteudo .linha .placas .placa:first-of-type {
  margin-right: 5px;
}

.cards-motoristas .card .conteudo .linha .placas .placa p {
  font-size: 12px;
}

.cards-motoristas .card .conteudo .linha .container-dado {
  display: flex;
  align-items: center;
}

.cards-motoristas .card .conteudo .linha .container-dado .distancia {
  font-size: 10px;
  margin-bottom: -3px;
}

.cards-motoristas .card .conteudo .linha .container-dado.tag span {
  display: flex;
  align-items: center;
  cursor: help;
}

.cards-motoristas .card .conteudo .linha .container-dado.tag [tag-dado] {
  position: relative;
}

.cards-motoristas .card .conteudo .linha .container-dado.tag [tag-dado]:after {
  display: none;
  position: absolute;
  bottom: 20px;
  padding: 3px;
  border-radius: 3px;
  left: calc(-80%);
  content: attr(tag-dado);
  white-space: nowrap;
  background-color: #3d8af7;
  color: White;
}

.cards-motoristas .card .conteudo .linha .container-dado.tag .tag-esquerda[tag-dado]:after {
  left: calc(-300% + 2px);
}

.cards-motoristas .card .conteudo .linha .container-dado.tag [tag-dado]:hover:after {
  display: block;
  z-index: 5;
}

.cards-motoristas .card .conteudo .linha .container-dado .icone {
  font-size: 18px;
  color: #3d8af7;
  margin-right: 3px;
}

.cards-motoristas .card .conteudo .linha .container-dado .icone.estrela {
  color: #ffbf00;
  font-size: 16px;
}

.cards-motoristas .card .conteudo .linha.valores {
  border-top: 0.5px solid #99b2c6;
  padding-top: 5px;
}

.cards-motoristas .card .conteudo .linha .container-dado .icone.dinheiro {
  color: green;
}

.cards-motoristas .card .conteudo .linha .container-dado .icone.diferenca {
  color: #c58b00;
}

.cards-motoristas .card .conteudo .linha .container-dado .real {
  margin-left: 5px;
  font-weight: normal;
}

.cards-motoristas .card .conteudo .linha .data {
  display: flex;
  align-items: center;
}

.cards-motoristas .card .conteudo .linha .data .icone {
  margin-right: 3px;
  color: #3d8af7;
}

.cards-motoristas .card .conteudo .linha .data p {
  font-size: 10px;
}

.cards-motoristas .card .conteudo .linha .botoes  {
  display: flex;
}

.cards-motoristas .card .conteudo .linha .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  border: none;
}

.cards-motoristas .card .conteudo .linha .botoes button:hover {
  filter: brightness(120%)
}

.cards-motoristas .card .conteudo .linha .botoes button .icone {
  font-size: 18px;
  color: #fff;
}

.cards-motoristas .card .conteudo .linha .botoes button.recusar {
  background-color: red;
  border-radius: 5px 0 0 5px;
}

.cards-motoristas .card .conteudo .linha .botoes button.aceitar {
  background-color: #85bf64;
  border-radius: 0 5px 5px 0;
}

.react-confirm-alert-overlay {
  z-index: 10001 !important;
}

.container-alert-proposta {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  border: 2px solid #3eeef7a8;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  animation-duration: 0.1s;
}

.container-alert-proposta .titulo {
  font-weight: bold;
  margin-bottom: 20px;
}

.container-alert-proposta .titulo span.reprovar {
  color: red;
}

.container-alert-proposta .titulo span.aprovar {
  color: #85bf64;
}

.container-alert-proposta .motorista {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.container-alert-proposta .motorista img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 8px;
}

.container-alert-proposta .motorista p {
  font-weight: bold;
  margin: 0 5px;
}

.container-alert-proposta  .container-input-checkbox-consulta {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.container-alert-proposta  .container-input-checkbox-consulta input {
  margin-right: 5px;
  cursor: pointer;
  -ms-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
  padding: 10px;
}

.container-conferir-documentos p span {
  font-weight: bold;
  color: #3d8af7;
}

.container-conferir-documentos p span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.container-alert-proposta .botoes {
  display: flex;
  justify-content: center;
}

.container-alert-proposta .botoes button {
  border: none;
  width: 50px;
  padding: 5px 0;
  font-weight: bold;
  color: #fff;
}

.container-alert-proposta .botoes button:hover {
  filter: brightness(120%);
}

.container-alert-proposta .botoes button.cancelar {
  background-color: red;
  border-radius: 3px 0 0 3px;
}

.container-alert-proposta .botoes button.confirmar {
  background-color: #85bf64;
  border-radius: 0 3px 3px 0;
}