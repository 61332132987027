.tabelas-grid {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  .empty {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .tabela-item {
    display: flex;
    align-items: stretch;
    padding: 5px;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    .data {
      flex: 1;

      .nomeCliente {
        font-weight: bold;
        font-size: 16px;
      }

      .informations {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        opacity: 0.6;
      }
    }

    .action {
      display: flex;
      align-items: center;
    }
  }
}
