.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .subtitle {
    margin-top: 5px;
    font-weight: bold;
    color: #001b48;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}
