
.quadro-quatidades {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f4fa;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.quadro-quatidades p {
  font-size: 16px;
  font-weight: bold;
  color: #001b48
}

.quadro-quatidades .input-quantidade {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
}

.quadro-quatidades .input-quantidade input {
  width: 80%;
  margin: 0 3px 0 10px;
  background-color: #fff;
  border: none;
}

.quadro-quatidades .input-quantidade select {
  flex: 1;
  background-color: #fff;
  border-color: #fff;
  color: gray;
}

.quadro-quatidades .input-quantidade input::placeholder {
  font-size: 12px;
}

.quadro-quatidades .input-quantidade .icone-limpar {
  font-size: 18px;
  color: #f1f4fa;
  background-color: #ced9e5;
  border-radius: 50%;
  cursor: pointer;
}

.quadro-quatidades .input-quantidade .icone-limpar:hover {
  filter: brightness(105%);
}
