.formulario-nfe {
  position: relative;
  margin-top: 20px;
  background-color: #f1f4fa;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 15px;
  border-radius: 8px;
}

.formulario-nfe .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 25px !important;
  color: red !important;
  cursor: pointer;
}

.formulario-nfe .icone-fechar:hover {
  filter: brightness(130%);
}

.formulario-nfe .container-inputs-5col {
  display: grid;
  grid-template-columns: 1fr 1fr 0.7fr 0.7fr 0.7fr;
  grid-column-gap: 1.5%;
  margin-top: 20px;
}

.formulario-nfe .container-inputs-4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2%;
  margin-top: 20px;
}

.formulario-nfe .container-inputs-3col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  margin-top: 20px;
}

.formulario-nfe .container-inputs-2col {
  display: grid;
  grid-template-columns: 0.95fr 2.05fr;
  grid-column-gap: 5%;
  margin-top: 20px;
}

.formulario-nfe .input-radio-cif-fob {
  width: 50%;
  display: flex;
}

.formulario-nfe .input-radio-cif-fob {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formulario-nfe .input-radio-cif-fob .container-input-radio {
  position: relative;
}

.formulario-nfe .input-radio-cif-fob .container-input-radio {
  user-select: none;
}

.formulario-nfe .input-radio-cif-fob .container-input-radio .radio {
  cursor: pointer;
}

.formulario-nfe .input-radio-cif-fob .container-input-radio .radio input[type="radio"] {
  display: none;
}

.formulario-nfe .input-radio-cif-fob .container-input-radio .radio input[type="radio"]+span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.formulario-nfe .input-radio-cif-fob .container-input-radio .radio input[type="radio"]:checked+span.checked {
  background: #001b48 -19px top no-repeat;
}

.formulario-nfe .input-radio-cif-fob .container-input-radio .radio input[type="radio"]+span.checked {
  margin-right: 5px;
}

.formulario-nfe .container-input-comum {
  position: relative;
}

.formulario-nfe .container-input-comum input,
.formulario-nfe .container-input-comum select {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.formulario-nfe .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.formulario-nfe .container-input-comum input:focus+span,
.formulario-nfe .container-input-comum input:disabled+span,
.formulario-nfe .container-input-comum input:valid+span,
.formulario-nfe .container-input-comum select:focus+span,
.formulario-nfe .container-input-comum select:disabled+span,
.formulario-nfe .container-input-comum select:valid+span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.formulario-nfe .container-input-comum input:disabled,
.formulario-nfe .container-input-comum select:disabled {
  color: #3d8af7;
}

.formulario-nfe .container-input-select {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.formulario-nfe .container-input-select select {
  background-color: #d6e8ee;
  padding: 9px 5px 9px 10px;
  border: none;
  border-radius: 8px;
  color: #8094aa;
}

.formulario-nfe .container-input-select input {
  width: 100%;
  background-color: #fff;
  padding: 10px 5px;
  border: none;
  border-radius: 0 8px 8px 0;
}

.formulario-nfe .container-input-select.disabled select {
  background-color: #fff;
  color: #3d8af7;
}

.formulario-nfe .container-input-select.disabled input {
  color: #3d8af7;
}

.formulario-nfe .btn-salvar {
  display: block;
  margin: 30px auto 0 auto;
  padding: 8px 40px;
  background-color: #02457a;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
}

.formulario-nfe .btn-salvar:hover {
  filter: brightness(120%);
}

.alert-sucesso-nfe {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-sucesso-nfe .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-sucesso-nfe .react-confirm-alert-body {
  color: #1c2732;
}

.alert-sucesso-nfe button {
  font-size: 14px;
  font-weight: bold;
  background-color: #02457a;
  color: #fff;
}

@media (max-width: 720px) {
  .formulario-nfe .container-inputs-5col {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 20px;
  }

  .formulario-nfe .container-inputs-4col {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
  }

  .formulario-nfe .container-inputs-2col {
    grid-template-columns: 1fr 1fr;
  }

  .formulario-nfe .container-inputs-3col {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
  }
}

@media (max-width: 420px) {
  .formulario-nfe .container-inputs-5col {
    grid-template-columns: 1fr 1fr;
  }

  .formulario-nfe .container-inputs-4col {
    grid-template-columns: repeat(2, 1fr);
  }

  .formulario-nfe .container-inputs-3col,
  .formulario-nfe .container-inputs-2col {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
}

@media (max-width: 380px) {
  .formulario-nfe .container-inputs-4col {
    grid-template-columns: 1fr;
  }
}