.container-cotinue-cte {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-cotinue-cte .btn-continue-cte {
    margin: 0;
    padding: 8px 40px;
    font-size: 16px;
    color: #fff;
    background-color: #02457a;
    border: none;
    border-radius: 8px;
    font-weight: bold;
}
