.overlay-modal-novo-frete-pagar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10001;
}

.modal-novo-frete-pagar  {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #f7fafc;
  border-radius: 5px;
  overflow: auto;
}

.modal-novo-frete-pagar .container-novo-frete {
  padding: 15px;
}

.modal-novo-frete-pagar .container-novo-frete .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #3a4959;
  cursor: pointer;
}

.modal-novo-frete-pagar .container-novo-frete .icone-fechar:hover {
  filter: brightness(130%);
}

.modal-novo-frete-pagar .container-novo-frete .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs {
  margin: 20px 0;
  background-color: #f1f4fa;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
  border-radius: 5px;
  padding: 30px 15px 20px 15px;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-inputs-4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2%;
  margin-top: 15px;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-inputs-1col {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum {
  position: relative;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum input {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum select {
  width: 100%;
  background-color: #d6e8ee;
  color: #8094aa;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum input:focus + span,
.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum input:disabled + span,
.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum input:valid + span,
.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum select:focus + span,
.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum select:disabled + span,
.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum select:valid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .container-input-comum input[type="date"] {
  height: 36px;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .btn-registrar {
  display: block;
  margin: 30px auto 0 auto;
  padding: 8px 20px;
  background-color: #02457a;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
}

.modal-novo-frete-pagar .container-novo-frete .container-inputs .btn-registrar:hover {
  filter: brightness(120%);
}

@media (max-width: 980px) {
  .modal-novo-frete-pagar {
    width: 98%;
  }
  .modal-novo-frete-pagar .container-novo-frete {
    margin-right: 5px;
  }
}

@media (max-width: 660px) {
  .modal-novo-frete-pagar .container-novo-frete .container-inputs .container-inputs-3col {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 15px;
  }
}

@media (max-width: 400px) {
  .modal-novo-frete-pagar .container-novo-frete .container-inputs .container-inputs-3col {
    grid-template-columns:  1fr;
  }
}