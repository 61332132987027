.container-historico {
  padding: 20px;
}

.container-historico .titulo {
  font-weight: bold;
  color: #172b4d;
}

.container-historico .container-quadros {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0;
}

.container-historico .container-quadros .quadro {
  border: 1px solid #99b2c6;
  box-shadow: rgba(0, 0, 0, 0.185) 0px 1px 8px;
  width: 150px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
}

.container-historico .container-quadros .quadro p:first-of-type {
  color: #3d8af7;
}

.container-historico .container-quadros .quadro:not(.quadro.total) p:last-of-type {
  padding: 8px 0;
}

.container-historico .container-quadros .quadro:not(.quadro:last-of-type) {
  margin-right: 2%;
}

.container-historico .container-quadros .quadro:not(.quadro.total) p:first-of-type {
  border-bottom: 1px solid #99b2c6;
}

.container-historico .container-quadros .quadro.total div {
  display: flex;
  justify-content: space-between;
  border-bottom:  1px solid #99b2c6;
}

.container-historico .container-quadros .quadro.total div p {
  flex: 1;
}

.container-historico .container-quadros .quadro.total div p:first-of-type {
  border-right: 1px solid #99b2c6;
}

.container-historico .container-quadros .quadro.total p.cargas {
  padding: 8px 0;
}

.container-historico .container-quadros .quadro.total p.cargas span {
  color: #3d8af7;
}

.container-historico .container-tabela-historico {
  margin-top: 20px;
  max-width: 100%;
  overflow: auto;
}

.container-historico .container-tabela-historico table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.container-historico .container-tabela-historico table thead tr th {
  padding: 10px 3px;
  color: gray;
  background-color: #f1f3f9;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  border-right: 1px solid #fff;
}

.container-historico .container-tabela-historico table thead tr th div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.container-historico .container-tabela-historico table thead tr th .icone-ordenacao {
  margin-left: 2px;
}

.container-historico .container-tabela-historico table tbody tr td {
  padding: 15px;
  font-size: 12px;
  border-right: 1px solid #fff;
}

.container-historico .container-tabela-historico table tbody tr.linha-impar td {
  background-color: #77adf9;
}

.container-historico .container-tabela-historico table p.expandir-linha {
  cursor: pointer;
  text-decoration: underline;
}

.container-historico .container-paginacao-historico {
  margin: 20px;
}

.container-historico .container-paginacao-historico .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.container-historico .container-paginacao-historico .pagination>.active>a {
  background-color: #3eeef7a8;
  color: black;
}

.container-historico .container-paginacao-historico .pagination>li>a {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

.container-historico .container-paginacao-historico .pagination>.active>a,
.container-historico .container-paginacao-historico .pagination>.active>span,
.container-historico .container-paginacao-historico .pagination>.active>a:hover,
.container-historico .container-paginacao-historico .pagination>.active>span:hover,
.container-historico .container-paginacao-historico .pagination>.active>a:focus,
.container-historico .container-paginacao-historico .pagination>.active>span:focus {
  color: #fff;
  border-color: #fff;
  outline: none;
}

.container-historico .container-paginacao-historico .pagination>li>a,
.container-historico .container-paginacao-historico .pagination>li>span {
  border: 1px solid #99b2c6;
  color: #99b2c6;
}

.container-historico .container-paginacao-historico .pagination>li>a:hover {
  filter: brightness(95%);
}

.container-historico .container-paginacao-historico .pagination>li:first-child>a,
.container-historico .container-paginacao-historico .pagination>li:first-child>span,
.container-historico .container-paginacao-historico .pagination>li:last-child>a,
.container-historico .container-paginacao-historico .pagination>li:last-child>span {
  border-radius: 50%;
}

@media (max-width: 980px) {
  .container-historico {
    padding: 20px 10px;
  }
  .container-historico .container-paginacao-historico {
    padding-bottom: 10px;
  }
}