.card-agendamento {
  position: relative;
  margin: 8px 5px;
  background-color: #f1f4fa;
  border: 1px solid #99b2c6;
  border-radius: 10px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card-agendamento .linha {
  display: flex;
  justify-content: space-between;
}

.card-agendamento .linha:not(.card .linha:last-of-type) {
  margin-bottom: 6px;
}

.card-agendamento .linha .id {
  font-size: 10px;
}

.card-agendamento .linha .id span {
  font-weight: bold;
}

.card-agendamento .linha .pedido {
  text-align: center;
  font-size: 12px;
}

.card-agendamento .linha .pedido span {
  font-weight: bold;
}

.card-agendamento .linha .pedido .id {
  font-size: 10px;
}

.card-agendamento .linha .transportadora {
  display: flex;
  align-items: flex-start;
  font-size: 10px;
  width: 95px;
}

.card-agendamento .linha .transportadora .icone {
  margin: 2px 1px 0 0;
  color: #3c89f5;
}

.card-agendamento .linha .embarque,
.card-agendamento .linha .entrega {
  display: flex;
  font-size: 12px;
}

.card-agendamento .linha .embarque .icone,
.card-agendamento .linha .entrega .icone {
  color: #3c89f5;
  margin-right: 2px;
}

.card-agendamento .linha .embarque p span,
.card-agendamento .linha .entrega p span {
  font-weight: bold;
}

.card-agendamento .linha .cidade {
  flex: 1;
  font-size: 12px;
  text-align: start;
}

.card-agendamento .linha .caminhao,
.card-agendamento .linha .carga {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 12px;
}
.card-agendamento .linha .carga {
  flex: 0.4;
}

.card-agendamento .linha .caminhao .icone,
.card-agendamento .linha .carga .icone {
  color: #3c89f5;
  margin: 3px;
}

.card-agendamento .linha .dados-motorista {
  display: flex;
  align-items: center;
}

.card-agendamento .linha .dados-motorista .icone {
  font-size: 18px;
  border-radius: 50%;
  color: #3c89f5;
  margin-right: 5px;
}

.card-agendamento .linha .dados-motorista p {
  font-size: 10px;
}

.card-agendamento .linha .dados-motorista .nome {
  font-size: 12px;
  font-weight: bold;
}