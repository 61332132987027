.container-perfil-transportadora {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-perfil-transportadora .perfil-transportadora {
  display: flex;
  min-height: 70vh;
  margin: 20px 4% 30px 4%;
}

.container-perfil-transportadora .perfil-transportadora .container-foto {
  width: 320px;
  margin-right: 30px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 1px 2px 8px 0px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .quadro-topo {
  padding-top: 35px;
  height: 180px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
  border-radius: 10px 10px 0 0;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .quadro-topo p {
  text-align: center;
  font-size: 22px;
  color: #fff;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .foto-id {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -75px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .foto-id img {
  height: 130px;
  width: 130px;
  object-fit: cover;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .foto-id .id {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 20px;
  right: 5px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .foto-id .id .icone {
  margin-right: 5px;
  font-size: 22px;
  color: #001b48;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .foto-id .id p {
  font-size: 12px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .foto-id .id p i {
  font-weight: bold;
  font-style: normal;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .dados {
  text-align: center;
  margin-top: 20px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .dados .nome {
  font-size: 22px;
  font-weight: bold;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .dados .telefones {
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #99b2c652;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .dados .telefones div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .dados .telefones div .icone {
  margin-right: 5px;
  color: #001b48;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura {
  margin-top: 20px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura .titulo {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura .dados-assinatura {
  margin-top: 15px;
  text-align: center;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura .dados-assinatura .nome-assinatura {
  color: #001b48;
  font-weight: bold;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura .dados-assinatura .validade {
  margin-top: 8px;
  font-size: 12px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura .dados-assinatura .btn-assinatura {
  padding: 6px 8px;
  background-color: #fff;
  color: #001b48;
  border: 2px solid #001b48;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 20px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura .dados-assinatura .btn-assinatura:hover {
  filter: brightness(120%);
}

.container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura .dados-assinatura .cancelar {
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
}

.container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura .dados-assinatura .cancelar:hover {
  text-decoration: underline;
}

.container-perfil-transportadora .perfil-transportadora .container-campos {
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 1px 2px 8px 0px;
  flex: 1;
}

.container-perfil-transportadora .perfil-transportadora .container-campos .topo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  border-bottom: 1px solid #99b2c652;
}

.container-perfil-transportadora .perfil-transportadora .container-campos .topo button {
  padding: 6px 20px;
  background-color: #fff;
  color: #001b48;
  border: 2px solid #001b48;
  border-radius: 4px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 5px;
}

.container-perfil-transportadora .perfil-transportadora .container-campos .topo button:hover {
  filter: brightness(120%);
}

.container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs {
  padding: 25px;
}

.container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs .container-inputs-1col {
  margin: 20px 0;
}

.container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs .container-inputs-2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
}

.container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs .container-inputs-3col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
}

.container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs label {
  width: 100%;
  font-size: 12px;
}

.container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs label input,
.container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs label textarea {
  width: 100%;
  padding: 12px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  margin-top: 3px;
  background-color: #f9fbfd;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 5px;
}

.container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs label input::placeholder,
.container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs label textarea::placeholder {
  color: #9ab2c6;
}

.container-perfil-transportadora .btn-voltar {
  display: block;
  margin: 0 auto;
  padding: 12px 15px;
  background-color: #9ab2c6;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

.container-perfil-transportadora .btn-voltar:hover {
  filter: brightness(120%);
}

.alert-dados-transportadora-alterados {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-dados-transportadora-alterados .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-dados-transportadora-alterados .react-confirm-alert-body {
  color: #1c2732;
}

.alert-dados-transportadora-alterados button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #001b48;
}

.alert-dados-transportadora-alterados button:hover {
  filter: brightness(120%);
}

@media (max-width: 980px) {
  .container-perfil-transportadora {
    padding: 25px 10px;
  }
  .container-perfil-transportadora .perfil-transportadora {
    margin: 20px 10px;
  }
  .container-perfil-transportadora .perfil-transportadora .container-campos .topo {
    padding: 25px 10px;
  }
  .container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs {
    padding: 10px;
  }
}

@media (max-width: 855px) {
  .container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs .container-inputs-2col,
  .container-perfil-transportadora .perfil-transportadora .container-campos .container-inputs .container-inputs-3col {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 730px) {
  .container-perfil-transportadora .perfil-transportadora {
    flex-direction: column;
    align-items: center;
  }
  .container-perfil-transportadora .perfil-transportadora .container-foto {
    width: 100%;
    margin-right: 0;
  }
  .container-perfil-transportadora .perfil-transportadora .container-foto .container-assinatura .dados-assinatura {
    margin: 15px 0;
  }
  .container-perfil-transportadora .perfil-transportadora .container-campos {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 450px) {
  .container-perfil-transportadora .perfil-transportadora .container-foto .foto-id {
    flex-direction: column;
  }
  .container-perfil-transportadora .perfil-transportadora .container-foto .foto-id {
    flex-direction: column;
    align-items: center;
  }
  .container-perfil-transportadora .perfil-transportadora .container-foto .foto-id .id {
    position: static;
  }
}