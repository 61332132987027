.container-input textarea {
    width: 100%;
    background-color: #f1f4fa;
    padding: 12px 10px;
    border: 1px solid #9ab2c6;
    border-radius: 8px;
}

.container-perfil-editar-transportadora {
    width: 100vw;
    margin: 10px 10px 15px 0;
    background-color: #fff;
    border-radius: 15px;
    padding: 25px 40px;
}

.container-perfil-editar-transportadora .btn-voltar-pagina {
    padding: 3px 8px;
    border-radius: 5px;
    background-color: #f1f4fa;
    border: 2px solid #9ab2c6;
    font-weight: bold;
    margin-bottom: 20px;
}

.container-perfil-editar-transportadora .btn-voltar-pagina:hover {
    filter: brightness(120%);
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .titulo-pagina {
    font-size: 20px;
    font-weight: bold;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .subtitulo-pagina {
    margin-top: 5px;
    font-weight: bold;
    color: #001b48;
}
.container-perfil-editar-transportadora .btn-remove{
    background: transparent;
    max-width: 60px;
    border: none;
    color: red;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
}

.container-perfil-editar-transportadora .btn-add{
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
}

.container-perfil-editar-transportadora .btn-add svg{
    color: green;
    font-size: 1.5rem;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria {
    margin-top: 30px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .titulo-categoria {
    font-size: 16px;
    font-weight: bold;
    color: #9ab2c6;
}


.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin: 20px 0;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search .input-search {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    padding: 12px 10px 12px 10px;
    background-color: #f1f4fa;
    border: 1px solid #9ab2c6;
    border-radius: 8px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search .input-search input {
    flex: 1;
    border: none;
    background-color: #f1f4fa;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search .input-search input::placeholder {
    color: gray;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search .input-search .icone {
    margin-left: 5px;
    font-size: 22px;
    color: #9ab2c6;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search .btn-buscar {
    width: 120px;
    background-color: #001b48;
    margin-left: 25px;
    height: 48px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search .btn-buscar:hover {
    filter: brightness(120%);
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria label.input-file .container-foto-perfil {
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    width: 225px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria label.input-file .container-foto-perfil img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria label.input-file .container-foto-perfil .foto {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    background-color: white;
    border: 1px solid #9ab2c6;
    border-radius: 10px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria label.input-file .container-foto-perfil p {
    margin-left: 15px;
    font-weight: bold;
    color: #001b48;
    cursor: pointer;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria label.input-file .container-foto-perfil p:hover {
    text-decoration: underline;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria label.input-file input {
    display: none;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input {
    position: relative;
    margin-top: 20px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input,
.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input select {
    width: 100%;
    background-color: #f1f4fa;
    padding: 12px 10px;
    border: 1px solid #9ab2c6;
    border-radius: 8px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input .banco>div {
    width: 100%;
    background-color: #f1f4fa;
    border: 1px solid #9ab2c6;
    border-radius: 8px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input[type="date"]:invalid::-webkit-datetime-edit,
.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input[type="date"]:disabled::-webkit-datetime-edit {
    color: transparent;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input[type="file"]:disabled {
    color: transparent;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input[type="file"]:disabled::-webkit-file-upload-button {
    display: none;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input[type="date"]:focus::-webkit-datetime-edit {
    color: black !important;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input[type="date"]+span {
    top: 16px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input span.fixed {
    top: -8px;
    background: #fff;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input select {
    padding: 11.6px 10px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input span {
    position: absolute;
    top: 14px;
    left: 15px;
    letter-spacing: 0.05em;
    pointer-events: none;
    transition: 0.4s;
    font-size: 13px;
    color: gray;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input span i {
    color: red;
    font-style: normal;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input:focus+span,
.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input:disabled+span,
.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input select:focus+span,
.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input select:disabled+span,
.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input input:valid+span,
.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input select:valid+span {
    top: -8px;
    left: 10px;
    font-size: 12px;
    color: #9ab2c6;
    background-color: #fff;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-1col {
    width: 45%;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-2col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10%;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-fotos-caminhao {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-fotos-caminhao .foto-caminhao {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    object-fit: cover;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-1col-combobox .container-combobox-select {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-1col-combobox .container-combobox-select .container-icones {
    display: flex;
    justify-content: flex-end;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-1col-combobox .container-combobox-select .container-icones .icone {
    margin-left: 5px;
    font-size: 20px;
    color: #001b48;
    cursor: pointer;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-1col-combobox .container-combobox-select .container-icones .icone:hover {
    filter: brightness(120%);
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-1col-combobox .container-combobox-select .container-input {
    margin-top: 5px !important;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-1col-combobox .btn-selecionar-select-combobox {
    height: 42px;
    width: 100px;
    margin-left: 20px;
    background-color: #001b48;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input-checkbox {
    position: relative;
    margin-top: 20px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input-checkbox {
    font-weight: bold;
    user-select: none;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input-checkbox .checkbox {
    cursor: pointer;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input-checkbox .checkbox input[type="checkbox"] {
    display: none;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input-checkbox .checkbox input[type="checkbox"]+span.checked {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 20px;
    height: 20px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #9ab2c6;
    border-radius: 50%;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input-checkbox .checkbox input[type="checkbox"]:checked+span.checked {
    background: #001b48 -19px top no-repeat;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-input-checkbox .checkbox input[type="checkbox"]+span.checked {
    margin-right: 10px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .container-botoes {
    display: flex;
    justify-content: center;
    margin: 80px auto 20px auto;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .container-botoes button {
    width: 250px;
    padding: 12px 0;
    background-color: #f1f4fa;
    border: 2px solid #9ab2c6;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .container-botoes button:hover {
    filter: brightness(120%);
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .container-botoes button:disabled {
    opacity: 0.4;
    pointer-events: none;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .container-botoes button.btn-cancelar {
    margin-right: 30px;
}

.container-perfil-editar-transportadora .perfil-editar-transportadora .container-botoes button.btn-cadastrar {
    background-color: #001b48;
    border-color: #001b48;
    color: #fff;
}


.container-perfil-editar-transportadora .carroceria-box {
    margin: 20px 0;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    position: relative;
}

.container-perfil-editar-transportadora .carroceria-box.disabled {
    opacity: 0.6;
    pointer-events: none;
}

.container-perfil-editar-transportadora .carroceria-box .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.container-perfil-editar-transportadora .carroceria-box .carroceria-title {
    font-weight: bold;
    font-size: 20px;
}

.container-perfil-editar-transportadora .add-carroceria-line {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.container-perfil-editar-transportadora .add-carroceria-line button {
    width: 250px;
    padding: 12px 0;
    border: 2px solid #001b48;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    background-color: #001b48;
    color: #fff;
}

.container-perfil-editar-transportadora .add-carroceria-line button:disabled {
    opacity: 0.4;
    pointer-events: none;
}

.container-perfil-editar-transportadora .add-carroceria-line button:hover {
    filter: brightness(120%);
}

.container-perfil-editar-transportadora .save-carroceria-line {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.container-perfil-editar-transportadora .save-carroceria-line button {
    padding: 12px 30px;
    border: 2px solid #001b48;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    background-color: #001b48;
    color: #fff;
}

.container-perfil-editar-transportadora .save-carroceria-line button:hover {
    filter: brightness(120%);
}

.container-alert-adicionar {
    width: 500px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 30px;
    animation-duration: 0.2s;
}

.container-alert-adicionar h1 {
    text-align: center;
    margin-bottom: 30px;
}

.container-alert-adicionar p {
    margin-bottom: 10px;
    font-weight: bold;
    color: #001b48;
}

.container-alert-adicionar input {
    width: 100%;
    background-color: #f1f4fa;
    padding: 12px 10px;
    border: 1px solid #9ab2c6;
    border-radius: 8px;
}

.container-alert-adicionar .container-btn-grupo-permissoes {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 40px;
}

.container-alert-adicionar .container-btn-grupo-permissoes button {
    width: 180px;
    padding: 12px 0;
    background-color: #f1f4fa;
    border: 2px solid #9ab2c6;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
}

.container-alert-adicionar .container-btn-grupo-permissoes button:hover {
    filter: brightness(120%);
}

.container-alert-adicionar .container-btn-grupo-permissoes button.btn-adicionar {
    background-color: #001b48;
    border-color: #001b48;
    color: #fff;
}

@media (max-width: 670px) {
    .container-perfil-editar-transportadora {
        padding: 25px 10px;
    }

    .container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-2col {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
    }

    .container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-inputs-1col {
        width: 100%;
    }

    .container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-fotos-caminhao {
        margin-top: 0;
        flex-wrap: wrap;
    }

    .container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-fotos-caminhao .foto-caminhao {
        margin-top: 10px;
    }

    .container-alert-adicionar {
        width: 100%;
    }

    .container-alert-adicionar .container-btn-grupo-permissoes {
        flex-direction: column;
    }

    .container-alert-adicionar .container-btn-grupo-permissoes button {
        width: 100%;
    }

    .container-alert-adicionar .container-btn-grupo-permissoes button:first-of-type {
        margin-bottom: 10px;
    }
}

@media (max-width: 470px) {
    .container-perfil-editar-transportadora .perfil-editar-transportadora .container-botoes {
        flex-direction: column;
        margin: 80px auto 10px auto;
    }

    .container-perfil-editar-transportadora .perfil-editar-transportadora .container-botoes button {
        width: 100%;
        margin-bottom: 15px;
    }
}

@media (max-width: 450px) {
    .container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search {
        flex-direction: column;
        width: 100%;
    }

    .container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search .input-search {
        width: 100%;
    }

    .container-perfil-editar-transportadora .perfil-editar-transportadora .categoria .container-search .btn-buscar {
        width: 100%;
        background-color: #001b48;
        margin: 10px 0 0 0;
    }
}