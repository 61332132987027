.container-aliquotas {
    width: calc(100vw - 100px);
    margin: 10px 10px 15px 0;
    background-color: #fff;
    border-radius: 15px;
    padding: 25px 40px;

    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.container-aliquotas .destinoTitle {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding: 5px 0;
    background: rgba(0, 0, 0, 0.05);
}
.container-aliquotas .base {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: auto;
    
    position: relative;
}
.container-aliquotas .base .table-left {
    position: sticky;
    left: 0;
    top: 0;
    width: 60px;
    z-index: 10;
    background: #FFF;
}
.container-aliquotas .base .table-right {
    position: sticky;
    right: 0;
    top: 0;
    width: 60px;
    z-index: 10;
    background: #FFF;
}
.container-aliquotas .base .aliquotas-table,
.container-aliquotas .base .aliquotas-table tr,
.container-aliquotas .base .aliquotas-table td,
.container-aliquotas .base .aliquotas-table th {
    border: none;
    border-collapse: collapse;
}
.container-aliquotas .base .aliquotas-table th,
.container-aliquotas .base .aliquotas-table td {
    box-sizing: border-box;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 25px;
    min-height: 25px;
    max-height: 25px;
    text-align: left;
}
.container-aliquotas .base .aliquotas-table .input-container {
    box-sizing: border-box;
    padding-right: 10px;
    padding-bottom: 4px;
}
.container-aliquotas .base .aliquotas-table .input-container input {
    max-width: 100%;
    border: 1px solid transparent;
    background: rgba(0, 0, 0, 0.1);
    height: 100%;
    border-radius: 4px;
}
.container-aliquotas .base .table-right th {
    text-align: right;
    width: 40px;
    min-width: 40px;
    max-width: 40px;
}
.container-aliquotas .base .table-right th:not(.origemTitleContainer) {
    padding-right: 10px;
}
.container-aliquotas .base .table-right th.origemTitleContainer {
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    background: rgba(0, 0, 0, 0.05);
}
.container-aliquotas .base .table-right .origemTitle {
    writing-mode: vertical-rl;
    text-orientation: upright;
}
.container-aliquotas .base .table-left th {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
}
.container-aliquotas .base .table-left th:not(.origemTitleContainer) {
    padding-left: 10px;
}
.container-aliquotas .base .table-left th.origemTitleContainer {
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    background: rgba(0, 0, 0, 0.05);
}
.container-aliquotas .base .table-left .origemTitle {
    writing-mode: vertical-rl;
    text-orientation: upright;
}
.container-aliquotas .container-botoes {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.container-aliquotas .container-botoes button {
    width: 250px;
    padding: 12px 0;
    background-color: #f1f4fa;
    border: 2px solid #9ab2c6;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
}
  
.container-aliquotas .container-botoes button:hover {
    filter: brightness(120%);
}
  
.container-aliquotas .container-botoes button.btn-cancelar {
    margin-right: 30px;
}

.container-aliquotas .container-botoes button.btn-cadastrar {
    background-color: #001b48;
    border-color: #001b48;
    color: #fff;
}
.container-aliquotas .loading-container {
    width: 100%;
    height: 400px;

    display: flex;
    align-items: center;
    justify-content: center;
}