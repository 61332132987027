.carga-etapa-1 {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 20px 5px 20px;
}

.carga-etapa-1 .conteudo {
  flex: 1;
}

.carga-etapa-1 .coletas {
  margin: 20px 0;
}
.carga-etapa-1 .coletas .actions {
  margin-top: 10px;
}
.carga-etapa-1 .elipsis {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.carga-etapa-1 .coleta-actions {
  display: flex;
  gap: 5px;
}
.carga-etapa-1 .coleta-actions .coleta-icon {
  cursor: pointer;
}
.carga-etapa-1 .coleta-actions .coleta-icon-hide {
  opacity: 0;
  pointer-events: none;
}

.carga-etapa-1 .navegadores {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.carga-etapa-1 .navegadores .icone {
  font-size: 30px;
  color: #bacad9;
}

.carga-etapa-1 .navegadores .icone.active {
  color: #20477e;
}

.carga-etapa-1 .navegadores .icone.active:hover {
  color: #20477e;
  transform: scale(1.1);
  cursor: pointer;
}

.carga-etapa-1 .conteudo .titulo-etapa {
  font-weight: bold;
}

.carga-etapa-1 .conteudo .input-radio {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.carga-etapa-1 .conteudo .input-radio .label {
  display: flex;
  align-items: center;
}

.carga-etapa-1 .conteudo .input-radio .label p {
  color: gray;
}

.carga-etapa-1 .conteudo .input-radio .label .icone {
  margin-left: 5px;
  color: #bacad9;
}

.carga-etapa-1 .conteudo .input-radio .container-radio {
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin-top: 5px;
}

.carga-etapa-1 .conteudo .input-radio .container-radio .container-input-radio {
  position: relative;
}

.carga-etapa-1 .conteudo .input-radio .container-radio .container-input-radio {
  font-weight: bold;
  user-select: none;
}

.carga-etapa-1 .conteudo .input-radio .container-radio .container-input-radio .radio {
  cursor: pointer;
}

.carga-etapa-1 .conteudo .input-radio .container-radio .container-input-radio .radio input[type="radio"] {
  display: none;
}

.carga-etapa-1 .conteudo .input-radio .container-radio .container-input-radio .radio input[type="radio"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.carga-etapa-1 .conteudo .input-radio .container-radio .container-input-radio .radio input[type="radio"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.carga-etapa-1 .conteudo .input-radio .container-radio .container-input-radio .radio input[type="radio"] + span.checked {
  margin-right: 5px;
}

.carga-etapa-1 .conteudo .container-input-combo {
  display: flex;
  align-items: center;
  margin: 10px 0 5px 0;
  background-color: #f1f4fa;
  padding: 10.6px 10px 10.6px 2px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.carga-etapa-1 .conteudo .container-input-combo .ReactTags__tags {
  flex: 1;
}

.carga-etapa-1 .conteudo .container-input-combo .ReactTags__selected {
  display: flex;
}

.carga-etapa-1 .conteudo .container-input-combo .ReactTags__selected .ReactTags__tag {
  margin-right: 3px;
  background-color: #99b2c6;
  border-radius: 5px;
  padding: 3px;
  font-size: 14px;
  color: #fff;
}

.carga-etapa-1 .conteudo .container-input-combo .ReactTags__selected .ReactTags__remove {
  background-color: transparent;
  border: none;
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.carga-etapa-1 .conteudo .container-input-combo .ReactTags__selected .ReactTags__tagInput {
  flex: 1;
}

.carga-etapa-1 .conteudo .container-input-combo .ReactTags__selected .ReactTags__tagInputField {
  width: 100%;
  height: 100%;
  background-color: #f1f4fa;
  border: none;
}

.carga-etapa-1 .conteudo .container-input-combo .icone {
  color: #bacad9;
  margin: 0 5px;
}

.carga-etapa-1 .conteudo .container-input {
  position: relative;
  margin-top: 15px;
}

.carga-etapa-1 .conteudo .container-input .icone {
  color: #bacad9;
  margin-right: 5px;
}

.carga-etapa-1 .conteudo .container-input input,
.carga-etapa-1 .conteudo .container-input select {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.carga-etapa-1 .conteudo .container-input select {
  padding: 11px 10px 11px 2px;
}

.carga-etapa-1 .conteudo .container-input span {
  position: absolute;
  top: 14px;
  left: 9px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.carga-etapa-1 .conteudo .container-input span i {
  color: red;
  font-style: normal;
}

.carga-etapa-1 .conteudo .container-input input:focus + span,
.carga-etapa-1 .conteudo .container-input input:disabled + span,
.carga-etapa-1 .conteudo .container-input select:focus + span,
.carga-etapa-1 .conteudo .container-input select:disabled + span,
.carga-etapa-1 .conteudo .container-input input:valid + span,
.carga-etapa-1 .conteudo .container-input select:valid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.carga-etapa-1 .conteudo .nome-atual-dado {
  font-size: 12px;
}

.carga-etapa-1 .conteudo .container-input-manter {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.carga-etapa-1 .conteudo .container-input-manter .container-icones {
  display: flex;
  justify-content: flex-end;
}

.carga-etapa-1 .conteudo .container-input-manter .container-icones .icone {
  font-size: 20px;
  color: #001b48;
  cursor: pointer;
}

.carga-etapa-1 .conteudo .container-input-manter .container-icones .icone.adicionar {
  margin-left: 3px;
}

.carga-etapa-1 .conteudo .container-input-manter .container-icones .icone:hover {
  transform: scale(1.1);
}

.carga-etapa-1 .conteudo .container-input-manter .container-input {
  margin-top: 2px !important;
}

.carga-etapa-1 .conteudo .container-input-manter .container-input.manter {
  display: flex;
}

.carga-etapa-1 .conteudo .container-input-manter .container-input.manter div {
  flex: 1;
}

.carga-etapa-1 .conteudo .container-input-manter .container-input.manter button {
  margin-left: 5px;
  padding: 0 8px;
  background-color: #001b48;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  color: #fff;
}

.carga-etapa-1 .conteudo .container-input-manter .container-input.manter button:hover {
  filter: brightness(140%);
}

.carga-etapa-1 .conteudo .container-input-destinos {
  display: flex;
  align-items: center;
}

.carga-etapa-1 .conteudo .container-input-destinos .icone-remover {
  margin-top: 12px;
  font-size: 22px;
  color: red;
  cursor: pointer;
}

.carga-etapa-1 .conteudo .container-input-destinos .icone-remover:hover {
  transform: scale(1.1);
}

.carga-etapa-1 .conteudo .container-input.localizacao {
  position: relative;
  flex: 1;
}

.carga-etapa-1 .conteudo .container-input.localizacao .icone-localizacao {
  position: absolute;
  top: 10px;
  font-size: 22px;
  right: 5px;
  color: #001b48;
}

.carga-etapa-1 .conteudo .info-localizacao {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carga-etapa-1 .conteudo .info-localizacao .icone {
  color: #bacad9;
}

.carga-etapa-1 .conteudo .info-localizacao .icone-localizacao {
  font-size: 25px;
  color: #001b48;
  margin: 0 5px;
}

.carga-etapa-1 .conteudo .info-localizacao p {
  font-size: 20px;
  font-weight: bold;
}

.carga-etapa-1 .conteudo .container-input-quantidade .label {
  margin: 10px 0;
  text-align: center;
  color: gray;
}

.carga-etapa-1 .conteudo .container-input-quantidade .input {
  display: flex;
  height: 41px;
  width: 100px;
  background-color: #f1f4fa;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
  margin: 0 auto;
  z-index: 2;
}

.carga-etapa-1 .conteudo .container-input-quantidade .input input {
  width: 100%;
  border: none;
  text-align: center;
  font-weight: bold;
  color: #001b48;
}

.carga-etapa-1 .conteudo .container-input-quantidade .input button {
  min-width: 25px;
  font-size: 25px;
  border: none;
  background-color: #fff;
}

.carga-etapa-1 .conteudo .container-input-quantidade .input button.decrementa {
  border-right: 1px solid #99b2c6;
  border-radius: 10px 0 0 10px;
}

.carga-etapa-1 .conteudo .container-input-quantidade .input button.incrementa {
  border-left: 1px solid #99b2c6;
  border-radius: 0 10px 10px 0;
}

.carga-etapa-1 .conteudo .msg-erro,
.carga-etapa-1 .conteudo .msg-erro-input-pedidos {
  font-size: 12px;
  font-weight: bold;
  color: #ff0000;
}

.carga-etapa-1 .selecionar-endereco {
  margin-top: 16px;

  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 0 16px;
}
.carga-etapa-1 .selecionar-endereco.remove {
  grid-template-columns: 200px 1fr 48px;
}
.carga-etapa-1 .selecionar-endereco.remove button {
  appearance: none;
  border: transparent;
  background: transparent;
  cursor: pointer;
}
.carga-etapa-1 .selecionar-endereco .title {
  grid-column: 1 / -1;
  padding-bottom: 10px;
  color: gray;
}
