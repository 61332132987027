.tabela-faixas-peso {
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;

  .inline-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    gap: 10px;
    padding: 0 10px;

    input {
      width: 50px !important;
    }
  }

  > thead,
  > tbody,
  > tfoot,
  > thead > tr,
  > thead > tr > td,
  > thead > tr > th,
  > tbody > tr,
  > tbody > tr > td,
  > tbody > tr > th,
  > tfoot > tr,
  > tfoot > tr > td {
    border-collapse: collapse;
  }

  > thead {
    > tr {
      > th {
        padding: 10px 3px;
        color: #fff;
        font-size: 12px;
        font-weight: normal;
        text-align: center;
        background: #3c89f5;
        border: 1px solid #fff;
      }
    }
  }

  > tbody {
    > tr {
      > td {
        padding: 5px !important;
        text-align: left;
      }
    }
  }

  > tfoot {
    > tr {
      > td {
        padding: 0;
        text-align: right;
        background: #3c89f5;

        button {
          color: #fff;
        }
      }
    }
  }
}
