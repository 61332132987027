.container-input-search {
  width: 45%;
  margin: 20px 0;
}

.container-input-search .input-search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px 12px 10px;
  background-color: #f1f4fa;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-input-search .input-search input {
  flex: 1;
  border: none;
  background-color: #f1f4fa;
}

.container-input-search .input-search input::placeholder {
  color: gray;
}

.container-input-search .input-search .icone {
  margin-left: 5px;
  font-size: 22px;
  color: #9ab2c6;
}

.container-input-search .no-suggestions {
  color: #9ab2c6;
  padding: 0.5rem;
}

.container-input-search .suggestions {
  border: 1px solid #9ab2c6;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
}

.container-input-search .suggestions li {
  padding: 0.5rem;
}

.container-input-search .suggestion-active,
.container-input-search .suggestions li:hover {
  cursor: pointer;
  background-color: #f1f4fa;
}

.container-input-search .suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #9ab2c6;
}

@media (max-width: 670px) {
  .container-input-search {
    width: 100%;
  }
}