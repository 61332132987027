.Toastify__toast-container {
    z-index: 10002 !important;
}
.toast-error {
    background: #ffcdd2;
    color: #222;
}
.toast-error-progress {
    background: #f44336;
}
.toast-error-body > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
}
.toast-error-body .toast-title {
    font-weight: bold;
}

.toast-success {
    background: #b2dfdb;
    color: #222;
}
.toast-success-progress {
    background: #004d40;
}
.toast-success-body > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
}
.toast-success-body .toast-title {
    font-weight: bold;
}