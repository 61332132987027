.escolher-forma-pagamento {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.escolher-forma-pagamento .titulo {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #001b48;
}

.escolher-forma-pagamento .subtitulo {
  font-weight: 500;
  color: #001b48;
  margin-bottom: 15px;
}


.escolher-forma-pagamento .quadro-total-pagamento {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3eedf7;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.escolher-forma-pagamento .quadro-total-pagamento.valor {
  background-color: #92d36e;
}

.escolher-forma-pagamento .quadro-total-pagamento p {
  font-size: 16px;
  font-weight: bold;
  color: #fff
}

.escolher-forma-pagamento .quadro-total-pagamento .input-quantidade {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
}

.escolher-forma-pagamento .quadro-total-pagamento .input-quantidade input {
  width: 80%;
  margin: 0 3px 0 10px;
  background-color: #fff;
  border: none;
  text-align: center;
  font-weight: bold;
}

.escolher-forma-pagamento .quadro-total-pagamento .input-quantidade input::placeholder {
  font-size: 12px;
}

.escolher-forma-pagamento .separador {
  border: 1px solid #ced9e5;
  background-color: #ced9e5;
  margin: 25px 0;
}

.escolher-forma-pagamento .quadro-forma-pagamento {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
  background-color: #f1f4fa;
  border-radius: 10px;
  padding: 0 20px;
  margin-bottom: 35px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.escolher-forma-pagamento .quadro-forma-pagamento p {
  font-weight: bold;
}

.escolher-forma-pagamento .quadro-forma-pagamento .container-input-checkbox {
  position: relative;
  user-select: none;
}

.escolher-forma-pagamento .quadro-forma-pagamento .container-input-checkbox .checkbox {
  cursor: pointer;
}

.escolher-forma-pagamento .quadro-forma-pagamento .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.escolher-forma-pagamento .quadro-forma-pagamento .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 30px;
  height: 30px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.escolher-forma-pagamento .quadro-forma-pagamento .container-input-checkbox .checkbox input[type="checkbox"]:checked + span.checked {
  background: #018abe -19px top no-repeat;
}

.escolher-forma-pagamento .quadro-forma-pagamento .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  margin-right: 10px;
}

.escolher-forma-pagamento .quadro-forma-pagamento img {
  height: 45px;
}

.escolher-forma-pagamento .container-botoes {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: auto;
}

.escolher-forma-pagamento .container-botoes button {
  border: none;
  border-radius: 8px;
  background-color: #97cadb;
  padding: 12px 0;
  width: 200px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.escolher-forma-pagamento .container-botoes button.avancar {
  background-color: #018abe;
}

.escolher-forma-pagamento .container-botoes button:hover {
  filter: brightness(120%);
}