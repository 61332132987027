.tabela-precos {
  .action-copy-bar {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tabela-cliente {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    
    margin-bottom: 20px;

    caption {
      padding: 10px 3px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      cursor: default;
      text-align: center;
      border-right: 1px solid #fff;
      background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
      border-top-left-radius: 5px;
      background-color: #20477e;
    }

    > tbody {
      > tr {
        > th[scope=row] {
          width: 200px;
          text-align: end;
          font-weight: bold;
          border: 1px solid #CCC;
          padding: 5px;
        }


        &:first-of-type {
          th {
            border-radius: 10px 0 0 0;
          }
        }

        > td {
          border: 1px solid #fff;
          text-align: left;
          
          &:not(.no-pad) {
            padding: 5px;
          }
        }
      }
    }
  }
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 10px 5px;
}

.row, > .grid > .row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.row.mb {
  margin-bottom: 20px;
}
