.tabela-valor-combinado {
  width: 100%;
  border-collapse: collapse;

  > thead {
    > tr {
      > th {
        color: #fff;
        font-size: 12px;
        font-weight: normal;
        text-align: center;
        background: #3c89f5;
        padding: 10px 3px;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
      }
    }
  }

  > tbody {
    > tr {
      > td {
        text-align: left;
        padding: 5px;
      }
    }
  }

  .veiculo-line {
    text-align: right;
    text-transform: uppercase;
  }

  .pedagio-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    gap: 5px;
  }
}
