.container-tabela-listar-motoristas {
  margin-top: 20px;
  max-width: 100%;
  overflow: auto;
}

.container-tabela-listar-motoristas table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.container-tabela-listar-motoristas table thead tr th {
  padding: 10px;
  border-bottom: 1px solid #9ab2c6;
  font-size: 16px;
  color: black;
}

.container-tabela-listar-motoristas table tbody tr.divisoria-linhas-tabela {
  height: 10px;
  background-color: #fff;
}

.container-tabela-listar-motoristas table tbody tr td {
  background-color: #f1f4fa;
  padding: 5px 10px;
  color: gray;
}

.container-tabela-listar-motoristas table tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}

.container-tabela-listar-motoristas table tbody tr td:last-child {
  border-radius: 0px 8px 8px 0px;
}

.container-tabela-listar-motoristas table .coluna-nome-motorista {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  margin-left: 35%;
}

.container-tabela-listar-motoristas table .coluna-nome-motorista img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.container-tabela-listar-motoristas .ver-motivo {
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: -30%;
  left: 50%;
  white-space: pre-wrap;
  max-width: 250px;
  width: 100%;
  transform: translateX(-50%);
  background-color: #018abe;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
}

.tooltip{
  font-size: 12px;
  text-align: left;
}

td {
  position: relative;
}

.container-tabela-listar-motoristas table .coluna-acoes .icone {
  margin: 0 3px;
  font-size: 22px;
  color: #001b48;
  cursor: pointer;
}

.container-tabela-listar-motoristas table .coluna-acoes .icone.favorito {
  color: #D4AF37;
}

.container-tabela-listar-motoristas table .coluna-acoes .icone:hover {
  filter: brightness(120%);
}

.alert-remover-motorista {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-remover-motorista .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-remover-motorista button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-remover-motorista button:hover {
  filter: brightness(120%);
}

.alert-remover-motorista button.botao-confirmar {
  background-color: #cc3300;
}

.alert-remover-motorista button.botao-cancelar {
  background-color: #001b48;
}

.container-alert-adicionar-motorista {
  margin: 0 auto;
  background-color: #fff;
  border: 2px solid #001b48;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  animation-duration: 0.1s;
}

.container-alert-adicionar-motorista .titulo {
  font-weight: bold;
}

.container-alert-adicionar-motorista .botoes {
  display: flex;
  justify-content: space-between;
}

.container-alert-adicionar-motorista .botoes button {
  border: none;
  width: 100px;
  padding: 8px 0;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}

.container-alert-adicionar-motorista .botoes button:hover {
  filter: brightness(120%);
}

.container-alert-adicionar-motorista .botoes button.cancelar {
  background-color: red;
}

.container-alert-adicionar-motorista .botoes button.confirmar {
  background-color: #85bf64;
}

.container-alert-adicionar-motorista .container-input {
  position: relative;
  margin: 30px 0;
}

.container-alert-adicionar-motorista .container-input select {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-novo-motorista .novo-motorista .categoria .container-input select {
  padding: 11.6px 10px;
}

.container-alert-adicionar-motorista .container-input span {
  position: absolute;
  top: 14px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.container-alert-adicionar-motorista .container-input span i {
  color: red;
  font-style: normal;
}

.container-alert-adicionar-motorista .container-input select:focus+span,
.container-alert-adicionar-motorista .container-input select:disabled+span,
.container-alert-adicionar-motorista .container-input select:valid+span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

@media (max-width: 670px) {
  .container-tabela-listar-motoristas table .coluna-nome-motorista {
    justify-content: flex-start;
    margin-left: 0;
  }
}