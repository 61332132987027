.container-tabela-relatorio-cargas {
  margin-top: 20px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-tabela-relatorio-cargas table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.container-tabela-relatorio-cargas table thead tr th {
  padding: 10px 3px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  border-right: 1px solid #fff;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.container-tabela-relatorio-cargas table thead tr th:first-of-type {
  border-top-left-radius: 5px;
}

.container-tabela-relatorio-cargas table thead tr th:last-of-type {
  border-top-right-radius: 5px;
}

.container-tabela-relatorio-cargas table tbody tr td {
  padding: 15px;
  font-size: 12px;
  border-right: 1px solid #fff;
}

.container-tabela-relatorio-cargas table tbody tr td span.cte-retificar {
  text-decoration: underline;
  cursor: pointer;
}

.container-tabela-relatorio-cargas table tbody tr.linha td {
  background-color: #d6e8ee;
}

.container-tabela-relatorio-cargas table tbody tr.linha.impar td {
  background-color: #99b2c6;
}

.container-tabela-relatorio-cargas table tbody tr.linha-selecionada td {
  background-color: #97cadb;
}

.container-tabela-relatorio-cargas table tbody td.coluna-modal.titulo-esquerda {
  text-align: left;
}

.container-tabela-relatorio-cargas table tbody td.coluna-modal span {
  text-decoration: underline;
  cursor: pointer;
}

.container-tabela-relatorio-cargas table tbody td.coluna-modal .icone {
  font-size: 25px;
  cursor: pointer;
  color: #0f8dbf;
}
.container-tabela-relatorio-cargas table tbody td.coluna-modal .icone.disabled {
  color: gray;
  opacity: 0.5;
}

.container-tabela-relatorio-cargas table .coluna-acoes .icone {
  font-size: 20px;
  color: #fff;
}

.container-tabela-relatorio-cargas table .coluna-acoes .icone:first-of-type {
  margin-right: 2px;
}

.container-tabela-relatorio-cargas table .coluna-acoes .icone {
  background-color: #3c89f5;
  border-radius: 50%;
  padding: 2px;
}

.container-tabela-relatorio-cargas table .coluna-acoes .icone.lixo {
  background-color: red;
}

.container-tabela-relatorio-cargas table .coluna-acoes .icone:hover,
.container-tabela-relatorio-cargas table tbody td.coluna-modal .icone:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.container-tabela-relatorio-cargas table tbody tr.linha-total td {
  background-color: #018abe;
  border-right: 1px solid #018abe;
  color: #fff;
}

.container-tabela-relatorio-cargas table tbody tr.linha-total td.coluna-total {
  border: 1px solid #fff;
  border-top: none;
  border-bottom: none;
  border-left: 1.5px solid #fff;
}

.container-tabela-relatorio-cargas table tbody tr.linha-total td:first-of-type {
  border-bottom-left-radius: 5px;
}

.container-tabela-relatorio-cargas table tbody tr.linha-total td:last-of-type {
  border-bottom-right-radius: 5px;
}

.alert-remover-nfe {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-remover-nfe .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-remover-nfe .react-confirm-alert-body {
  color: #1c2732;
}

.alert-remover-nfe button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-remover-nfe button:hover {
  filter: brightness(120%);
}

.alert-remover-nfe button.botao-confirmar {
  background-color: #cc3300;
}

.alert-remover-nfe button.botao-cancelar {
  background-color: #001b48;
}

.tabela-relatorio-cargas .botoes-topo {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.tabela-relatorio-cargas .botoes-topo .prints {
  display: flex;
  align-items: center;
}

.tabela-relatorio-cargas .botoes-topo .prints .tag span {
  display: flex;
  align-items: center;
  cursor: help;
}

.tabela-relatorio-cargas .botoes-topo .prints .tag [tag-dado] {
  position: relative;
}

.tabela-relatorio-cargas .botoes-topo .prints .tag [tag-dado]:after {
  display: none;
  position: absolute;
  bottom: 30px;
  padding: 3px;
  border-radius: 3px;
  right: 0;
  content: attr(tag-dado);
  white-space: nowrap;
  background-color: #3d8af7;
  color: White;
  font-size: 12px;
}

.tabela-relatorio-cargas .botoes-topo .prints .tag [tag-dado]:hover:after {
  display: block;
  z-index: 5;
}

.tabela-relatorio-cargas .botoes-topo .prints .icone {
  font-size: 24px;
}

.tabela-relatorio-cargas .botoes-topo .prints .icone:first-of-type {
  margin-right: 10px;
}

.tabela-relatorio-cargas .botoes-topo .prints .icone.color {
  color: #3c89f5;
}

.tabela-relatorio-cargas .botoes-topo .prints .icone:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.tabela-relatorio-cargas .botoes-topo .btn-financeiro {
  display: flex;
  align-items: center;
  background-color: #3c89f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 5px 5px 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.tabela-relatorio-cargas .botoes-topo .btn-financeiro:hover {
  filter: brightness(110%);
}

.tabela-relatorio-cargas .botoes-topo .btn-financeiro .icone {
  margin-left: 20px;
  font-weight: bold;
}

.tabela-relatorio-cargas .botoes-topo .btns-esquerda {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tabela-relatorio-cargas .botoes-topo .btns-direita {
  display: flex;
  align-items: center;
}

.tabela-relatorio-cargas .botoes-topo .btns-direita button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c89f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
  margin-right: 10px;
}

.tabela-relatorio-cargas .botoes-topo .btns-direita button:hover {
  filter: brightness(110%);
}

.tabela-relatorio-cargas .botoes-topo .btns-direita button .icone {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

.container-remover-documento {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  animation-duration: 0.1s;
}

.container-remover-documento .titulo {
  margin-bottom: 20px;
}

.container-remover-documento .titulo span.cancelar {
  color: red;
  font-weight: bold;
}

.container-remover-documento .justificativa {
  margin: 20px 0;
}

.container-remover-documento .justificativa textarea {
  width: 100%;
  resize: none;
  border-radius: 3px;
  padding: 5px;
}

.container-conferir-documentos p span {
  font-weight: bold;
  color: #3d8af7;
}

.container-conferir-documentos p span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.container-remover-documento .botoes {
  display: flex;
  justify-content: center;
}

.container-remover-documento .botoes button {
  border: none;
  width: 50px;
  padding: 5px 0;
  font-weight: bold;
  color: #fff;
}

.container-remover-documento .botoes button:hover {
  filter: brightness(120%);
}

.container-remover-documento .botoes button.confirmar {
  background-color: red;
  border-radius: 0 3px 3px 0;
}

.container-remover-documento .botoes button.cancelar {
  background-color: #85bf64;
  border-radius: 3px 0 0 3px;
}

.alert-certificado-digital {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-certificado-digital .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-certificado-digital .react-confirm-alert-body {
  color: #1c2732;
}

.alert-certificado-digital button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #001b48;
}

.alert-certificado-digital button:hover {
  filter: brightness(120%);
}