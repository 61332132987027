.overlay-modal-gerar-cte {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.5);
  z-index: 10001;
}

.modal-gerar-cte {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-height: 90vh;
  background-color: #f7fafc;
  border-radius: 5px;
  overflow: auto;
}

.modal-gerar-cte .gerar-cte .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #3a4959;
  cursor: pointer;
}

.modal-gerar-cte .gerar-cte .icone-fechar:hover {
  filter: brightness(130%);
}

.modal-gerar-cte .gerar-cte .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
  text-align: center;
}

.modal-gerar-cte .gerar-cte {
  padding: 20px 15px;
}

.modal-gerar-cte .gerar-cte .container-formulario {
  position: relative;
  margin-top: 20px;
  background-color: #f1f4fa;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 15px;
  border-radius: 8px;
}

.modal-gerar-cte .gerar-cte .container-formulario .titulo-formulario {
  font-weight: bold;
  color: #001b48;
}
.modal-gerar-cte .gerar-cte .container-formulario .help {
  font-weight: normal;
  color: #001b48;
  opacity: 0.6;
}

.modal-gerar-cte .gerar-cte .container-formulario .titulo-formulario.prestacao {
  margin-top: 20px;
}

.modal-gerar-cte .gerar-cte .novos-papeis {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px 0;
}

.modal-gerar-cte .gerar-cte .novos-papeis button {
  background-color: #02457a;
  padding: 6px 15px;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.modal-gerar-cte .gerar-cte .container-inputs-6col {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 2%;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.modal-gerar-cte .gerar-cte .container-inputs-6col.larga {
  grid-template-columns: 2fr repeat(4, 1fr);
  grid-column-gap: 1%;
}

.modal-gerar-cte .gerar-cte .container-inputs-4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.modal-gerar-cte .gerar-cte .container-inputs-3col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.modal-gerar-cte .gerar-cte .container-inputs-3col.prestacao {
  grid-template-columns: 1.03fr 1fr 1fr;
  grid-column-gap: 2%;
}

.modal-gerar-cte .gerar-cte .container-inputs-2col {
  display: grid;
  grid-template-columns: 2.05fr 0.95fr;
  grid-column-gap: 5%;
  margin-top: 20px;
}

.modal-gerar-cte .gerar-cte .container-input-comum {
  position: relative;
}

.modal-gerar-cte .gerar-cte .container-input-comum input,
.modal-gerar-cte .gerar-cte .container-input-comum select {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-gerar-cte .gerar-cte .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.modal-gerar-cte .gerar-cte .container-input-comum.cinza input {
  background-color: #9cb6cd;
}

.modal-gerar-cte .gerar-cte .container-input-comum.cinza span {
  color: #fff;
}

.modal-gerar-cte .gerar-cte .container-input-comum.vermelho span {
  color: #ff0000;
}

.modal-gerar-cte .gerar-cte .container-input-comum input:focus + span,
.modal-gerar-cte .gerar-cte .container-input-comum input:disabled + span,
.modal-gerar-cte .gerar-cte .container-input-comum input:valid + span,
.modal-gerar-cte .gerar-cte .container-input-comum select:focus + span,
.modal-gerar-cte .gerar-cte .container-input-comum select:disabled + span,
.modal-gerar-cte .gerar-cte .container-input-comum select:valid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.modal-gerar-cte .gerar-cte .container-input-comum.cinza input:focus + span,
.modal-gerar-cte .gerar-cte .container-input-comum.cinza input:disabled + span,
.modal-gerar-cte .gerar-cte .container-input-comum.cinza input:valid + span {
  background-color: #9cb6cd;
  color: #fff;
}

.modal-gerar-cte .gerar-cte .container-input-comum input:disabled,
.modal-gerar-cte .gerar-cte .container-input-comum select:disabled {
  color: #3d8af7;
}

.modal-gerar-cte .gerar-cte .container-input-select {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-gerar-cte .gerar-cte .container-input-select select {
  background-color: #d6e8ee;
  padding: 10px 5px 10px 10px;
  border: none;
  border-radius: 8px;
  color: #8094aa;
}

.modal-gerar-cte .gerar-cte .container-input-select input {
  width: 100%;
  background-color: #fff;
  padding: 10px 5px;
  border: none;
  border-radius: 0 8px 8px 0;
}

.modal-gerar-cte .gerar-cte .container-input-checkbox {
  position: relative;
  margin-top: 10px;
}

.modal-gerar-cte .gerar-cte .container-input-checkbox {
  font-weight: bold;
  user-select: none;
}

.modal-gerar-cte .gerar-cte .container-input-checkbox .checkbox {
  cursor: pointer;
}

.modal-gerar-cte
  .gerar-cte
  .container-input-checkbox
  .checkbox
  input[type="checkbox"] {
  display: none;
}

.modal-gerar-cte
  .gerar-cte
  .container-input-checkbox
  .checkbox
  input[type="checkbox"]
  + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.modal-gerar-cte
  .gerar-cte
  .container-input-checkbox
  .checkbox
  input[type="checkbox"]:checked
  + span.checked {
  background: #001b48 -19px top no-repeat;
}

.modal-gerar-cte
  .gerar-cte
  .container-input-checkbox
  .checkbox
  input[type="checkbox"]
  + span.checked {
  margin-right: 10px;
}

.modal-gerar-cte .gerar-cte .input-radio-gerar-cte {
  display: flex;
  background-color: #fff;
  padding: 8.3px 10px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-gerar-cte .gerar-cte .input-radio-gerar-cte {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-gerar-cte .gerar-cte .input-radio-gerar-cte .container-input-radio {
  position: relative;
}

.modal-gerar-cte .gerar-cte .input-radio-gerar-cte .container-input-radio {
  user-select: none;
}

.modal-gerar-cte
  .gerar-cte
  .input-radio-gerar-cte
  .container-input-radio
  .radio {
  cursor: pointer;
}

.modal-gerar-cte
  .gerar-cte
  .input-radio-gerar-cte
  .container-input-radio
  .radio.checked {
  color: #3d8af7;
}

.modal-gerar-cte
  .gerar-cte
  .input-radio-gerar-cte
  .container-input-radio
  .radio
  input[type="radio"] {
  display: none;
}

.modal-gerar-cte
  .gerar-cte
  .input-radio-gerar-cte
  .container-input-radio
  .radio
  input[type="radio"]
  + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.modal-gerar-cte
  .gerar-cte
  .input-radio-gerar-cte
  .container-input-radio
  .radio
  input[type="radio"]:checked
  + span.checked {
  background: #3d8af7 -19px top no-repeat;
}

.modal-gerar-cte
  .gerar-cte
  .input-radio-gerar-cte
  .container-input-radio
  .radio
  input[type="radio"]
  + span.checked {
  margin-right: 5px;
}

.modal-gerar-cte .gerar-cte .container-notas-fiscais {
  margin: 20px 0;
  padding: 5px 5px 10px 5px;
  max-height: 53vh;
  overflow-y: auto;
}

.modal-gerar-cte .gerar-cte .container-notas-fiscais::-webkit-scrollbar-thumb {
  background: #3eedf7 !important;
}

.modal-gerar-cte
  .gerar-cte
  .container-notas-fiscais::-webkit-scrollbar-thumb:hover {
  background: #3eeef7a8 !important;
  border-radius: 10px;
}

.modal-gerar-cte .gerar-cte .titulo-opcoes-componentes {
  display: flex;
  justify-content: space-between;
}

.modal-gerar-cte .gerar-cte .titulo-opcoes-componentes .container-input-comum {
  flex: 0.7;
  align-self: flex-end;
  margin-bottom: 15px;
}

.modal-gerar-cte
  .gerar-cte
  .titulo-opcoes-componentes
  .container-embutir-total {
  margin-bottom: 15px;
}

.modal-gerar-cte
  .gerar-cte
  .titulo-opcoes-componentes
  .container-embutir-total
  p {
  color: #001b48;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
}

.modal-gerar-cte .gerar-cte .input-radio-gerar-cte.azul {
  background-color: #d6e8ee;
}

.modal-gerar-cte
  .gerar-cte
  .input-radio-gerar-cte.azul
  .container-input-radio:first-of-type {
  margin-right: 20px;
}

.modal-gerar-cte .gerar-cte .container-forma-pagamento p {
  margin-top: -15px;
  font-size: 12px;
  font-weight: bold;
  color: #001b48;
}

.modal-gerar-cte .gerar-cte .container-botoes {
  margin-top: 30px;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.modal-gerar-cte .gerar-cte .container-botoes .btn {
  margin: 0 10px;
  padding: 8px 40px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
}

.modal-gerar-cte .gerar-cte .container-botoes .btn:not(.btn:disabled):hover {
  filter: brightness(120%);
}

.modal-gerar-cte .gerar-cte .container-botoes .btn.cancelar {
  background-color: #99b2c6;
}

.modal-gerar-cte .gerar-cte .container-botoes .btn.salvar {
  background-color: #018abe;
}

.modal-gerar-cte .gerar-cte .container-botoes .btn.enviar {
  background-color: #02457a;
}

.modal-gerar-cte .gerar-cte .container-botoes .btn:disabled {
  opacity: 0.6;
  cursor: default;
}

.errors-modal-body {
  width: 500px;
}
.errors-modal-part {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}
.errors-modal-part > div:not(:last-child) {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  padding: 8px;
}
.errors-modal-part > div h3 {
  padding-bottom: 8px;
}
.errors-modal-part > div p {
  padding: 4px 0;
}

@media (max-width: 1100px) {
  .modal-gerar-cte .gerar-cte .container-inputs-6col {
    grid-template-columns: repeat(3, 1fr);
  }

  .modal-gerar-cte .gerar-cte .container-inputs-6col.larga {
    grid-template-columns: 2fr 1fr;
  }
}

@media (max-width: 985px) {
  .modal-gerar-cte .gerar-cte .container-botoes .btn {
    padding: 8px 15px;
    font-size: 14px;
  }
}

@media (max-width: 920px) {
  .modal-gerar-cte .gerar-cte .input-radio-gerar-cte {
    flex-direction: column;
    justify-content: left;
  }

  .modal-gerar-cte .gerar-cte .input-radio-gerar-cte .container-input-radio {
    width: 100%;
  }

  .modal-gerar-cte
    .gerar-cte
    .input-radio-gerar-cte
    .container-input-radio:last-of-type {
    margin-top: 5px;
  }

  .modal-gerar-cte
    .gerar-cte
    .input-radio-gerar-cte.azul
    .container-input-radio:first-of-type {
    margin-right: 0;
  }

  .modal-gerar-cte .gerar-cte .container-inputs-3col.prestacao {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 850px) {
  .modal-gerar-cte .gerar-cte .container-inputs-3col {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 775px) {
  .modal-gerar-cte .gerar-cte .input-radio-gerar-cte.azul {
    width: 190px;
  }
}

@media (max-width: 720px) {
  .modal-gerar-cte .gerar-cte .container-inputs-4col {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 710px) {
  .modal-gerar-cte .gerar-cte .container-botoes {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .modal-gerar-cte .gerar-cte .container-botoes .btn {
    width: 250px;
    margin-top: 10px;
  }
}

@media (max-width: 630px) {
  .modal-gerar-cte .gerar-cte .titulo-opcoes-componentes {
    flex-direction: column;
  }

  .modal-gerar-cte
    .gerar-cte
    .titulo-opcoes-componentes
    .container-embutir-total {
    margin-top: 20px;
  }

  .modal-gerar-cte .gerar-cte .container-inputs-6col {
    grid-template-columns: repeat(2, 1fr);
  }

  .modal-gerar-cte .gerar-cte .container-inputs-3col.prestacao,
  .modal-gerar-cte .gerar-cte .container-inputs-2col {
    grid-template-columns: 1fr;
  }

  .modal-gerar-cte .gerar-cte .container-inputs-2col {
    grid-row-gap: 20px;
  }
}

@media (max-width: 420px) {
  .modal-gerar-cte .gerar-cte .container-inputs-6col,
  .modal-gerar-cte .gerar-cte .container-inputs-6col.larga,
  .modal-gerar-cte .gerar-cte .container-inputs-4col,
  .modal-gerar-cte .gerar-cte .container-inputs-3col,
  .modal-gerar-cte .gerar-cte .container-inputs-2col {
    grid-template-columns: 1fr;
  }
}
