.formulario-nfe-cte {
  position: relative;
  margin-top: 20px;
  background-color: #f1f4fa;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 15px;
  border-radius: 8px;
  opacity: 0.6;
}

.formulario-nfe-cte.vinculada {
  opacity: 1;
}

.formulario-nfe-cte .titulo-formulario {
  font-weight: bold;
  color: #001b48;
}

.formulario-nfe-cte .titulo-formulario.categoria {
  margin-top: 20px;
}

.formulario-nfe-cte .container-titulo {
  display: flex;
  align-items: center;
}

.formulario-nfe-cte .container-acoes {
  display: flex;
  margin-left: 40px;
}

.formulario-nfe-cte .container-acoes img {
  height: 27px;
}

.formulario-nfe-cte .container-acoes img:not(.container-acoes img:last-of-type) {
  margin-right: 15px;
}

.formulario-nfe-cte .container-acoes img:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.formulario-nfe-cte .status {
  flex: 1;
}

.formulario-nfe-cte .status p {
  font-weight: bold;
  color: #ff0000;
  margin-left: 40px;
}

.formulario-nfe-cte .status p.vinculada {
  color: #008631;
}

.formulario-nfe-cte .container-inputs-4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.formulario-nfe-cte .container-inputs-3col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.formulario-nfe-cte .container-inputs-3col.mini {
  width: 47.5%;
}

.formulario-nfe-cte .container-input-comum {
  position: relative;
}

.formulario-nfe-cte .container-input-comum input {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.formulario-nfe-cte .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.formulario-nfe-cte .container-input-comum input:focus+span,
.formulario-nfe-cte .container-input-comum input:disabled+span,
.formulario-nfe-cte .container-input-comum input:valid+span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.formulario-nfe-cte .container-input-comum input:disabled {
  color: #3d8af7;
}

@media (max-width: 720px) {
  .formulario-nfe-cte .container-inputs-4col,
  .formulario-nfe-cte .container-inputs-3col {
    grid-template-columns: repeat(2, 1fr);
  }

  .formulario-nfe-cte .container-inputs-3col.mini {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .formulario-nfe-cte .container-inputs-4col,
  .formulario-nfe-cte .container-inputs-3col {
    grid-template-columns: 1fr;
  }
}