.modal-emitir-cte-lote {
	.selecionar-notas-box {
		height: 300px;
		overflow: auto;
		
		table {
			width: 100%;
			display: table;
			border-collapse: collapse;
			text-align: center;

			thead {
				tr th {
					padding: 10px 3px;
					color: #fff;
					font-size: 12px;
					font-weight: normal;
					cursor: pointer;
					text-align: center;
					border-right: 1px solid #fff;
					background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
					background-color: #20477e;

					&:first-of-type {
						border-top-left-radius: 5px;
					}
					&:last-of-type {
						border-top-right-radius: 5px;
					}
				}
			}

			tbody {
				tr {
					td {
						padding: 15px;
						font-size: 12px;
						border-right: 1px solid #fff;
						background-color: #d6e8ee;
					}

					&:nth-child(2n) {
						td {
							background-color: #99b2c6;
						}
					}

					&.linha-selecionada {
						td {
							background-color: #20477e;
						}
					}
				}
			}

			.coluna-observacoes {
				min-width: 32%;
			}
		}
	}

	.checked-box,
	.unchecked-box {
		cursor: pointer;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #fff;
		border: 1px solid #9ab2c6;
	}

	.checked-box {
		background: #001b48;
	}

  .container-inputs-4col {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2%;
    grid-row-gap: 20px;
    margin-top: 20px;

    @media (max-width: 630px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 420px) {
      grid-template-columns: 1fr;
    }
  }
  .container-inputs-2col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2%;
    grid-row-gap: 20px;
    margin-top: 20px;
    
    &.nopad {
      margin: 0;
    }

    @media (max-width: 420px) {
      grid-template-columns: 1fr;
    }
  }
  .container-input-comum {
    position: relative;
    
    input,
    select {
      width: 100%;
      background-color: #fff;
      padding: 10px;
      border: none;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    span {
      position: absolute;
      top: 11px;
      left: 15px;
      letter-spacing: 0.05em;
      pointer-events: none;
      transition: 0.4s;
      font-size: 13px;
      color: gray;
    }

    &.cinza {
      input {
        background-color: #9cb6cd;
      }

      span {
        color: #fff;
      }

      input:focus + span,
      input:disabled + span,
      input:valid + span {
        background-color: #9cb6cd;
        color: #fff;
      }
    }
    
    &.vermelho {
      span {
        color: #ff0000;
      }
    }

    input:focus + span,
    input:disabled + span,
    input:valid + span,
    select:focus + span,
    select:disabled + span,
    select:valid + span {
      top: -8px;
      left: 10px;
      font-size: 12px;
      color: #9ab2c6;
      background-color: #fff;
    }

    input:disabled,
    select:disabled {
      color: #3d8af7;
    }
  }

  .titulo-formulario {
    font-weight: bold;
    color: #001b48;
    margin-top: 30px;
  }  

  .input-radio-gerar-cte {
    display: flex;
    background-color: #fff;
    padding: 8.3px 10px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.azul {
      background-color: #d6e8ee;
    }

    .container-input-radio {
      position: relative;
      user-select: none;
  
      input[type="radio"] {
        display: none;
      }
      
      input[type="radio"] + span.checked {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 20px;
        height: 20px;
        margin: -1px 0px 0 0;
        vertical-align: middle;
        background-color: #fff;
        border: 1px solid #9ab2c6;
        border-radius: 50%;
        margin-right: 5px;
      }
  
      input[type="radio"]:checked + span.checked {
        background: #3d8af7 -19px top no-repeat;
      }
    }
  }

  .informacoes-emissao .totals {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
  
    > div {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 10px;
   
      > span {
        display: block;
        font-weight: bold;
      }
    }
  }

  .container-embutir-total {
    margin-bottom: 15px;
    margin-top: -15px;
   
    p {
      color: #001b48;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  .submit-line {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    button {
      margin: 0 10px;
      padding: 8px 40px;
      font-size: 16px;
      color: #fff;
      border: none;
      border-radius: 8px;
      font-weight: bold;
      background-color: #99b2c6;

      &:hover {
        filter: brightness(120%);
      }

      &.btn-salvar {
        background-color: #018abe;
      }
    }
  }
}
