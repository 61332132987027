.container-tabela-listar-transportadoras {
  margin-top: 20px;
  max-width: 100%;
  overflow: auto;
}

.container-tabela-listar-transportadoras table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.container-tabela-listar-transportadoras table thead tr th {
  padding: 10px 0;
  border-bottom: 1px solid #9ab2c6;
  font-size: 16px;
  color: black;
}

.container-tabela-listar-transportadoras table tbody tr.divisoria-linhas-tabela {
  height: 10px;
  background-color: #fff;
}

.container-tabela-listar-transportadoras table tbody tr td {
  background-color: #f1f4fa;
  padding: 15px 10px;
  color: gray;
}

.container-tabela-listar-transportadoras table tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}

.container-tabela-listar-transportadoras table tbody tr td:last-child {
  border-radius: 0px 8px 8px 0px;
}

.container-tabela-listar-transportadoras table .coluna-acoes .icone {
  margin: 0 3px;
  font-size: 22px;
  color: #001b48;
  cursor: pointer;
}

.container-tabela-listar-transportadoras table .coluna-acoes .icone.favorito {
  color: #D4AF37;
}

.container-tabela-listar-transportadoras table .coluna-acoes .icone.motorista {
  color: black;
}

.container-tabela-listar-transportadoras table .coluna-acoes .icone:hover {
  filter: brightness(120%);
}

.alert-remover-transportadora {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-remover-transportadora .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-remover-transportadora button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-remover-transportadora button:hover {
  filter: brightness(120%);
}

.alert-remover-transportadora button.botao-confirmar {
  background-color: #cc3300;
}

.alert-remover-transportadora button.botao-cancelar {
  background-color: #001b48;
}