.overlay-modal-alerta-coins {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10001;
}

.modal-alerta-coins {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  max-height: 90vh;
  background-color: #f7fafc;
  border-radius: 20px;
  overflow: auto;
}

.modal-alerta-coins .alerta-coins .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #3a4959;
  cursor: pointer;
}

.modal-alerta-coins .alerta-coins .icone-fechar:hover {
  filter: brightness(130%);
}

.modal-alerta-coins .alerta-coins {
  padding: 30px 15px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-alerta-coins .alerta-coins .wallet {
  width: 128px;
  height: auto;
  margin-bottom: 40px;
}

.modal-alerta-coins .alerta-coins .title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
  color: #018abe;
}

.modal-alerta-coins .alerta-coins .description {
  font-size: 14px;
  opacity: 0.6;
  text-align: center;
  margin-bottom: 30px;
}
.modal-alerta-coins .alerta-coins .actions {
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}
.modal-alerta-coins .alerta-coins .actions button {
  appearance: none;
  padding: 10px 5px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
}
.modal-alerta-coins .alerta-coins .actions button.detail {
  background: transparent;
  color: #222;
}
.modal-alerta-coins .alerta-coins .actions button.close {
  background: transparent;
  color: #222;
}
.modal-alerta-coins .alerta-coins .actions button.buy {
  background: #018abe;
  color: #FFF;
}
