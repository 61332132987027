.overlay-modal-agendamento {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.100);
  z-index: 1010;
}

.modal-agendamento {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 95vh;
  width: 400px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  overflow: auto;
  z-index: 99;
  overflow-y: hidden;
}

.modal-agendamento .titulo {
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.modal-agendamento .titulo p {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.modal-agendamento .titulo .icone {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.modal-agendamento .titulo .icone:hover {
  filter: brightness(120%);
}

.modal-agendamento .container-cards-agendamentos {
  height: 75vh;
  overflow-y:auto; 
  padding-bottom: 20px;
}

@media (max-width: 580px) {
  .modal-agendamento {
    width: 96%;
  }
}