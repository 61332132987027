.container-novo-grupo-permissoes {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-novo-grupo-permissoes .btn-voltar-pagina {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  font-weight: bold;
  margin-bottom: 20px;
}

.container-novo-grupo-permissoes .btn-voltar-pagina:hover {
  filter: brightness(120%);
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .titulo-pagina {
  font-size: 20px;
  font-weight: bold;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .subtitulo-pagina {
  margin-top: 5px;
  font-weight: bold;
  color: #001b48;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-nome-grupo-permissoes {
  margin: 40px 0 20px 0;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-nome-grupo-permissoes p {
  margin-top: 5px;
  font-weight: bold;
  color: #9ab2c6;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-nome-grupo-permissoes .container-input {
  position: relative;
  margin-top: 20px;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-nome-grupo-permissoes .container-input input {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-nome-grupo-permissoes .container-input span {
  position: absolute;
  top: 14px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-nome-grupo-permissoes .container-input span i {
  color: red;
  font-style: normal;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-nome-grupo-permissoes .container-input input:focus + span,
.container-novo-grupo-permissoes .novo-grupo-permissoes .container-nome-grupo-permissoes .container-input input:valid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes {
  display: flex;
  justify-content: space-between;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .quadro {
  flex: 1;
  background-color: #f1f4fa;
  padding: 10px;
  border-radius: 10px;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .quadro .input-search {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 8px;
  margin-bottom: 10px;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .quadro .input-search input {
  flex: 1;
  border: none;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .quadro .input-search input::placeholder {
  color: gray;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .quadro .input-search .icone {
  margin-left: 5px;
  font-size: 20px;
  color: black;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-permissoes {
  overflow-y: auto;
  max-height: 50vh;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-permissoes .permissao {
  margin-top: 10px;
  background-color: #99b2c6;
  border-radius: 8px;
  padding: 6px 3px;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-permissoes .permissao.selecionada {
  background-color: #001b48;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-permissoes .permissao.desabilitada {
  pointer-events: none;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-permissoes .permissao.selecionada p {
  color: #fff;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-permissoes .permissao p {
  color: rgb(82, 82, 82);
  font-style: italic;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-botoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-botoes button {
  width: 180px;
  padding: 5px 0;
  margin-bottom: 10px;
  background-color: #001b48;
  border: 2px solid #001b48;
  border-radius: 8px;
  font-weight: bold;
  color: #fff;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-botoes button:last-child {
  margin-bottom: 0;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-botoes button:hover {
  filter: brightness(120%);
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-botoes {
  display: flex;
  justify-content: center;
  margin: 80px auto 20px auto; 
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-botoes button {
  width: 250px;
  padding: 12px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-botoes button:hover {
  filter: brightness(120%);
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-botoes button.btn-cancelar {
  margin-right: 30px;
}

.container-novo-grupo-permissoes .novo-grupo-permissoes .container-botoes button.btn-cadastrar {
  background-color: #001b48;
  border-color: #001b48;
  color: #fff;
}

@media (max-width: 750px) {
  .container-novo-grupo-permissoes {
    padding: 25px 10px;
    overflow-y: hidden;
  }
  .container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes {
    display: flex;
    flex-direction: column;
  }
  .container-novo-grupo-permissoes .novo-grupo-permissoes .container-quadros-permissoes .container-botoes {
    width: 100%;
    margin: 20px 0;
  }
}

@media (max-width: 470px) {
  .container-novo-grupo-permissoes .novo-grupo-permissoes .container-botoes {
    flex-direction: column;
    margin: 80px auto 10px auto; 
  }
  
  .container-novo-grupo-permissoes .novo-grupo-permissoes .container-botoes button {
    width: 100%;
    margin-bottom: 15px;
  }
}