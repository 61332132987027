.overlay-modal-carta-correcao {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10001;
}

.modal-carta-correcao {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 70%;
  max-height: 90vh;
  background-color: #f7fafc;
  border-radius: 5px;
  overflow: auto;
}

.modal-carta-correcao .carta-correcao .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #3a4959;
  cursor: pointer;
}

.modal-carta-correcao .carta-correcao .icone-fechar:hover {
  filter: brightness(130%);
}

.modal-carta-correcao .carta-correcao {
  padding: 20px 15px;
}

.modal-carta-correcao .carta-correcao .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
  text-align: center;
}

.modal-carta-correcao .carta-correcao .descricao {
  margin: 25px 0;
}

.modal-carta-correcao .carta-correcao .container-formulario {
  display: flex;
  align-items: center;
}

.modal-carta-correcao .carta-correcao .container-formulario .remover-carta {
  height: 20px;
  color: red;
  cursor: pointer;
  margin: 15px 10px 0 0;
}

.modal-carta-correcao .carta-correcao .container-formulario .remover-carta:hover {
  transform: scale(1.05);
}

.modal-carta-correcao .carta-correcao .container-inputs-4col {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3%;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.modal-carta-correcao .carta-correcao .container-input-comum {
  position: relative;
}

.modal-carta-correcao .carta-correcao .container-input-comum input,
.modal-carta-correcao .carta-correcao .container-input-comum select {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-carta-correcao .carta-correcao .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.modal-carta-correcao .carta-correcao .container-input-comum input:focus+span,
.modal-carta-correcao .carta-correcao .container-input-comum input:disabled+span,
.modal-carta-correcao .carta-correcao .container-input-comum input:valid+span,
.modal-carta-correcao .carta-correcao .container-input-comum select:focus+span,
.modal-carta-correcao .carta-correcao .container-input-comum select:disabled+span,
.modal-carta-correcao .carta-correcao .container-input-comum select:valid+span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.modal-carta-correcao .carta-correcao .btn-adicionar {
  margin-top: 15px;
  text-decoration: underline;
  font-weight: bold;
  color: #3d8af7;
  cursor: pointer;
}