.modal-historico-transacoes {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 75vh;
  width: 47%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 30px 30px 30px;
  overflow: auto;
  z-index: 99;
}

.overlay-modal-historico-transacoes {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.840);
  z-index: 10002;
}

.overlay-modal-historico-transacoes .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #011c49;
  text-align: center;
  text-transform:capitalize;
}

.overlay-modal-historico-transacoes .subtitulo {
  font-weight: 500;
  color: #011c49;
  margin: 20px 0 15px 0;
}

.overlay-modal-historico-transacoes .datas {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0 25px 0;
}

.overlay-modal-historico-transacoes .datas .container-input-date {
  width: 180px;
  display: flex;
  align-items: center;
  padding: 10px 5px 10px 20px;
  background-color: #f1f4fa;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.overlay-modal-historico-transacoes .datas .container-input-date input {
  width: 120px;
  margin: 0 3px 0 auto;
  background-color: #f1f4fa;
  border: none;
}

.overlay-modal-historico-transacoes .datas .container-input-date input[type="date"]::-webkit-inner-spin-button,
.overlay-modal-historico-transacoes .datas .container-input-date input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.overlay-modal-historico-transacoes .datas .container-input-date .icone {
  font-size: 18px;
}

.overlay-modal-historico-transacoes .datas .icone-imprimir {
  position: absolute;
  font-size: 40px;
  color: #018abe;
  right: 0;
  bottom: -15px;
  cursor: pointer;
}

.overlay-modal-historico-transacoes .container-tabela-historico {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100%;
  overflow: auto;
}

.overlay-modal-historico-transacoes .container-tabela-historico table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.overlay-modal-historico-transacoes .container-tabela-historico table thead tr th {
  padding: 15px 0;
  color: #9ab2c6;
  background-color: #f1f4fa;
  border: 1px solid #e0ecf1;
}

.overlay-modal-historico-transacoes .container-tabela-historico table tbody tr td {
  background-color: #f1f4fa;
  padding: 15px 0;
  color: #011c49;
  border: 1px solid #e0ecf1;
}

.overlay-modal-historico-transacoes .container-tabela-historico table tbody tr.destaque td {
  background-color: #6ea5f1;
}

.overlay-modal-historico-transacoes .container-tabela-historico table tbody tr td.coluna-coins span {
  margin-right: 5px;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
}

.overlay-modal-historico-transacoes .botao-voltar {
  display: block;
  margin: auto auto 0 auto;
  border: none;
  border-radius: 8px;
  background-color: #018abe;
  padding: 10px 0;
  width: 200px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.overlay-modal-historico-transacoes .botao-voltar:hover {
  filter: brightness(120%);
}


.overlay-modal-historico-transacoes .pagination-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-modal-historico-transacoes .pagination-container .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.overlay-modal-historico-transacoes .pagination-container .pagination>.active>a {
  background-color: #0d78f2;
  color: black;
}

.overlay-modal-historico-transacoes .pagination-container .pagination>li>a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

.overlay-modal-historico-transacoes .pagination-container .pagination>.active>a,
.overlay-modal-historico-transacoes .pagination-container .pagination>.active>span,
.overlay-modal-historico-transacoes .pagination-container .pagination>.active>a:hover,
.overlay-modal-historico-transacoes .pagination-container .pagination>.active>span:hover,
.overlay-modal-historico-transacoes .pagination-container .pagination>.active>a:focus,
.overlay-modal-historico-transacoes .pagination-container .pagination>.active>span:focus {
  background-color: #001b48;
  color: #fff;
  border-color: #fff;
  outline: none;
}

.overlay-modal-historico-transacoes .pagination-container .pagination>li>a,
.overlay-modal-historico-transacoes .pagination-container .pagination>li>span {
  background-color: #9ab2c6;
  color: black;
}

.overlay-modal-historico-transacoes .pagination-container .pagination>li>a:hover {
  filter: brightness(120%);
}

.overlay-modal-historico-transacoes .pagination-container .pagination>li:first-child>a,
.overlay-modal-historico-transacoes .pagination-container .pagination>li:first-child>span,
.overlay-modal-historico-transacoes .pagination-container .pagination>li:last-child>a,
.overlay-modal-historico-transacoes .pagination-container .pagination>li:last-child>span {
  border-radius: 5px
}

@media (max-width: 800px) {
  .modal-historico-transacoes {
    width: 96%;
  }
}