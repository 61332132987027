.tabela-rota {
  width: 100%;

  thead {
    th {
      color: #fff;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      background: #3c89f5;
      padding: 10px 3px;
      border-right: 1px solid #fff;

      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 10%;
      }
    }
  }

  tbody {
    td {
      text-align: left;
      padding: 5px;

      .uf_municipio {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 5px;

        .origem, .destino {
          display: flex;
          align-items: center;
          gap: 5px;

          .title {
            margin-top: 10px;
          }
        }

        .uf, .municipio {
          flex: 1;
        }
      }
    }
  }
}