.overlay-modal-perfil-motorista {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10001;
}

.modal-perfil-motorista {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 92vh;
  width: 95%;
  background-color: rgba(18, 19, 20, 0.400);
  overflow: auto;
  z-index: 99;
}

.modal-perfil-motorista .detalhes-motorista {
  min-width: 340px;
  margin-right: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 1px 2px 8px 0px;
}

.modal-perfil-motorista .todas-informacoes {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  overflow-y: auto;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo {
  position: relative;
  height: 150px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
  border-radius: 10px 10px 0 0;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .container-favorito {
  position: relative;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .container-favorito .background {
  position: absolute;
  top: 10px;
  left: 18px;
  background-color: #ffd700;
  height: 20px;
  width: 20px;
  z-index: 1;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .icone-estrela {
  position: relative;
  font-size: 45px;
  cursor: pointer;
  color: #000000be;
  margin-left: 5px;
  z-index: 2;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .icone-estrela:hover {
  color: #000000;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .rating {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  pointer-events: none;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .rating .icone {
  font-size: 25px;
  margin: 0 4px;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .rating .icone.vazio {
  color: #fff;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .rating .icone.preenchido {
  color: #fac917;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .icone-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  color: #31435c;
  cursor: pointer;
}

.modal-perfil-motorista .detalhes-motorista .quadro-topo .icone-fechar:hover {
  filter: brightness(120%);
}

.modal-perfil-motorista .detalhes-motorista .foto-id {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -75px;
}

.modal-perfil-motorista .detalhes-motorista .foto-id img {
  height: 130px;
  width: 130px;
  object-fit: cover;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.modal-perfil-motorista .detalhes-motorista .foto-id .telefones {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 5px;
  left: 5px;
}

.modal-perfil-motorista .detalhes-motorista .foto-id .telefones div {
  display: flex;
}

.modal-perfil-motorista .detalhes-motorista .foto-id .telefones div:last-of-type {
  margin-top: 10px;
}

.modal-perfil-motorista .detalhes-motorista .foto-id .telefones div .icone {
  margin-right: 5px;
  color: #3d8af7;
}
.modal-perfil-motorista .detalhes-motorista .foto-id .telefones div p {
  font-size: 12px;
}

.modal-perfil-motorista .detalhes-motorista .foto-id .id {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 30px;
  right: 5px;
}

.modal-perfil-motorista .detalhes-motorista .foto-id .id .icone {
  margin-right: 5px;
  font-size: 18px;
  color: #3d8af7;
}

.modal-perfil-motorista .detalhes-motorista .foto-id .id p {
  font-size: 12px;
}

.modal-perfil-motorista .detalhes-motorista .foto-id .id p i {
  font-weight: bold;
  font-style: normal;
}

.modal-perfil-motorista .detalhes-motorista .dados {
  text-align: center;
  margin-top: 20px;
}

.modal-perfil-motorista .detalhes-motorista .dados .nome {
  font-size: 22px;
  font-weight: bold;
}

.modal-perfil-motorista .detalhes-motorista .dados .cpf {
  margin-top: 8px;
  font-size: 12px;
}

.modal-perfil-motorista .detalhes-motorista .veiculo {
  margin: 15px auto;
  padding: 15px 0;
  border-top: 1px solid #ced9e5;
  max-width: 50%;
}

.modal-perfil-motorista .detalhes-motorista .veiculo p {
  font-size: 12px;
  text-align: center;
}
.modal-perfil-motorista .detalhes-motorista .veiculo p span {
  font-weight: bold;
}

.modal-perfil-motorista .detalhes-motorista .placas {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #ced9e5;
  padding-bottom: 20px;
}

.modal-perfil-motorista .detalhes-motorista .placas div {
  border: 2px solid #3d8af7;
  padding: 5px 15px;
  border-radius: 3px;
  font-weight: bold;
}

.modal-perfil-motorista .detalhes-motorista .linha {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.modal-perfil-motorista .detalhes-motorista .linha .icone {
  font-size: 22px;
  margin-right: 10px;
  color: #3d8af7;
}

.modal-perfil-motorista .detalhes-motorista .linha p span {
  font-weight: bold;
}

.modal-perfil-motorista .detalhes-motorista .botoes  {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.modal-perfil-motorista .detalhes-motorista .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 22px;
  border: none;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 8px;
}

.modal-perfil-motorista .detalhes-motorista .botoes button:hover {
  filter: brightness(120%)
}

.modal-perfil-motorista .detalhes-motorista .botoes button .icone {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.modal-perfil-motorista .detalhes-motorista .botoes button.recusar {
  background-color: red;
  border-radius: 3px 0 0 3px;
}

.modal-perfil-motorista .detalhes-motorista .botoes button.aceitar {
  background-color: #85bf64;
  border-radius: 0 3px 3px 0;
}

.modal-perfil-motorista .todas-informacoes .container-abas {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  background-color: #99b2c6;
  border-radius: 8px;
}

.modal-perfil-motorista .todas-informacoes .container-abas .aba {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 15px 0;
  cursor: pointer;
  border-radius: 8px;
}

.modal-perfil-motorista .todas-informacoes .container-abas .aba.selecionada {
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.modal-perfil-motorista .todas-informacoes .container-abas .aba.selecionada p {
  color: #fff;
}

.modal-perfil-motorista .todas-informacoes .container-abas .aba.selecionada .icone {
  color: #fff;
}

.modal-perfil-motorista .todas-informacoes .container-abas .aba .icone {
  font-size: 20px;
  margin-right: 5px;
  color: #172b4d;
}

.modal-perfil-motorista .todas-informacoes .container-abas .aba p {
  font-weight: bold;
  font-size: 18px;
  color: #172b4d;
}

.container-alert-proposta {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  border: 2px solid #3eeef7a8;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  animation-duration: 0.1s;
}

.container-alert-proposta .titulo {
  font-weight: bold;
  margin-bottom: 20px;
}

.container-alert-proposta .titulo span.reprovar {
  color: red;
}

.container-alert-proposta .titulo span.aprovar {
  color: #85bf64;
}

.container-alert-proposta .motorista {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.container-alert-proposta .motorista img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 8px;
}

.container-alert-proposta .motorista p {
  font-weight: bold;
  margin: 0 5px;
}

.container-alert-proposta  .container-input-checkbox-consulta {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.container-alert-proposta  .container-input-checkbox-consulta input {
  margin-right: 5px;
  cursor: pointer;
  -ms-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
  padding: 10px;
}

.container-alert-proposta .botoes {
  display: flex;
  justify-content: center;
}

.container-alert-proposta .botoes button {
  border: none;
  width: 50px;
  padding: 5px 0;
  font-weight: bold;
  color: #fff;
}

.container-alert-proposta .botoes button:hover {
  filter: brightness(120%);
}

.container-alert-proposta .botoes button.cancelar {
  background-color: red;
  border-radius: 3px 0 0 3px;
}

.container-alert-proposta .botoes button.confirmar {
  background-color: #85bf64;
  border-radius: 0 3px 3px 0;
}

@media (max-width: 980px) {
  .modal-perfil-motorista {
    width: 98%;
  }
  .modal-perfil-motorista .detalhes-motorista {
    margin-right: 5px;
  }
}

@media (max-width: 600px) {
  .overlay-modal-perfil-motorista {
    overflow-y: scroll;
  }
  .modal-perfil-motorista {
    flex-direction: column;
    justify-content: space-between;
    top: 0; 
    left: 0; 
    transform: none;
    height: auto;
  }
  .modal-perfil-motorista .detalhes-motorista {
    margin: 0 0 10px 0;
    padding-bottom: 20px;
  }
}
