.container-loading-login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #001b48;
}

.container-loading-login h1 {
  color: #fff;
  font-size: 45px;
  margin-bottom: 40px;
}