.radio-container {
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
    padding-left: 30px;
  }

  input[type=radio] {
    appearance: none;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid #9ab2c6;
      border-radius: 50%;
      transition: background 0.3s ease;
    }

    &:checked {
      &::after {
        background-color: #001b48;
      }
    }
  }

  /*
.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio {
  cursor: pointer;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio input[type="radio"] {
  display: none;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio input[type="radio"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio input[type="radio"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.modal-nova-carga .container-nova-carga .input-radio .container-radio .container-input-radio .radio input[type="radio"] + span.checked {
  margin-right: 5px;
}
*/
}
