.container-agenda-cargas {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-agenda-cargas .container-filtros-agenda {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.container-agenda-cargas .container-filtros-agenda .input-filtro {
  width: 255px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f1f4fa;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.container-agenda-cargas .container-filtros-agenda .input-filtro input {
  margin: 0 3px 0 10px;
  background-color: #f1f4fa;
  border: none;
}

.container-agenda-cargas .container-status-agenda {
  width: 90%;
  margin: 0 auto 20px auto;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat( auto-fit, minmax(205px, 1fr) );
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox {
  position: relative;
  margin: 5px 0;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox {
  font-weight: bold;
  user-select: none;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox {
  display: flex;
  cursor: pointer;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox input[type="checkbox"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  margin-right: 3px;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label {
  display: flex;
  align-items: center;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label .cor {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  border-radius: 50%;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label .cor.todos {
  background-color: #f1f4fa;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label .cor.programado {
  background-color: #99b2c6;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label .cor.portaria {
  background-color: #018abe;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label .cor.embarcando{
  background-color: #fec63d;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label .cor.embarcado {
  background-color: #001b48;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label .cor.inicio-viagem {
  background-color: #3eedf7;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label .cor.entrega-prevista {
  background-color: #3a5d28;
}

.container-agenda-cargas .container-status-agenda .container-input-checkbox .checkbox .label .cor.entregue {
  background-color: #92d36e;
}

.container-agenda-cargas .container-filtros-agenda .input-filtro .icone-filtro {
  font-size: 18px;
}

.container-agenda-cargas .container-filtros-agenda .input-filtro .icone-limpar {
  font-size: 22px;
  color: #f1f4fa;
  background-color: #ced9e5;
  border-radius: 50%;
  cursor: pointer;
}

.container-agenda-cargas .container-filtros-agenda .input-filtro .icone-limpar:hover {
  filter: brightness(105%);
}

.container-agenda-cargas .fc-header-toolbar.fc-toolbar  .fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

.container-agenda-cargas .fc-header-toolbar.fc-toolbar  .fc-toolbar-chunk .fc-toolbar-title {
  font-size: 18px;
}

.container-agenda-cargas .fc-icone-button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: default !important;
}

.container-agenda-cargas .fc-icone-button:hover {
  background-color: transparent;
}

.container-agenda-cargas .fc-dayOrWeek-button {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px;
  border-color: #99b2c6;
  color: #333;
  font-weight: bold;
  border-radius: 10px;
  padding: 5px 20px;
}

.container-agenda-cargas .fc-prev-button, .container-agenda-cargas .fc-next-button {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px;
  border-color: #99b2c6;
  color: #333;
  font-weight: bold;
  border-radius: 10px;
  padding: 4.5px 5px;
}

.container-agenda-cargas .fc-dayOrWeek-button:hover,
.container-agenda-cargas .fc-prev-button:hover, 
.container-agenda-cargas .fc-next-button:hover {
  background-color: initial;
  color: #333;
  border-color: #001b48;
}

.container-agenda-cargas .fc-dayOrWeek-button:focus,
.container-agenda-cargas .fc-prev-button:focus, 
.container-agenda-cargas .fc-next-button:focus {
  box-shadow: none;
}

.container-agenda-cargas .icone-calendario {
  font-size: 30px;
  color: #386fdd;
}

.container-agenda-cargas .slot-hour {
  color: #001b48;
}

.container-agenda-cargas .hour-day {
  font-weight: bold;
  color: #001b48;
}

.container-agenda-cargas .indicator-hour {
  font-size: 10px;
  font-weight: bold;
  color: #c69416;
}

.container-agenda-cargas .fc-timegrid-now-indicator-line.indicator-hour {
  border-color: #c69416;
  color: transparent;
}

.container-agenda-cargas .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c69416;
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.container-agenda-cargas .fc-col-header  {
  background-color: #d6e8ee;
}

.container-agenda-cargas .container-titulo-dia {
  background-color: #d6e8ee;
  width: 100%;
}

.container-agenda-cargas .container-titulo-dia .dia-mes {
  font-size: 10px;
  font-weight: normal;
  color: #001b48;
}

.container-agenda-cargas .container-titulo-dia .dia-semana {
  text-transform: uppercase;
  font-weight: bold;
  color: #001b48;
}

.container-agenda-cargas .container-titulo-dia .eventos {
  display: flex;
}

.container-agenda-cargas .container-titulo-dia .eventos .evento {
  margin: 0 2px;
  font-size: 10px;
  padding: 0px 3px;
  border-radius: 3px;
  color: #fff;
}

.container-agenda-cargas .container-titulo-dia .eventos .evento.embarcado {
  background-color: #084d67;
}

.container-agenda-cargas .container-titulo-dia .eventos .evento.inicio-viagem {
  background-color: #3eedf7;
}

.container-agenda-cargas .container-titulo-dia .eventos .evento.entrega-prevista {
  background-color: #3a5d28;
}

.container-agenda-cargas .container-titulo-dia .eventos .evento.embarcando {
  background-color: #fcc540;
}

.container-agenda-cargas .container-titulo-dia .eventos .evento.programado {
  background-color: #99b2c6;
}

.container-agenda-cargas .container-titulo-dia .eventos .evento.entregue {
  background-color: #92d36e;
}

.container-agenda-cargas .container-titulo-dia .eventos .evento.portaria {
  background-color: #018abe;
}

.container-agenda-cargas .evento {
  padding: 0;
  background-color: transparent;
  border: none;
}

.container-agenda-cargas .container-evento {
  /* height: 100%; */
  width: 100%;
  margin: 0;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
}

.container-agenda-cargas .fc-timeGridDay-view .container-evento {
  width: 50%;
  margin: 0 auto;
}

.container-agenda-cargas .container-evento:hover {
  filter: brightness(110%);
}

.container-agenda-cargas .container-evento .titulo {
  flex: 1;
  text-align: center;
}

.container-agenda-cargas .container-evento .quantidade {
  background-color: #fff;
  padding: 1px 4px;
  border-radius: 3px;
}

.container-agenda-cargas .container-evento .quantidade p {
  font-size: 10px;
  font-weight: bold;
  color: #333;
}

.container-agenda-cargas .container-evento.embarcado {
  background-color: #084d67;
}

.container-agenda-cargas .container-evento.inicio-viagem {
  background-color: #3eedf7;
}

.container-agenda-cargas .container-evento.entrega-prevista {
  background-color: #3a5d28;
}

.container-agenda-cargas .container-evento.embarcando {
  background-color: #fcc540;
}

.container-agenda-cargas .container-evento.programado {
  background-color: #99b2c6;
}

.container-agenda-cargas .container-evento.entregue {
  background-color: #92d36e;
}

.container-agenda-cargas .container-evento.portaria {
  background-color: #018abe;
}

@media (max-width: 450px) {
.container-agenda-cargas {
    padding: 25px 10px;
  }
}