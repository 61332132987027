.container-historico-transacoes {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.historico-transacoes {
  display: flex;
  gap: 30px
}

.historico-transacoes .quadro-historico {
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
  padding: 15px 25px;
  border-radius: 5px;
}

.historico-transacoes .quadro-historico .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #011c49;
  text-align: center;
}

.historico-transacoes .quadro-historico .subtitulo {
  font-weight: 500;
  color: #011c49;
  margin: 20px 0 15px 0;
}

.historico-transacoes .quadro-historico .datas {
  display: flex;
  justify-content: space-evenly;
}

.historico-transacoes .quadro-historico .datas .container-input-date {
  width: 180px;
  display: flex;
  align-items: center;
  padding: 10px 5px 10px 20px;
  background-color: #f1f4fa;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.historico-transacoes .quadro-historico .datas .container-input-date input {
  width: 120px;
  margin: 0 3px 0 auto;
  background-color: #f1f4fa;
  border: none;
}

.historico-transacoes .quadro-historico .datas .container-input-date input[type="date"]::-webkit-inner-spin-button,
.historico-transacoes .quadro-historico .datas .container-input-date input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.historico-transacoes .quadro-historico .datas .container-input-date .icone {
  font-size: 18px;
}

.historico-transacoes .quadro-historico .separador {
  border: 1px solid #d9e2e8;
  background-color: #d9e2e8;
  margin: 20px 0 0 0;
}

.historico-transacoes .quadro-historico .container-tabela-historico {
  margin-top: 20px;
  max-width: 100%;
  max-height: 664px;
  overflow-y: scroll
}

.historico-transacoes .quadro-historico .container-tabela-historico table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.historico-transacoes .quadro-historico .container-tabela-historico table thead tr th {
  padding: 10px 0;
  border-top: 1px solid #d9e2e8;
  font-size: 16px;
  color: #001b48;
}

.historico-transacoes .quadro-historico .container-tabela-historico table tbody tr.divisoria-linhas-tabela {
  height: 5px;
  background-color: #fff;
}

.historico-transacoes .quadro-historico .container-tabela-historico table tbody tr td {
  background-color: #f1f4fa;
  padding: 20px 10px;
  font-weight: bold;
  color: #018abe;
}

.historico-transacoes .quadro-historico .container-tabela-historico table tbody tr td.coluna-coins span {
  margin-right: 5px;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
}

.historico-transacoes .quadro-historico .container-tabela-historico table tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}

.historico-transacoes .quadro-historico .container-tabela-historico table tbody tr td:last-child {
  border-radius: 0px 8px 8px 0px;
}

.historico-transacoes .quadro-historico .botao-detalhes {
  display: block;
  margin: 20px auto 0 auto;
  border: none;
  border-radius: 8px;
  background-color: #018abe;
  padding: 10px 0;
  width: 200px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.historico-transacoes .quadro-historico .botao-detalhes:hover {
  filter: brightness(120%);
}