.tabela-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  overflow: auto;

  .tabela-rastro {
    width: 100%;
    max-width: 100%;
    display: table;
    border-collapse: collapse;
    white-space: nowrap;
    text-align: center;

    thead {
      tr {
        th {
          padding: 10px 0;
          border-bottom: 1px solid #9ab2c6;
          font-size: 16px;
          color: black;
        }
      }
    }

    tbody {
      tr {
        &.divisoria-linhas-tabela {
          height: 10px;
          background-color: #fff;
        }

        td {
          background-color: #f1f4fa;
          padding: 15px 10px;
          color: gray;

          &:first-child {
            border-radius: 8px 0 0 8px;
          }

          &:last-child {
            border-radius: 0px 8px 8px 0px;
          }
        }
      }
    }

    .coluna-acoes {
      .icone {
        margin: 0 3px;
        font-size: 22px;
        color: #001b48;
        cursor: pointer;

        &:hover {
          filter: brightness(120%);
        }
      }
    }

    .action-link {
      color: #000;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
