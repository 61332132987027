.menuLateral {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.menuLateral .pro-sidebar-layout {
  min-height: 100vh;
}

.menuLateral .header {
  border-bottom: none !important;
  padding: 20px 10px;
}

.menuLateral .header div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuLateral .header div .container-logo {
  flex: 1;
}

.menuLateral .header div .container-logo img {
  height: 60px;
  width: 70px;
}

.menuLateral .header div .icone {
  font-size: 26px;
  color: #99b2c6;
  cursor: pointer;
}

.menuLateral .conteudo .menu-dashboard {
  padding: 0;
}

.menuLateral .conteudo .menu-dashboard .item-menu-dashboard {
  margin: 5px 10px;
  border-radius: 8px;
  background-color: #001b48;
  color: #fff;
}

.menuLateral .conteudo .menu-dashboard .item-menu-dashboard:hover {
  text-decoration: underline;
}

.menuLateral .conteudo .menu-dashboard .item-menu-dashboard a {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.menuLateral .conteudo .icone, .menuLateral .rodape .icone {
  font-size: 26px;
}

.menuLateral .conteudo .titulo, .menuLateral .rodape .titulo {
  font-size: 16px;
  font-weight: bold;
  padding-left: 30px;
  margin: 10px 0 5px 0;
}

.menuLateral .conteudo .menu-opcoes {
  padding: 0;
}

.menuLateral .conteudo .menu-opcoes.principal .submenu-opcoes {
  border-radius: 8px;
  padding-top: 8px;
}

.menuLateral .conteudo .menu-opcoes.principal .submenu-opcoes,
.menuLateral .conteudo .menu-opcoes.principal .submenu-opcoes.open,
.menuLateral .conteudo .menu-opcoes.principal .submenu-opcoes .pro-inner-item {
  background-color: #001b48;
  color: #fff;
}

.menuLateral .conteudo .menu-opcoes.principal .submenu-opcoes.cadastro {
  margin: 0;
  border-radius: 0;
}

.menuLateral .conteudo .menu-opcoes.principal  .submenu-opcoes .pro-inner-list-item {
  padding-left: 0;
  margin-left: 24px;
}

.menuLateral .conteudo .menu-opcoes .submenu-opcoes {
  font-weight: bold;
  margin: 5px 10px;
  padding-bottom: 5px;
  border-radius: 8px;
}

.menuLateral .conteudo .menu-opcoes .submenu-opcoes .pro-inner-item, 
.menuLateral .rodape .menu-opcoes .item-menu-rodape .pro-inner-item {
  padding: 0px 10px 0px 20px;
}

.menuLateral .conteudo .menu-opcoes .submenu-opcoes .pro-inner-item:hover,
.menuLateral .rodape .menu-opcoes .item-menu-rodape .pro-inner-item:hover {
  text-decoration: underline;
}

.menuLateral .conteudo .menu-opcoes .submenu-opcoes .pro-inner-item .pro-arrow-wrapper {
  display: none;
}

.menuLateral .conteudo .menu-opcoes .submenu-opcoes .pro-inner-list-item ul {
  padding: 0;
}

.menuLateral .conteudo .menu-opcoes .submenu-opcoes .pro-inner-list-item .pro-inner-item {
  padding-left: 0;
}

.menuLateral .conteudo .menu-opcoes .submenu-opcoes .icone.embarcador {
  transform: scaleX(-1);
}

.menuLateral .conteudo .menu-opcoes .submenu-opcoes.open {
  background-color: #99b2c6;
  color: #fff;
}

.menuLateral .conteudo .menu-opcoes .submenu-opcoes.open a {
  color: #fff;
}

.menuLateral .rodape {
  border-top: none !important;
}

.menuLateral .rodape .menu-opcoes {
  padding-top: 0;
  margin-left: 5px;
  font-weight: bold;
}

.menuLateral .rodape .menu-opcoes .item-menu-rodape {
  margin-top: 5px;
}

.menuLateral .rodape .icone.exit {
  margin-left: 6px;
}

.menuLateral.collapsed .header div .container-logo img {
  height: 50px;
  width: 55px;
}

.menuLateral.collapsed .conteudo .menu-dashboard .item-menu-dashboard {
  margin: 0;
  border-radius: 8px;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  color: #99b2c6;
}

.menuLateral.collapsed .conteudo .menu-opcoes.principal .submenu-opcoes,
.menuLateral.collapsed .conteudo .menu-opcoes.principal .submenu-opcoes .pro-inner-item {
  margin: 0;
  border-radius: 8px;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  color: #99b2c6;
}

.menuLateral.collapsed .conteudo .titulo, .menuLateral.collapsed .rodape .titulo {
  display: none;
}

.menuLateral.collapsed .conteudo .submenu-opcoes, .menuLateral.collapsed .rodape .menu-opcoes {
  margin: 0;
}

.menuLateral.collapsed .conteudo .submenu-opcoes .pro-inner-item .pro-item-content,
.menuLateral.collapsed .rodape .menu-opcoes .pro-inner-item .pro-item-content {
  display: none;
}

.menuLateral.collapsed .conteudo .submenu-opcoes .popper-element {
  display: none !important;
}

.menuLateral.collapsed .rodape .menu-opcoes {
  margin: 0;
}

@media (max-width: 880px) {
  .menuLateral {
    min-height: 100vh;
    position: fixed;
  }
  .menuLateral.collapsed {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}