.overlay-rastro-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10001;
  
  .rastro-modal {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    width: 70%;
    max-height: 90vh;
    background-color: #f7fafc;
    border-radius: 5px;
    overflow: auto;

    &:not(.large) {
      max-width: 800px;
    }

    padding: 20px 15px;

    .close-button {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 28px;
      color: #3a4959;
      cursor: pointer;

      appearance: none;
      border: transparent;
      background: transparent;
      
      &:hover {
        filter: brightness(130%);
      }
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      color: #001b48;
      text-align: center;
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}
