.formulario-primeiro-acesso .titulo-formulario {
  background-color: #001b48;
  padding: 10px 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.formulario-primeiro-acesso .conteudo-formulario {
  padding: 30px 25px;
}

.formulario-primeiro-acesso .conteudo-formulario p {
  color: #001b48;
}

.formulario-primeiro-acesso .conteudo-formulario label {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.formulario-primeiro-acesso .conteudo-formulario label input {
  margin-top: 5px;
  padding: 15px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.formulario-primeiro-acesso .conteudo-formulario label input.erro {
  border-color: #ff0000;
}

.formulario-primeiro-acesso .conteudo-formulario .msg-erro-senhas {
  font-size: 10px;
  font-weight: bold;
  color: #ff0000;
}

.formulario-primeiro-acesso .conteudo-formulario button {
  display: block;
  width: 100%;
  margin: 25px auto 15px auto;
  padding: 14px 0;
  background-color: #001b48;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.formulario-primeiro-acesso .conteudo-formulario button:hover {
  filter: brightness(120%);
}

.alert-primeiro-acesso {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-primeiro-acesso .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-primeiro-acesso button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #001b48;
}