.container-transportadoras-favoritas {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-transportadoras-favoritas .btn-voltar-pagina {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  font-weight: bold;
  margin-bottom: 20px;
}

.container-transportadoras-favoritas .btn-voltar-pagina:hover {
  filter: brightness(120%);
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-topo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-topo .titulo-pagina {
  font-size: 20px;
  font-weight: bold;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-topo .subtitulo-pagina {
  margin-top: 5px;
  font-weight: bold;
  color: #001b48;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 50px 0;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-search .input-search {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 12px 10px 12px 10px;
  background-color: #f1f4fa;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-search .input-search.embarcador {
  margin-right: 25px;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-search .input-search input {
  flex: 1;
  border: none;
  background-color: #f1f4fa;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-search .input-search input::placeholder {
  color: gray;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-search .input-search .icone {
  margin-left: 5px;
  font-size: 22px;
  color: #9ab2c6;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-search .btn-buscar {
  width: 120px;
  background-color: #001b48;
  margin-left: 25px;
  height: 48px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-search .btn-buscar:hover {
  filter: brightness(120%);
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora {
  margin-top: 20px;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>.active>a {
  background-color: #0d78f2;
  color: black;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>li>a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>.active>a,
.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>.active>span,
.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>.active>a:hover,
.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>.active>span:hover,
.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>.active>a:focus,
.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>.active>span:focus {
  background-color: #001b48;
  color: #fff;
  border-color: #fff;
  outline: none;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>li>a,
.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>li>span {
  background-color: #9ab2c6;
  color: black;
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>li>a:hover {
  filter: brightness(120%);
}

.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>li:first-child>a,
.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>li:first-child>span,
.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>li:last-child>a,
.container-transportadoras-favoritas .transportadoras-favoritas .container-paginacao-transportadora .pagination>li:last-child>span {
  border-radius: 5px
}

@media (max-width: 670px) {
  .container-transportadoras-favoritas {
    padding: 25px 10px;
    overflow-y: hidden;
  }

  .container-transportadoras-favoritas .transportadoras-favoritas .container-topo {
    flex-direction: column;
    text-align: center;
  }

  .container-transportadoras-favoritas .transportadoras-favoritas .container-topo .btn-nova-transportadora {
    width: 100%;
    justify-content: center;
    margin-top: 15px;
  }

  .container-transportadoras-favoritas .transportadoras-favoritas .container-search {
    flex-direction: column;
    width: 100%;
  }

  .container-transportadoras-favoritas .transportadoras-favoritas .container-search .input-search {
    width: 100%;
  }

  .container-transportadoras-favoritas .transportadoras-favoritas .container-search .input-search.embarcador {
    margin: 0 0 10px 0;
  }

  .container-transportadoras-favoritas .transportadoras-favoritas .container-search .btn-buscar {
    width: 100%;
    margin: 10px 0 0 0;
  }
}