.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.btn.smallAction {
  background-color: #9ab2c6;
  color: #000;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
  }
}

.btn.ghost {
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;

  &:disabled {
    pointer-events: none;
  }
}

.btn.save {
  width: 250px;
  padding: 12px 0;
  border: 2px solid #001b48;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  background-color: #001b48;
  color: #fff;

  &.small {
    width: auto;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: unset;
  }

  &:hover {
    filter: brightness(120%);
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.btn.saveBack {
  width: 250px;
  padding: 12px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #000;

  &.small {
    width: auto;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: unset;
  }

  &:hover {
    filter: brightness(120%);
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.btn.detail {
  display: flex;
  align-items: center;
  margin: 0 0 5px auto;
  background-color: #3c89f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 5px 5px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  gap: 10px;

  &:hover {
    filter: brightness(110%);
  }
}