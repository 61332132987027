.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .titulo {
  background-color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards::-webkit-scrollbar-thumb {
  background: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .cards::-webkit-scrollbar-thumb:hover {
  background: #3d8bf79f;
  border-radius: 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div {
  text-align: center;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes p:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.total {
  flex: 0.3;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.total,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.carregamento,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.status {
  border-right: 1px solid #99b2c6;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.total p span {
  color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.status,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.carregamento {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.status div,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.carregamento  {
  padding: 0;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.status div:first-of-type,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.carregamento div:first-of-type {
  border-bottom: 1px solid #99b2c6;
  width: 100%;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.status div:first-of-type p span {
  color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.status div:last-of-type p span {
  color: #fec63d;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.carregamento div:first-of-type p span {
  color: #3eedf7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.carregamento div:last-of-type p span {
  color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.embarque .detalhes div.icones {
  flex: 0.1;
}