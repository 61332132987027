.modal-perfil-motorista-carga-tracking .dados-pedido {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.modal-perfil-motorista-carga-tracking .dados-pedido .progresso {
  flex: 0.15;
  height: 60px;
}

.modal-perfil-motorista-carga-tracking .dados-pedido .progresso .CircularProgressbar-text {
  font-size: 30px;
  font-weight: bold;
  fill: #333;
}

.modal-perfil-motorista-carga-tracking .dados-pedido .progresso .CircularProgressbar-path {
  stroke: #008282;
}

.modal-perfil-motorista-carga-tracking .dados-pedido .informacoes div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-perfil-motorista-carga-tracking .dados-pedido .informacoes div .icone {
  font-size: 15px;
  color: #3c89f5;
  margin-right: 5px;
}

.modal-perfil-motorista-carga-tracking .dados-pedido .informacoes div p span,
.modal-perfil-motorista-carga-tracking .dados-pedido .informacoes .pedido span {
  font-weight: bold;
}

.modal-perfil-motorista-carga-tracking .dados-pedido .informacoes .pedido {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

.modal-perfil-motorista-carga-tracking .dados-pedido .informacoes .message-app {
  color: #FF0000;
  margin-top: 15px;
}

.modal-perfil-motorista-carga-tracking .separador {
  margin: 0 auto;
  width: 92%;
  background-color: #ced9e5;
  height: 0.5px;
  border: none;
}

.modal-perfil-motorista-carga-tracking .mapa-tracking {
  padding: 20px 4%;
}

.modal-perfil-motorista-carga-tracking .mapa-tracking .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
  margin-bottom: 20px;
  text-align: center;
}

.modal-perfil-motorista-carga-tracking .mapa-tracking .container-mapa .mapa {
  height: 30vh;
  width: 100%;
}

.modal-perfil-motorista-carga-tracking .eventos-carga {
  padding: 20px 4%;
}

.modal-perfil-motorista-carga-tracking .eventos-carga .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
}

@media (max-width: 980px) {
  .modal-perfil-motorista-carga-tracking .mapa-tracking {
    padding: 10px;
  }
}