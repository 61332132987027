.sem-calculadora {
  flex: 1;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 10px 0px;
  padding: 15px 30px;
}

.sem-calculadora .subtitulo {
  margin: 15px 0;
  font-weight: 500;
  color: #001b48;
}

.sem-calculadora .quadro-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3eedf7;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.sem-calculadora .quadro-total p {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-right: 10px;
}

.sem-calculadora .quadro-total .input-quantidade {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
}

.sem-calculadora .quadro-total .input-quantidade input {
  width: 80%;
  margin: 0 3px 0 10px;
  background-color: #fff;
  border: none;
}

.sem-calculadora .quadro-total .input-quantidade input::placeholder {
  font-size: 12px;
}

.sem-calculadora .btn-comprar {
  display: block;
  margin: 100px auto 0 auto;
  align-items: center;
  background-color: #018abe;
  border: none;
  border-radius: 8px;
  padding: 10px 40px;
}

.sem-calculadora .btn-comprar:hover {
  filter: brightness(120%);
}

.sem-calculadora .btn-comprar div {
  display: flex;
  align-items: center;
}

.sem-calculadora .btn-comprar div .icone {
  color: #fff;
  font-size: 22px;
  margin-right: 10px;
}

.sem-calculadora .btn-comprar div p {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}