.overlay-modal-proposta-carga {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.100);
  z-index: 10000;
}

.modal-proposta-carga {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 95vh;
  width: 500px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  overflow: auto;
  z-index: 99;
  overflow-y: hidden;
}

.modal-proposta-carga .titulo {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.modal-proposta-carga .titulo p {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.modal-proposta-carga .titulo .icone {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.modal-proposta-carga .titulo .icone:hover {
  filter: brightness(120%);
}

.modal-proposta-carga .informacoes-carga {
  padding: 10px 15px;
  border-bottom: 1px solid #99b2c6;
}

.modal-proposta-carga .informacoes-carga .linha {
  display: flex;
  justify-content: space-between;
}

.modal-proposta-carga .informacoes-carga .linha .valor {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.modal-proposta-carga .informacoes-carga .linha .valor div {
  background-color: #3eedf7;
  border-radius: 50%;
  padding: 2px;
  margin-right: 3px;
}

.modal-proposta-carga .informacoes-carga .linha .valor div p {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}

.modal-proposta-carga .informacoes-carga .linha .pedido-id {
  flex: 1;
  width: 100%;
  padding-right: 30px;
  font-size: 12px;
  text-align: center;
}

.modal-proposta-carga .informacoes-carga .linha .pedido-id span {
  font-weight: bold;
}

.modal-proposta-carga .informacoes-carga .linha .pedido-id .id {
  font-size: 10px;
}

.modal-proposta-carga .informacoes-carga .linha .motoristas .icone {
  font-size: 20px;
  color: #3eedf7;
}

.modal-proposta-carga .informacoes-carga .linha .motoristas span {
  color: #92d36e;
  font-size: 12px;
  font-weight: bold;
}

.modal-proposta-carga .informacoes-carga .linha.cidades {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  font-size: 12px;
}

.modal-proposta-carga .informacoes-carga .linha .embarque,
.modal-proposta-carga .informacoes-carga .linha .entrega {
  display: flex;
  font-size: 12px;
}

.modal-proposta-carga .informacoes-carga .linha .embarque .icone,
.modal-proposta-carga .informacoes-carga .linha .entrega .icone {
  font-size: 14px;
  color: #3eedf7;
  margin-right: 2px;
}

.modal-proposta-carga .informacoes-carga .linha .embarque p span,
.modal-proposta-carga .informacoes-carga .linha .entrega p span {
  font-weight: bold;
}

.modal-proposta-carga .container-cards-motoristas {
  height: 75vh;
  overflow-y:auto; 
  padding-bottom: 20px;
}

.modal-proposta-carga .container-cards-motoristas .msg-sem-candidatos {
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.modal-proposta-carga .container-cards-motoristas::-webkit-scrollbar {
  width: 12px;
}

.modal-proposta-carga .container-cards-motoristas::-webkit-scrollbar-track {
  background: #fff;
  border-left: 1px solid #99b2c6;
}

.modal-proposta-carga .container-cards-motoristas::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.modal-proposta-carga .container-cards-motoristas::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, 70%, #00000071);
  background-color: #3eeef7a8;
}

.modal-proposta-carga .container-cards-motoristas::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(transparent, 70%, #000000a6);
  background-color: #3eeef7a8;
}

@media (max-width: 580px) {
  .modal-proposta-carga {
    width: 96%;
  }
}