.modal-video-transacoes {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 58%;
    background-color: #fff;
    border-radius: 5px;
    overflow: auto;
    z-index: 99;
}

.modal-video-body iframe {
    display: block;
    margin: 15px auto;
}

.modal-video-body .btn-voltar-pagina {
    padding: 3px 8px;
    border-radius: 5px;
    background-color: #f1f4fa;
    border: 2px solid #9ab2c6;
    font-weight: bold;
    margin: 10px 0 0px 15px;
}

.modal-video-body .btn-voltar-pagina:hover {
    filter: brightness(120%);
}