.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .titulo {
  background-color: #172b4d;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards::-webkit-scrollbar-thumb {
  background: #172b4d;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards::-webkit-scrollbar-thumb:hover {
  background: #172b4d9c;
  border-radius: 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div {
  text-align: center;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes p:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div.total,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div.prazo,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div.atraso,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div.atrasado {
  border-right: 1px solid #99b2c6;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div.total p span {
  color: #3d8af7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div.prazo p span {
  color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div.atraso p span {
  color: #fec63d;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div.atrasado p span {
  color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .detalhes div.icones {
  flex: 0.5;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha .icone {
  color: #333;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro.em-transito .cards .card .linha-dados-motorista .foto-nome .icone {
  color: #333 !important;
}