.container-novo-embarcador {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-novo-embarcador .btn-voltar-pagina {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  font-weight: bold;
  margin-bottom: 20px;
}

.container-novo-embarcador .btn-voltar-pagina:hover {
  filter: brightness(120%);
}

.container-novo-embarcador .novo-embarcador .titulo-pagina {
  font-size: 20px;
  font-weight: bold;
}

.container-novo-embarcador .novo-embarcador .subtitulo-pagina {
  margin-top: 5px;
  font-weight: bold;
  color: #001b48;
}

.container-novo-embarcador .novo-embarcador .categoria {
  margin-top: 30px;
}

.container-novo-embarcador .novo-embarcador .categoria .titulo-categoria {
  font-size: 16px;
  font-weight: bold;
  color: #9ab2c6;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input {
  position: relative;
  margin-top: 20px;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input input,
.container-novo-embarcador .novo-embarcador .categoria .container-input select,
.container-novo-embarcador .novo-embarcador .categoria .container-input textarea {
  width: 100%;
  background-color: #f1f4fa;
  padding: 12px 10px;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input.file input {
  padding: 9px 10px;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input input[type="date"]:invalid::-webkit-datetime-edit,
.container-novo-embarcador .novo-embarcador .categoria .container-input input[type="date"]:disabled::-webkit-datetime-edit {
  color: transparent;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input input[type="date"] + span {
  top: 16px;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input select {
  padding: 11.6px 10px;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input textarea {
  resize: none;
  border-radius: 20px;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input span {
  position: absolute;
  top: 14px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input span i {
  color: red;
  font-style: normal;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input input:focus + span,
.container-novo-embarcador .novo-embarcador .categoria .container-input input:disabled + span,
.container-novo-embarcador .novo-embarcador .categoria .container-input select:focus + span,
.container-novo-embarcador .novo-embarcador .categoria .container-input select:disabled + span,
.container-novo-embarcador .novo-embarcador .categoria .container-input textarea:focus + span,
.container-novo-embarcador .novo-embarcador .categoria .container-input textarea:disabled + span,
.container-novo-embarcador .novo-embarcador .categoria .container-input input:valid + span,
.container-novo-embarcador .novo-embarcador .categoria .container-input select:valid + span,
.container-novo-embarcador .novo-embarcador .categoria .container-input textarea:valid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input .input-rede-social {
  top: 14px;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input .input-rede-social .icone {
  margin-top: -3px;
  font-size: 18px;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input .input-rede-social .icone.instagram {
  color: rgba(254, 95, 94, 1)
}

.container-novo-embarcador .novo-embarcador .categoria .container-input .input-rede-social .icone.facebook {
  color: #475993;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input .input-rede-social .icone.linkedin {
  color: #0077b5;
  border-radius: 50%;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input input:focus + span.input-rede-social,
.container-novo-embarcador .novo-embarcador .categoria .container-input input:valid + span.input-rede-social {
  width: auto;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input input:focus + span.input-rede-social .icone,
.container-novo-embarcador .novo-embarcador .categoria .container-input input:valid + span.input-rede-social .icone {
  margin-left: 5px;
  font-size: 12px;
}

.container-novo-embarcador .novo-embarcador .categoria .container-inputs-2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
}

.container-novo-embarcador .novo-embarcador .categoria .container-inputs-1col {
  width: 45%;
}

.container-novo-embarcador .novo-embarcador .categoria .container-inputs-1col-expandida {
  width: 100%;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input-checkbox {
  position: relative;
  margin-top: 20px;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input-checkbox {
  font-weight: bold;
  user-select: none;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input-checkbox .checkbox {
  cursor: pointer;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input-checkbox .checkbox input[type="checkbox"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.container-novo-embarcador .novo-embarcador .categoria .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  margin-right: 10px;
}

.container-novo-embarcador .novo-embarcador .container-botoes {
  display: flex;
  justify-content: center;
  margin: 80px auto 20px auto; 
}

.container-novo-embarcador .novo-embarcador .container-botoes button {
  width: 250px;
  padding: 12px 0;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

.container-novo-embarcador .novo-embarcador .container-botoes button:hover {
  filter: brightness(120%);
}

.container-novo-embarcador .novo-embarcador .container-botoes button.btn-cancelar {
  margin-right: 30px;
}

.container-novo-embarcador .novo-embarcador .container-botoes button.btn-cadastrar {
  background-color: #001b48;
  border-color: #001b48;
  color: #fff;
}

.container-novo-embarcador .novo-embarcador .msg-erro {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

@media (max-width: 670px) {
  .container-novo-embarcador  {
    padding: 25px 10px;
  }
  .container-novo-embarcador .novo-embarcador .categoria .container-input .input-rede-social {
    width: 92%;
  }
  .container-novo-embarcador .novo-embarcador .categoria .container-inputs-2col {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
  .container-novo-embarcador .novo-embarcador .categoria .container-inputs-1col {
    width: 100%;
  }
}

@media (max-width: 470px) {
  .container-novo-embarcador .novo-embarcador .container-botoes {
    flex-direction: column;
    margin: 80px auto 10px auto; 
  }
  
  .container-novo-embarcador .novo-embarcador .container-botoes button {
    width: 100%;
    margin-bottom: 15px;
  }
}