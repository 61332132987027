.alert-network-modal {
    z-index: 10003 !important;
    background: none;
}

.alert-network-modal .react-confirm-alert {
    position: fixed;
    bottom: 10px;
    left: 10px;
}

.alert-network-modal .custom-modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 0;
}

.alert-network-modal .custom-modal-close {
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
    color: #0069d9 !important;
}

.alert-network-modal .custom-modal-close:hover {
    color: #0069d978;
    ;
}

.alert-network-modal .custom-modal-title {
    font-size: 16px;
    font-weight: bold;
    color: #172b4d;
    margin: 0;
}

.alert-network-modal .custom-modal {
    background-color: #f1f4fa;;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    max-width: 360px;
    position: fixed;
    bottom: 20px;
    left: 20px;
}

.alert-network-modal .custom-modal-content {
    margin: 15px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;   
    column-gap: 20px;
}

.alert-network-modal .custom-modal-content svg {
    font-size: 25px;
}

.alert-network-modal .custom-modal-icon-off svg{
    color: #ff0000;
}

.alert-network-modal .custom-modal-icon-on svg{
    color: #00af21;
}

.alert-network-modal .custom-modal-content p {
    margin-top: 3px;
}

.alert-network-modal .custom-modal-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin-left: 10px;
    cursor: pointer;
}

.alert-network-modal .custom-modal-button:hover {
    background-color: #0069d9;
}