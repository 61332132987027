.overlay-modal-historico-negociacoes {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.800);
  z-index: 10002;
}

.modal-historico-negociacoes {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 85vh;
  width: 500px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  overflow: auto;
  z-index: 99;
  overflow-y: hidden;
}

.modal-historico-negociacoes .titulo {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.modal-historico-negociacoes .titulo p {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.modal-historico-negociacoes .titulo .icone {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.modal-historico-negociacoes .titulo .icone:hover {
  filter: brightness(120%);
}

.modal-historico-negociacoes .conteudo {
  padding: 10px 20px;
}

.modal-historico-negociacoes .conteudo .proposta-inicial {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 5px 20px;
  width: 70%;
  border-radius: 20px;
  background-color: #f8f8f8;
  color: #707c8f;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.modal-historico-negociacoes .conteudo .proposta-inicial .valor {
  display: flex;
  align-items: center;
  flex: 0.8;
  font-size: 12px;
  margin-left: 5px;
}

.modal-historico-negociacoes .conteudo .proposta-inicial .valor div {
  background-color: #3eedf7;
  border-radius: 50%;
  padding: 2px;
  margin-right: 3px;
}

.modal-historico-negociacoes .conteudo .proposta-inicial .valor div p {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}

.modal-historico-negociacoes .conteudo .proposta-inicial .valor .real {
  display: flex;
  flex-direction: column;
}

.modal-historico-negociacoes .conteudo .titulo-tabela {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 5px 0;
  padding: 0 20px;
}

.modal-historico-negociacoes .conteudo .titulo-tabela p {
  text-align: center;
  color: #707c8f;
  width: 110px;
}

.modal-historico-negociacoes .conteudo .cards-negociacoes {
  max-height: 58vh;
  overflow: auto;
  padding-bottom: 10px;
}

.modal-historico-negociacoes .conteudo .cards-negociacoes .card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  background-color: #f7fafc;
  border: 3px solid #f7fafc;
  border-radius: 45px;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.modal-historico-negociacoes .conteudo .cards-negociacoes .card.aprovada {
  border-color: #3eedf7;
}

.modal-historico-negociacoes .conteudo .cards-negociacoes .card .img-nome,
.modal-historico-negociacoes .conteudo .cards-negociacoes .card p:not(.img-nome p) {
  width: 110px;
}

.modal-historico-negociacoes .conteudo .cards-negociacoes .card .img-nome {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-historico-negociacoes .conteudo .cards-negociacoes .card .img-nome img  {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.modal-historico-negociacoes .conteudo .cards-negociacoes .card .img-nome p {
  width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modal-historico-negociacoes .conteudo .cards-negociacoes .card p {
  text-align: center;
}

.modal-historico-negociacoes .conteudo .cards-negociacoes .card p.valor-fechado {
  color: #a8d2e1;
}

.modal-historico-negociacoes .conteudo .btn-voltar {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 15px;
  width: 150px;
  padding: 12px 0;
  background-color: #99b2c6;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.modal-historico-negociacoes .conteudo .btn-voltar:hover {
  filter: brightness(120%);
}

@media (max-width: 580px) {
  .modal-historico-negociacoes {
    width: 96%;
  }
}