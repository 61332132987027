.container-breadcrumbs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #9ab2c6;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.container-breadcrumbs .container-breads {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
}

.container-breadcrumbs .container-breads .breadcrumb {
  margin-right: 5%;
  position: relative;
}

.container-breadcrumbs .container-breads .breadcrumb a {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.container-breadcrumbs .container-breads .breadcrumb a:hover {
  color: #99b2c6;
}

.container-breadcrumbs .container-breads .breadcrumb a.breadcrumb-ativo {
  color: #001b48;
}

.container-breadcrumbs .container-breads .breadcrumb a.breadcrumb-ativo::after {
  position: absolute;
  content: '';
  height: 4px;
  bottom: -8px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: #001b48;
  border-radius: 10px;
}

.container-breadcrumbs .outras-navegacoes {
  display: flex;
  align-items: center;
  gap: 20px;
}

.container-breadcrumbs .outras-navegacoes .nome-transportadora {
  font-weight: bold;
}

.container-breadcrumbs .outras-navegacoes .usuario-logado {
  color: #018abe;
  font-weight: bold;
}

.container-breadcrumbs .outras-navegacoes .botao-rastro-coins {
  border: none;
  background-color: #018abe;
  padding: 5px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.container-breadcrumbs .outras-navegacoes .botao-rastro-coins:hover {
  filter: brightness(120%);
}

.container-breadcrumbs .outras-navegacoes .container-notificacoes a {
  display: flex;
  position: relative;
  color: inherit;
}

.container-breadcrumbs .outras-navegacoes .container-notificacoes:hover {
  color: #99b2c6;
  cursor: pointer;
}


.container-breadcrumbs .outras-navegacoes .container-notificacoes a .icone {
  font-size: 30px;
}

.container-breadcrumbs .outras-navegacoes .container-notificacoes a .quantidade {
  position: absolute;
  bottom: 0;
  right: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}