.container-painel-monitoramento {
  min-height: 96vh;
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-painel-monitoramento .painel-monitoramento .container-filtros {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.116) 0px 3px 8px;
}

.container-painel-monitoramento .painel-monitoramento .container-filtros .input-filtro {
  width: 255px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f1f4fa;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.container-painel-monitoramento .painel-monitoramento .container-filtros .input-filtro input {
  flex: 1;
  margin: 0 3px 0 10px;
  background-color: #f1f4fa;
  border: none;
}

.container-painel-monitoramento .painel-monitoramento .container-filtros .input-filtro input[type="date"]::-webkit-inner-spin-button,
.container-painel-monitoramento .painel-monitoramento .container-filtros .input-filtro input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.container-painel-monitoramento .painel-monitoramento .container-filtros .input-filtro .icone-filtro {
  font-size: 18px;
}

.container-painel-monitoramento .painel-monitoramento .container-filtros .input-filtro .icone-limpar {
  font-size: 22px;
  color: #f1f4fa;
  background-color: #ced9e5;
  border-radius: 50%;
  cursor: pointer;
}

.container-painel-monitoramento .painel-monitoramento .container-filtros .input-filtro .icone-limpar:hover {
  filter: brightness(105%);
}

.container-painel-monitoramento .painel-monitoramento .btn-filtro-avancado {
  width: 255px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #99b2c6;
  border: none;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.container-painel-monitoramento .painel-monitoramento .btn-filtro-avancado:hover {
  filter: brightness(120%);
}

.container-painel-monitoramento .painel-monitoramento .btn-filtro-avancado .icone {
  font-size: 18px;
}

.container-painel-monitoramento .painel-monitoramento .btn-filtro-avancado p {
  flex: 1;
  text-align: center;
}

.container-painel-monitoramento .painel-monitoramento .container-status {
  width: 70%;
  margin: 15px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox {
  position: relative;
  margin: 5px;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox {
  font-weight: bold;
  user-select: none;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox {
  display: flex;
  cursor: pointer;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox input[type="checkbox"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  margin-right: 3px;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox .label {
  display: flex;
  align-items: center;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox .label .cor {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  border-radius: 50%;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox .label .cor.todos {
  background-color: #99b2c6;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox .label .cor.prazo,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero .cor.negociacao,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero .cor.prazo {
  background-color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox .label .cor.pendencia,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero .cor.pendencia {
  background-color: #fec63d;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox .label .cor.ocorrencia,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero .cor.ocorrencia {
  background-color: #c58b00;
}

.container-painel-monitoramento .painel-monitoramento .container-status .container-input-checkbox .checkbox .label .cor.atrasado,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero .cor.sem-negociacao,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero .cor.atrasado {
  background-color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(314px, 1fr) );
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro {
  min-height: 59vh;
  width: 420px;
  max-width: 100%;
  overflow: auto;
  border: 1px solid #99b2c6;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .titulo {
  border-radius: 20px 20px 0 0;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .titulo p {
  text-align: center;
  font-weight: bold;
  color: #fff;
  padding: 12px 0;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .detalhes {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #99b2c6;
  min-height: 45px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .detalhes div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .detalhes div p {
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .detalhes div.icones {
  flex: 0.5;
  justify-content: space-evenly;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .detalhes div.icones .icone {
  font-size: 22px;
  cursor: pointer;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .detalhes div.icones .icone:hover {
  filter: brightness(120%);
  transform: scale(1.1);
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .detalhes div.icones .icone.adicionar {
  color: green;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards {
  max-height: 778px;
  overflow-y: auto;
  min-height: 250px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards::-webkit-scrollbar {
  width: 8px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards::-webkit-scrollbar-track {
  background: #fff;
  border-left: 1px solid #99b2c6;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card {
  position: relative;
  margin: 8px 5px;
  background-color: #f1f4fa;
  border: 1px solid #99b2c6;
  border-radius: 10px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card.fracionado {
  /*background-color: rgba(119, 173, 249, 0.5);*/
  background: repeating-linear-gradient(
    45deg,
    rgba(119, 173, 249, 0.4),
    rgba(119, 173, 249, 0.4) 10px,
    rgba(119, 173, 249, 0.3) 10px,
    rgba(119, 173, 249, 0.3) 20px
  );
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card.ocorrencia {
  border-color: #c58b00;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card.ocorrencia .icone-ocorrencia {
  position: absolute;
  right: 35%;
  color: #c58b00;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha {
  display: flex;
  justify-content: space-between;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha:not(.card .linha:last-of-type) {
  margin-bottom: 6px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .data {
  display: flex;
  align-items: flex-start;
  font-size: 10px;
  color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .data .icone {
  color: #fec63d;
  margin: 2px 2px 0 0;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido {
  text-align: center;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero {
  display: flex;
  align-items: center;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero .cor {
  height: 12px;
  width: 12px;
  margin-right: 2px;
  border-radius: 50%;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero p {
  font-size: 12px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .cor-numero p span {
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .pedido .id {
  font-size: 10px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .candidatos {
  position: relative;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .candidatos .icone {
  color: #3eedf7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .candidatos p {
  position: absolute;
  font-weight: bold;
  top: 10px;
  right: -6px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .candidatos p.sem-candidatos {
  color: #ef2d41;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .candidatos p.com-candidatos {
  color: #92d36e;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .transportadora {
  display: flex;
  align-items: flex-start;
  font-size: 10px;
  width: 95px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .transportadora .icone {
  margin: 2px 1px 0 0;
  color: #3eedf7;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .embarque,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .entrega {
  display: flex;
  font-size: 12px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .embarque .icone,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .entrega .icone {
  color: #3eedf7;
  margin-right: 2px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .embarque p span,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .entrega p span {
  font-weight: bold;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .cidade {
  font-size: 12px;
  width: 210px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .valor {
  display: flex;
  align-items: center;
  flex: 0.8;
  font-size: 12px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .valor div {
  background-color: #3eedf7;
  border-radius: 50%;
  padding: 2px;
  margin-right: 3px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .valor div p {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .valor .real {
  display: flex;
  flex-direction: column;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .caminhao,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .carga {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 12px;
}
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .carga {
  width: 95px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .caminhao .icone,
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .card .linha .carga .icone {
  color: #3eedf7;
  margin: 3px;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .acoes-card {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .acoes-card .icone {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .acoes-card .icone.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .acoes-card .icone:hover {
  transform: scale(1.1);
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .acoes-card .motoristas-icone {
  position: relative;
}

.container-painel-monitoramento .painel-monitoramento .container-quadros .quadro .cards .acoes-card .motoristas-icone p {
  position: absolute;
  right: -8px;
  bottom: 0;
  color: #fff;
}


@media (max-width: 740px) {
  .container-painel-monitoramento .painel-monitoramento .container-filtros {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  .container-painel-monitoramento .painel-monitoramento .container-status {
    width: 70%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
  }
  .container-painel-monitoramento .painel-monitoramento .container-quadros {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 815px) {
  .container-painel-monitoramento .painel-monitoramento .container-quadros {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 450px) {
  .container-painel-monitoramento {
    padding: 25px 10px;
  }
  .container-painel-monitoramento .painel-monitoramento .container-status {
    width: 100%;
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
  }
}