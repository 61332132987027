.container-avaliacoes {
  padding: 20px;
}

.container-avaliacoes .titulo {
  font-weight: bold;
  color: #172b4d;
}

.container-avaliacoes .conteudo {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.container-avaliacoes .conteudo img {
  max-width: 100%;
  max-height: 500px;
}

.container-avaliacoes .quadros {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10%;
}

.container-avaliacoes .quadros .quadro {
  border: 1px solid #99b2c6;
  box-shadow: rgba(0, 0, 0, 0.185) 0px 1px 8px;
  width: 230px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

.container-avaliacoes .quadros .quadro p:first-of-type {
  color: #3d8af7;
  border-bottom: 1px solid #99b2c6;
  padding: 5px 0;
}

.container-avaliacoes .quadros .quadro:not(.quadro.total) p:last-of-type {
  padding: 8px 0;
}

@media (max-width: 980px) {
  .container-avaliacoes .conteudo {
    flex-direction: column;
    align-items: center;
  }
  .container-avaliacoes .conteudo img {
    margin-bottom: 20px;
  }
}