.container-historico-cargas {
  min-height: 96vh;
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-historico-cargas .container-filtros-historico {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.container-historico-cargas .container-filtros-historico .input-filtro {
  width: 255px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f1f4fa;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.container-historico-cargas .container-filtros-historico .input-filtro input {
  margin: 0 3px 0 10px;
  background-color: #f1f4fa;
  border: none;
}

.container-historico-cargas .container-filtros-historico .input-filtro .icone-filtro {
  font-size: 18px;
}

.container-historico-cargas .container-filtros-historico .input-filtro .icone-limpar {
  font-size: 22px;
  color: #f1f4fa;
  background-color: #ced9e5;
  border-radius: 50%;
  cursor: pointer;
}

.container-historico-cargas .container-filtros-historico .input-filtro .icone-limpar:hover {
  filter: brightness(105%);
}

.container-historico-cargas .container-filtros-historico .btn-filtro-avancado {
  width: 255px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #99b2c6;
  border: none;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 3px 8px;
}

.container-historico-cargas .container-filtros-historico .btn-filtro-avancado:hover {
  filter: brightness(120%);
}

.container-historico-cargas .container-filtros-historico .btn-filtro-avancado .icone {
  font-size: 18px;
}

.container-historico-cargas .container-filtros-historico .btn-filtro-avancado p {
  flex: 1;
  text-align: center;
}

.container-historico-cargas .container-status-historico {
  width: 90%;
  margin: 0 auto 20px auto;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat( auto-fit, minmax(205px, 1fr) );
}

.container-historico-cargas .container-status-historico .container-input-checkbox {
  position: relative;
  margin: 5px 0;
}

.container-historico-cargas .container-status-historico .container-input-checkbox {
  font-weight: bold;
  user-select: none;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox {
  display: flex;
  cursor: pointer;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox input[type="checkbox"] {
  display: none;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #9ab2c6;
  border-radius: 50%;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox input[type="checkbox"]:checked + span.checked {
  background: #001b48 -19px top no-repeat;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox input[type="checkbox"] + span.checked {
  margin-right: 3px;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox .label {
  display: flex;
  align-items: center;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox .label .cor {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  border-radius: 50%;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox .label .cor.todos {
  background-color: #99b2c6;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox .label .cor.prazo {
  background-color: #92d36e;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox .label .cor.pendencia {
  background-color: #fec63d;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox .label .cor.ocorrencia{
  background-color: #c58b00;
}

.container-historico-cargas .container-status-historico .container-input-checkbox .checkbox .label .cor.atrasado {
  background-color: #ef2d41;
}

.container-historico-cargas .container-filtros-agenda .input-filtro .icone-filtro {
  font-size: 18px;
}

.container-historico-cargas .container-filtros-agenda .input-filtro .icone-limpar {
  font-size: 22px;
  color: #f1f4fa;
  background-color: #ced9e5;
  border-radius: 50%;
  cursor: pointer;
}

.container-historico-cargas .container-filtros-agenda .input-filtro .icone-limpar:hover {
  filter: brightness(105%);
}