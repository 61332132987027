.calculadora {
  flex: 1;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 10px 0px;
  padding: 15px 30px;
}

.calculadora p {
  color: #001b48;
}

.calculadora .subtitulo {
  margin: 15px 0;
  font-weight: 500;
}

.calculadora .separador {
  border: 1px solid #ced9e5;
  background-color: #ced9e5;
  margin: 15px 0;
}

.calculadora .quadro-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3eedf7;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.calculadora .quadro-total p {
  font-size: 16px;
  font-weight: bold;
  color: #fff
}

.calculadora .quadro-total .input-quantidade {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
}

.calculadora .quadro-total .input-quantidade input {
  width: 80%;
  margin: 0 3px 0 10px;
  background-color: #fff;
  border: none;
}

.calculadora .quadro-total .input-quantidade input::placeholder {
  font-size: 12px;
}

.calculadora .container-total-pagar {
  position: relative;
}

.calculadora .quadro-total.pagar {
  position: relative;
  background-color: #92d36e;
  z-index: 2;
}

.calculadora .quadro-total.pagar .input-quantidade {
  padding: 10px 15px;
}

.calculadora .quadro-total.pagar .input-quantidade input {
  color: #333;
}

.calculadora .container-total-pagar .desconto {
  position: absolute;
  top: 48px;
  right: 0;
  width: 200px;
  padding: 10px 0 5px 0;
  border-radius: 0 0 5px 5px;
  z-index: 1;
  background: rgb(85,210,16);
  background: radial-gradient(circle, rgba(85,210,16,1) 0%, rgba(64,158,12,1) 100%);
}

.calculadora .container-total-pagar .desconto p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.calculadora .btn-comprar {
  display: block;
  margin: 40px auto 0 auto;
  align-items: center;
  background-color: #018abe;
  border: none;
  border-radius: 8px;
  padding: 10px 40px;
}

.calculadora .btn-comprar:hover {
  filter: brightness(120%);
}

.calculadora .btn-comprar div {
  display: flex;
  align-items: center;
}

.calculadora .btn-comprar div .icone {
  color: #fff;
  font-size: 22px;
  margin-right: 10px;
}

.calculadora .btn-comprar div p {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}