.pagamento-cartao {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.pagamento-cartao .titulo {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #001b48;
}

.pagamento-cartao .subtitulo {
  font-weight: 500;
  color: #001b48;
  margin-bottom: 15px;
}

.pagamento-cartao .quadro-totais {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #3eedf7;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.pagamento-cartao .quadro-totais p {
  font-size: 16px;
  font-weight: bold;
  color: #fff
}

.pagamento-cartao .quadro-totais input {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
  text-align: center;
  font-weight: bold;
}

.pagamento-cartao .quadro-totais input::placeholder {
  font-size: 12px;
}

.pagamento-cartao .separador {
  border: 1px solid #ced9e5;
  background-color: #ced9e5;
  margin: 0 0 15px 0;
}

.pagamento-cartao .icone-pagamento {
  margin: 0 auto 15px auto;
  height: 80px;
  width: 80px;
}

.pagamento-cartao .container-botoes {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: auto;
}

.pagamento-cartao .container-botoes button {
  border: none;
  border-radius: 8px;
  background-color: #97cadb;
  padding: 12px 0;
  width: 200px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.pagamento-cartao .container-botoes button.avancar {
  background-color: #018abe;
}

.pagamento-cartao .container-botoes button:hover {
  filter: brightness(120%);
}

.pagamento-cartao .container-input-custom {
  padding: 8px 15px;
  background-color: #f1f4fa;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
}

.pagamento-cartao .container-col-2 {
  display: flex;
  gap: 30px;
}

.pagamento-cartao .container-col-2 .container-input-custom {
  flex: 1;
}

.pagamento-cartao .container-input-custom {
  margin-bottom: 15px;
}

.pagamento-cartao .container-input-custom input, 
.pagamento-cartao .container-input-custom select {
  width: 100%;
  padding: 10px 30px;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
  font-weight: bold;
  color: #678faf;
}

.pagamento-cartao .container-input-custom input::placeholder {
  color: #678faf;
}

.pagamento-cartao .container-botoes {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: auto;
}

.pagamento-cartao .container-botoes button {
  border: none;
  border-radius: 8px;
  background-color: #97cadb;
  padding: 12px 0;
  width: 200px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.pagamento-cartao .container-botoes button.avancar {
  background-color: #018abe;
}

.pagamento-cartao .container-botoes button:hover {
  filter: brightness(120%);
}