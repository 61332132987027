.modal-adicionar-carga {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 95vh;
  width: 800px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  overflow: auto;
  z-index: 99;
}

.overlay-modal-adicionar-carga {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.840);
  z-index: 10002;
}

.icone-adicionar-carga.editar {
  font-size: 22px;
  cursor: pointer;
}

.icone-adicionar-carga.editar:hover {
  transform: scale(1.1);
}

.modal-adicionar-carga .conteudo {
  position: relative;
}

.modal-adicionar-carga .conteudo .is-loading-conteudo {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  
  z-index: 455;

  background: rgba(255, 255, 255, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  font-weight: bold;
}

.modal-adicionar-carga .titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.modal-adicionar-carga .titulo .detalhes {
  flex: 1;
}

.modal-adicionar-carga .titulo .detalhes {
  text-align: center;
}

.modal-adicionar-carga .titulo .detalhes p {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.modal-adicionar-carga .titulo .detalhes p span {
  font-weight: lighter;
}

.modal-adicionar-carga .titulo .detalhes div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.modal-adicionar-carga .titulo .detalhes div p {
  font-size: 12px;
}

.modal-adicionar-carga .titulo .detalhes div .icone.id {
  font-size: 16px;
  color: black;
  margin-right: 5px;
}

.modal-adicionar-carga .titulo .icone.fechar {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.modal-adicionar-carga .titulo .icone.fechar:hover {
  filter: brightness(120%);
}

.modal-adicionar-carga .container-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 10px auto;
}

.modal-adicionar-carga .container-nav .linhas {
  position: absolute;
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);
  display: flex;
  width: 100%;
}

.modal-adicionar-carga .container-nav .linhas hr {
  height: 12px;
  background-color: #f1f4fa;
  border: 1px solid #bacad9;
}

.modal-adicionar-carga .container-nav .linhas hr.primeira,
.modal-adicionar-carga .container-nav .linhas hr.segunda {
  width: 50%;
}

.modal-adicionar-carga .container-nav .linhas hr.primeira.active,
.modal-adicionar-carga .container-nav .linhas hr.segunda.active {
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
  border: none;
}

.modal-adicionar-carga .container-nav .nav-wizard {
  display: flex;
  justify-content: space-between;
  text-align: center;
  pointer-events: none;
}

.modal-adicionar-carga .container-nav .nav-wizard .dot {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #f1f4fa;
  border: 1px solid #bacad9;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.modal-adicionar-carga .container-nav .nav-wizard .dot:hover {
  filter: brightness(120%);
}

.modal-adicionar-carga .container-nav .nav-wizard .dot p {
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
}

.modal-adicionar-carga .container-nav .nav-wizard .dot.active {
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
  border: none;
}

.modal-adicionar-carga .container-nav .nav-wizard .dot.active p {
  color: #fff;
}

.alert-fechar-modal {
  z-index: 10003 !important;
}

.alert-fechar-modal .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-fechar-modal button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-fechar-modal button:hover {
  filter: brightness(120%);
}

.alert-fechar-modal button.botao-confirmar {
  background-color: #cc3300;
}

.alert-fechar-modal button.botao-cancelar {
  background-color: #001b48;
}

@media (max-width: 800px) {
  .modal-adicionar-carga {
    width: 96%;
  }
}