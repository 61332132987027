.modal-perfil-motorista-carga-eventos .dados-pedido {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.modal-perfil-motorista-carga-eventos .dados-pedido .progresso {
  flex: 0.15;
  height: 60px;
}

.modal-perfil-motorista-carga-eventos .dados-pedido .progresso .CircularProgressbar-text {
  font-size: 30px;
  font-weight: bold;
  fill: #333;
}

.modal-perfil-motorista-carga-eventos .dados-pedido .progresso .CircularProgressbar-path {
  stroke: #008282;
}

.modal-perfil-motorista-carga-eventos .dados-pedido .informacoes div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-perfil-motorista-carga-eventos .dados-pedido .informacoes div .icone {
  font-size: 15px;
  color: #3c89f5;
  margin-right: 5px;
}

.modal-perfil-motorista-carga-eventos .dados-pedido .informacoes div p span,
.modal-perfil-motorista-carga-eventos .dados-pedido .informacoes .pedido span {
  font-weight: bold;
}

.modal-perfil-motorista-carga-eventos .dados-pedido .informacoes .pedido {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

.modal-perfil-motorista-carga-eventos .dados-pedido .informacoes .message-app {
  color: #FF0000;
  margin-top: 15px;
}

.modal-perfil-motorista-carga-eventos .separador {
  margin: 0 auto;
  width: 92%;
  background-color: #ced9e5;
  height: 0.5px;
  border: none;
}

.modal-perfil-motorista-carga-eventos .registro-eventos {
  display: flex;
  justify-content: space-between;
  padding: 20px 4%;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
  margin-bottom: 20px;
  text-align: left;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log {
  flex: 1;
  height: 290px;
  overflow-y: auto;
  padding-left: 6px;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs {
  position: relative;
  margin: 0 auto;
  border-left: 2px dashed #333;
  padding: 35px 0;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .origem {
  position: absolute;
  top: 0;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .destino {
  position: absolute;
  bottom: 0;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .origem,
.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .destino {
  display: flex;
  align-items: center;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .origem,
.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .destino {
  font-size: 20px;
  font-weight: bold;
  margin-left: -10px;
  background-color: white;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .origem .icone,
.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .destino .icone {
  margin-right: 5px;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .origem .icone {
  color: #3c89f5;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .destino .icone {
  color: #92d36e;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos {
  display: flex;
  align-items: center;
  margin: 0 0 15px -8px;
  background-color: #fff;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos.Inicio-de-Viagem .marcador-log {
  background-color: #3c89f5;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos.Chegada-Local-de-Embarque .marcador-log {
  background-color: #172b4d;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos.Em-Carregamento .marcador-log {
  background-color: #fcc540;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos.Carregamento-Finalizado .marcador-log {
  background-color: #99b2c6;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos.Viagem-Liberada .marcador-log {
  background-color: #008282;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos.Chegada-ao-Destino .marcador-log {
  background-color: #3eedf7;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos.Entrega-Concluída .marcador-log {
  background-color: #92d36e;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos.Parada .marcador-log {
  background-color: #eebe48;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos.Retomada .marcador-log  {
  background-color: #99edc3;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos .marcador-log {
  border: 1px solid #99b2c6;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-right: 5px;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .container-log .linha-logs .logs-eventos p span {
  font-weight: bold;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .mapa-eventos {
  margin-left: 20px;
  flex: 1;
}

.modal-perfil-motorista-carga-eventos .registro-eventos .mapa-eventos .mapa {
  height: 30vh;
  width: 100%;
}

.icone-marcador-mapa {
  font-size: 20px;
  color: red;
}

.modal-perfil-motorista-carga-eventos .eventos-carga {
  padding: 20px 4%;
}

.modal-perfil-motorista-carga-eventos .eventos-carga .titulo {
  font-size: 16px;
  font-weight: bold;
  color: #001b48;
}

@media (max-width: 980px) {
  .modal-perfil-motorista-carga-eventos .registro-eventos {
    padding: 10px;
  }
}

@media (max-width: 800px) {
  .modal-perfil-motorista-carga-eventos .registro-eventos {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .modal-perfil-motorista-carga-eventos .registro-eventos .container-log,
  .modal-perfil-motorista-carga-eventos .registro-eventos .mapa-eventos {
    width: 100%;
  }
  .modal-perfil-motorista-carga-eventos .registro-eventos .mapa-eventos {
    margin: 30px 0 10px 0;
  }
}