.card-agendamento.portaria .linha button.liberar-entrada {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 0px 10px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.116) 0px 3px 8px;
}

.card-agendamento.portaria .linha button.liberar-entrada {
  background-color: #c58b00;
}

.card-agendamento.portaria .linha button.liberar-entrada:hover {
  filter: brightness(105%);
}

.card-agendamento.portaria .linha button.liberar-entrada .icone {
  margin-right: 3px;
}

.container-alert-liberar-entrada {
  width: 500px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
  animation-duration: 0.1s;
}

.container-alert-liberar-entrada .titulo {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.container-alert-liberar-entrada .titulo p {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.container-alert-liberar-entrada .titulo .icone.fechar {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.container-alert-liberar-entrada .titulo .icone.fechar:hover {
  filter: brightness(120%);
}

.container-alert-liberar-entrada .conteudo {
  padding: 20px;
}

.container-alert-liberar-entrada .conteudo .pergunta {
  font-size: 16px;
  font-weight: bold;
}

.container-alert-liberar-entrada .conteudo .detalhes-entrada {
  display: flex;
}

.container-alert-liberar-entrada .container-botoes-liberar {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.container-alert-liberar-entrada .container-botoes-liberar button {
  width: 100px;
  padding: 8px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.container-alert-liberar-entrada .container-botoes-liberar button.negar {
  background-color: #ef2d41;
}

.container-alert-liberar-entrada .container-botoes-liberar button.liberar {
  background-color: #92d36e;
}


@media (max-width: 500px) {
  .container-alert-liberar-entrada {
    width: 95%;
    margin: 0 auto;
  }
  .container-alert-liberar-entrada .conteudo {
    padding: 20px 5px;
  }
}