.container-pagamentos {
  padding: 20px;
}

.container-pagamentos .titulo {
  font-weight: bold;
  color: #172b4d;
  margin-bottom: 30px;
}

.container-pagamentos .container-informacoes-pagamento:not(.container-informacoes-pagamento:last-of-type) {
  margin-bottom: 70px;
}

.container-pagamentos .container-informacoes-pagamento .titulo {
  display: flex;
  align-items: center;
}

.container-pagamentos .container-informacoes-pagamento .titulo img {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.container-pagamentos .container-informacoes-pagamento .col-4 {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-column-gap: 4%;
  grid-row-gap: 15px;
  margin-top: 20px;
}

.container-pagamentos .container-informacoes-pagamento .col-4 label {
  font-size: 12px;
  color: gray;
}

.container-pagamentos .container-informacoes-pagamento .col-4 label input,
.container-pagamentos .container-informacoes-pagamento .col-4 label select {
  width: 100%;
  margin-top: 2px;
  background-color: #f9fbfd;
  border: none;
  padding: 10px 5px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
}
.container-pagamentos .container-informacoes-pagamento .col-4 label .banco.guep-editable {
  border: none !important;
}

.container-pagamentos .container-informacoes-pagamento .col-4 label .banco > div {
  width: 100%;
  margin-top: 2px;
  background-color: #f9fbfd;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
}
.container-pagamentos .container-informacoes-pagamento .col-4 label .banco.guep-editable > div {
  border: 1px dashed #222 !important;
}

@media (max-width: 890px) {
  .container-pagamentos .container-informacoes-pagamento .col-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 790px) {
  .container-pagamentos .container-informacoes-pagamento .col-4 {
    grid-template-columns: repeat(1, 1fr);
  }
  .container-pagamentos .container-informacoes-pagamento:last-of-type {
    margin-bottom: 50px;
  }
}