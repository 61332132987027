.overlay-modal-perfil-motorista-carga {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.500);
  z-index: 10000;
}

.modal-perfil-motorista-carga {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 92vh;
  width: 95%;
  background-color: rgba(18, 19, 20, 0.400);
  overflow: auto;
  z-index: 99;
}

.modal-perfil-motorista-carga .detalhes-motorista {
  min-width: 340px;
  margin-right: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 1px 2px 8px 0px;
  overflow-y: auto;
}

.modal-perfil-motorista-carga .todas-informacoes {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  overflow-y: auto;
}

.modal-perfil-motorista-carga .detalhes-motorista .quadro-topo {
  position: relative;
  display: flex;
  height: 150px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
  border-radius: 10px 10px 0 0;
}

.modal-perfil-motorista-carga .detalhes-motorista .quadro-topo .rating {
  flex: 1;
  display: flex;
  justify-content: center;
  pointer-events: none;
  margin-top: 35px;
}

.modal-perfil-motorista-carga .detalhes-motorista .quadro-topo .rating .icone {
  font-size: 25px;
  margin: 0 4px;
}

.modal-perfil-motorista-carga .detalhes-motorista .quadro-topo .rating .icone.vazio {
  color: #fff;
}

.modal-perfil-motorista-carga .detalhes-motorista .quadro-topo .rating .icone.preenchido {
  color: #fac917;
}

.modal-perfil-motorista-carga .detalhes-motorista .quadro-topo .icone-fechar {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 28px;
  color: #31435c;
  cursor: pointer;
}

.modal-perfil-motorista-carga .detalhes-motorista .quadro-topo .icone-fechar:hover {
  filter: brightness(120%);
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -75px;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id img {
  height: 130px;
  width: 130px;
  object-fit: cover;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .telefones {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 5px;
  left: 5px;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .telefones div {
  display: flex;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .telefones div:last-of-type {
  margin-top: 10px;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .telefones div .icone {
  margin-right: 5px;
  color: #3d8af7;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .telefones div p {
  font-size: 12px;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .id {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 30px;
  right: 5px;
  cursor: pointer;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .id:hover {
  transform: scale(1.05);
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .id .icone {
  margin-right: 5px;
  font-size: 18px;
  color: #3d8af7;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .id p {
  font-size: 12px;
}

.modal-perfil-motorista-carga .detalhes-motorista .foto-id .id p i {
  font-weight: bold;
  font-style: normal;
}

.modal-perfil-motorista-carga .detalhes-motorista .dados {
  text-align: center;
  margin-top: 20px;
}

.modal-perfil-motorista-carga .detalhes-motorista .dados .nome {
  font-size: 22px;
  font-weight: bold;
}

.modal-perfil-motorista-carga .detalhes-motorista .dados .cpf {
  margin-top: 8px;
  font-size: 12px;
}

.modal-perfil-motorista-carga .detalhes-motorista .dados .cadastro-app {
  margin-top: 5px;
}

.modal-perfil-motorista-carga .detalhes-motorista .dados .cadastro-app .cadastro-sem-app {
  color: #FF0000;
  font-weight: bold;
}

.modal-perfil-motorista-carga .detalhes-motorista .dados .cadastro-app .cadastro-com-app {
  color: #008000;
  font-weight: bold;
}

.modal-perfil-motorista-carga .detalhes-motorista .veiculo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  padding: 15px 5px;
  border-top: 1px solid #ced9e5;
  border-bottom: 1px solid #ced9e5;
}

.modal-perfil-motorista-carga .detalhes-motorista .veiculo p {
  font-size: 12px;
  text-align: center;
}

.modal-perfil-motorista-carga .detalhes-motorista .veiculo p span {
  font-weight: bold;
}

.modal-perfil-motorista-carga .detalhes-motorista .veiculo .placa {
  font-weight: bold;
}

.modal-perfil-motorista-carga .detalhes-motorista .cidade-distancia {
  margin: 15px 0;
  padding: 0 5px;
}

.modal-perfil-motorista-carga .detalhes-motorista .cidade-distancia .cidades {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.modal-perfil-motorista-carga .detalhes-motorista .cidade-distancia .distancia {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.modal-perfil-motorista-carga .detalhes-motorista .cidade-distancia .editar-paradas {
  display: flex;
  justify-content: flex-end;
}

.modal-perfil-motorista-carga .detalhes-motorista .cidade-distancia .editar-paradas svg {
  cursor: pointer;
}

.modal-perfil-motorista-carga .detalhes-motorista .cidade-distancia .distancia .icone {
  font-size: 20px;
  color: #3d8af7;
  margin-right: 5px;
}

.modal-perfil-motorista-carga .detalhes-motorista .cidade-distancia .distancia p {
  font-weight: bold;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha {
  margin: 8px 5px;
  font-size: 12px;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha span {
  font-weight: bold;
}

.modal-perfil-motorista-carga .detalhes-motorista .separador {
  margin: 0 auto;
  width: 50%;
  background-color: #ced9e5;
  height: 0.5px;
  border: none;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.status {
  display: flex;
  align-items: center;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.status p {
  text-transform: capitalize;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.status .marcador {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 5px;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.status .marcador.no-prazo {
  background-color: #92d36e;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.status .marcador.atraso {
  background-color: #ef2d41;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.status .marcador.possivel-atraso {
  background-color: #fec63d;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.distancia,
.modal-perfil-motorista-carga .detalhes-motorista .linha.confirmacao {
  display: flex;
  align-items: center;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.distancia .icone,
.modal-perfil-motorista-carga .detalhes-motorista .linha.confirmacao .icone {
  font-size: 15px;
  color: #3d8af7;
  margin-right: 5px;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.distancia .dados-distancia p,
.modal-perfil-motorista-carga .detalhes-motorista .linha.confirmacao .dados-confirmacao p {
  margin: 4px 0;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.distancia .dados-distancia div,
.modal-perfil-motorista-carga .detalhes-motorista .linha.confirmacao .dados-confirmacao div {
  display: flex;
  align-items: center;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.distancia .dados-distancia div .icone {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.confirmacao .icone {
  color: #3eedf7;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.confirmacao .rating-cliente .icone.vazio {
  color: #99b2c6;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.confirmacao .rating-cliente .icone.preenchido {
  color: #fac917;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.pagamento {
  display: flex;
  align-items: center;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.pagamento p {
  text-transform: capitalize;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.pagamento .icone {
  font-size: 15px;
  color: #00ff00;
  margin-right: 5px;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico div .icone {
  font-size: 18px;
  margin-right: 5px;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico div p {
  font-weight: bold;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico button.liberar-viagem,
.modal-perfil-motorista-carga .detalhes-motorista .linha.historico button.remover-motorista {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 8px 10px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.116) 0px 3px 8px;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico button.liberar-viagem {
  background-color: #3eedf7;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico button.liberar-entrada {
  background-color: #c58b00;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico button.remover-motorista {
  background-color: red;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico button.liberar-viagem:hover,
.modal-perfil-motorista-carga .detalhes-motorista .linha.historico button.remover-motorista:hover {
  filter: brightness(105%);
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.historico button.liberar-viagem .icone,
.modal-perfil-motorista-carga .detalhes-motorista .linha.historico button.remover-motorista .icone {
  margin-right: 3px;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.recebimento {
  display: flex;
  align-items: center;
}

.modal-perfil-motorista-carga .detalhes-motorista .linha.recebimento .icone {
  font-size: 18px;
  margin-right: 3px;
}

.modal-perfil-motorista-carga .todas-informacoes .container-abas {
  display: flex;
  flex-wrap: wrap;
  background-color: #99b2c6;
  border-radius: 8px;
}

.modal-perfil-motorista-carga .todas-informacoes .container-abas .aba {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 15px 0;
  cursor: pointer;
  border-radius: 8px;
}

.modal-perfil-motorista-carga .todas-informacoes .container-abas .aba.selecionada {
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.modal-perfil-motorista-carga .todas-informacoes .container-abas .aba.selecionada p {
  color: #fff;
}

.modal-perfil-motorista-carga .todas-informacoes .container-abas .aba.selecionada .icone {
  color: #fff;
}

.modal-perfil-motorista-carga .todas-informacoes .container-abas .aba .icone {
  font-size: 20px;
  margin-right: 5px;
  color: #172b4d;
}

.modal-perfil-motorista-carga .todas-informacoes .container-abas .aba p {
  font-weight: bold;
  font-size: 18px;
  color: #172b4d;
}

.container-alert-liberar-viagem {
  width: 500px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
  animation-duration: 0.1s;
}

.container-alert-liberar-viagem .titulo {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.container-alert-liberar-viagem .titulo p {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.container-alert-liberar-viagem .titulo .icone.fechar {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.container-alert-liberar-viagem .titulo .icone.fechar:hover {
  filter: brightness(120%);
}

.container-alert-liberar-viagem .conteudo {
  padding: 20px;
}

.container-alert-liberar-viagem .conteudo .pergunta {
  font-size: 16px;
  font-weight: bold;
}

.container-alert-liberar-viagem .conteudo .detalhes-viagem {
  display: flex;
}

.container-alert-liberar-viagem .container-botoes-liberar {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.container-alert-liberar-viagem .container-botoes-liberar button {
  width: 150px;
  padding: 8px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: bold;
  color: #fff;
}

.container-alert-liberar-viagem .container-botoes-liberar button:hover {
  filter: brightness(120%);
}

.container-alert-liberar-viagem .container-botoes-liberar button.negar {
  background-color: #ef2d41;
}

.container-alert-liberar-viagem .container-botoes-liberar button.liberar {
  background-color: #92d36e;
}

.container-alert-liberar-entrada {
  width: 500px;
  background-color: #fff;
  border: 2px solid #3c89f5;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
  animation-duration: 0.1s;
}

.container-alert-liberar-entrada .titulo {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 6px 6px 0 0;
  padding-right: 10px;
  background: linear-gradient(rgba(60, 137, 245, 255), 70%, transparent);
  background-color: #20477e;
}

.container-alert-liberar-entrada .titulo p {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.container-alert-liberar-entrada .titulo .icone.fechar {
  font-size: 28px;
  color: #99b2c6;
  cursor: pointer;
}

.container-alert-liberar-entrada .titulo .icone.fechar:hover {
  filter: brightness(120%);
}

.container-alert-liberar-entrada .conteudo {
  padding: 20px;
}

.container-alert-liberar-entrada .conteudo .pergunta {
  font-size: 16px;
  font-weight: bold;
}

.container-alert-liberar-entrada .conteudo .detalhes-entrada {
  display: flex;
}

.container-alert-liberar-entrada .container-botoes-liberar {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.container-alert-liberar-entrada .container-botoes-liberar button {
  width: 100px;
  padding: 8px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.container-alert-liberar-entrada .container-botoes-liberar button.negar {
  background-color: #ef2d41;
}

.container-alert-liberar-entrada .container-botoes-liberar button.liberar {
  background-color: #92d36e;
}

.alert-realizar-pagamento {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-realizar-pagamento .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-realizar-pagamento button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-realizar-pagamento button:hover {
  filter: brightness(120%);
}

.alert-realizar-pagamento button.botao-confirmar {
  background-color: #cc3300;
}

.alert-realizar-pagamento button.botao-cancelar {
  background-color: #001b48;
}

@media (max-width: 500px) {
  .container-alert-liberar-entrada {
    width: 95%;
    margin: 0 auto;
  }

  .container-alert-liberar-entrada .conteudo {
    padding: 20px 5px;
  }
}

@media (max-width: 980px) {
  .modal-perfil-motorista-carga {
    width: 98%;
  }

  .modal-perfil-motorista-carga .detalhes-motorista {
    margin-right: 5px;
  }
}

@media (max-width: 600px) {
  .overlay-modal-perfil-motorista-carga {
    overflow-y: scroll;
  }

  .modal-perfil-motorista-carga {
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    transform: none;
    height: auto;
  }

  .modal-perfil-motorista-carga .detalhes-motorista {
    margin: 0 0 10px 0;
    padding-bottom: 20px;
  }
}