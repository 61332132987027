.container-input-search-cnpj .container-input-comum {
  width: 100%;
  margin-right: 10px;
}

.container-input-search-cnpj .container-input-comum span {
  color: #fff;
}

.container-input-search-cnpj .container-input-comum input {
  width: 100%;
  background-color: #97cadb;
}

.container-input-search-cnpj .container-input-comum input:disabled {
  color: #fff;
}

.container-input-search-cnpj .container-input-comum input:focus+span,
.container-input-search-cnpj .container-input-comum input:disabled+span,
.container-input-search-cnpj .container-input-comum input:valid+span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.container-input-search-cnpj .no-suggestions {
  color: #9ab2c6;
  padding: 0.5rem;
}

.container-input-search-cnpj .no-suggestions span {
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
}

.container-input-search-cnpj .no-suggestions span:hover {
  filter: brightness(80%);
}

.container-input-search-cnpj .suggestions {
  border: 1px solid #9ab2c6;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 160px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
}

.container-input-search-cnpj .suggestions li {
  padding: 0.5rem;
}

.container-input-search-cnpj .suggestion-active,
.container-input-search-cnpj .suggestions li:hover {
  cursor: pointer;
  background-color: #f1f4fa;
}

.container-input-search-cnpj .suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #9ab2c6;
}