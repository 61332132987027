.container-editar-relatorio {
  padding: 10px;
  background-color: #97cadb;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-editar-relatorio .container-inputs-4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2%;
  margin-top: 15px;
}

.container-editar-relatorio .container-inputs-3col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2%;
  margin-top: 20px;
}

.container-editar-relatorio .container-input-comum {
  position: relative;
}

.container-editar-relatorio .container-input-comum input,
.container-editar-relatorio .container-input-comum select {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-editar-relatorio .container-input-comum input:disabled {
  color: #3d8af7;
}

.container-editar-relatorio .container-input-comum span {
  position: absolute;
  top: 11px;
  left: 15px;
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.4s;
  font-size: 13px;
  color: gray;
}

.container-editar-relatorio .container-input-comum input:focus + span,
.container-editar-relatorio .container-input-comum input:disabled + span,
.container-editar-relatorio .container-input-comum input:valid + span,
.container-editar-relatorio .container-input-comum select:focus + span,
.container-editar-relatorio .container-input-comum select:disabled + span,
.container-editar-relatorio .container-input-comum select:valid + span {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #9ab2c6;
  background-color: #fff;
}

.container-editar-relatorio .container-input-select {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-editar-relatorio .container-input-select select {
  background-color: #d6e8ee;
  padding: 10px 5px 10px 10px;
  border: none;
  border-radius: 8px;
  color: #8094aa;
}

.container-editar-relatorio .container-input-select input {
  width: 100%;
  background-color: #fff;
  padding: 10px 5px;
  border: none;
  border-radius: 0 8px 8px 0;
}

.container-editar-relatorio .btn-salvar {
  display: block;
  margin: 20px auto 0 auto;
  background-color: #3c89f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 6px 20px;
}

.container-editar-relatorio .btn-salvar:hover {
  filter: brightness(110%);
}

@media (max-width: 1030px) {
  .container-editar-relatorio .container-inputs-4col {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 15px;
  }
  
  .container-editar-relatorio .container-inputs-3col {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 15px;
  }
}

@media (max-width: 750px) {
  .container-editar-relatorio .container-inputs-4col {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .container-editar-relatorio .container-inputs-3col {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 640px) {
  .container-editar-relatorio .container-inputs-4col {
    grid-template-columns: 1fr;
  }
}