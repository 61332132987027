.no-itens-box {
  width: 100%;
  min-height: 200px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;

  .text {
    text-align: center;
    font-size: 24px;
    color: #000;
    opacity: 0.6;
  }

  .create-button {
    background-color: #001b48;
    border: 1px solid #001b48;
    color: #fff;

    font-size: 16px;

    padding: 16px 32px;
    border-radius: 8px;

    &:hover {
      filter: brightness(120%);
    }
  }
}