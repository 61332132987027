.container-grupos-motoristas {
  width: 100vw;
  margin: 10px 10px 15px 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 40px;
}

.container-grupos-motoristas .btn-voltar-pagina {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #f1f4fa;
  border: 2px solid #9ab2c6;
  font-weight: bold;
  margin-bottom: 20px;
}

.container-grupos-motoristas .btn-voltar-pagina:hover {
  filter: brightness(120%);
}

.container-grupos-motoristas .grupos-motoristas .container-topo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-grupos-motoristas .grupos-motoristas .container-topo .titulo-pagina {
  font-size: 20px;
  font-weight: bold;
}

.container-grupos-motoristas .grupos-motoristas .container-topo .subtitulo-pagina {
  margin-top: 5px;
  font-weight: bold;
  color: #001b48;
}

.container-grupos-motoristas .grupos-motoristas .container-topo .btn-novo-grupo {
  width: 150px;
  display: flex;
  align-items: center;
  padding: 12px 8px;
  background-color: #001b48;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
}

.container-grupos-motoristas .grupos-motoristas .container-topo .btn-novo-grupo:hover {
  filter: brightness(120%);
}

.container-grupos-motoristas .grupos-motoristas .container-topo .btn-novo-grupo .icone {
  font-size: 25px;
  margin-right: 10px;
}

.container-grupos-motoristas .grupos-motoristas .container-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 50px 0;
}

.container-grupos-motoristas .grupos-motoristas .container-search .input-search {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 12px 10px 12px 10px;
  background-color: #f1f4fa;
  border: 1px solid #9ab2c6;
  border-radius: 8px;
}

.container-grupos-motoristas .grupos-motoristas .container-search .input-search input {
  flex: 1;
  border: none;
  background-color: #f1f4fa;
}

.container-grupos-motoristas .grupos-motoristas .container-search .input-search input::placeholder {
  color: gray;
}

.container-grupos-motoristas .grupos-motoristas .container-search .input-search .icone {
  margin-left: 5px;
  font-size: 22px;
  color: #9ab2c6;
}

.container-grupos-motoristas .grupos-motoristas .container-search .btn-buscar {
  width: 120px;
  background-color: #001b48;
  margin-left: 25px;
  height: 48px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.container-grupos-motoristas .grupos-motoristas .container-search .btn-buscar:hover {
  filter: brightness(120%);
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas {
  margin-top: 20px;
  max-width: 100%;
  overflow: auto;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  white-space: nowrap;
  text-align: center;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table thead tr th {
  padding: 10px 0;
  border-bottom: 1px solid #9ab2c6;
  font-size: 16px;
  color: black;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table tbody tr.divisoria-linhas-tabela {
  height: 10px;
  background-color: #fff;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table tbody tr td {
  background-color: #f1f4fa;
  padding: 15px 10px;
  color: gray;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table tbody tr td:last-child {
  border-radius: 0px 8px 8px 0px;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table .coluna-nome {
  width: 75%;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table .coluna-acoes .icone {
  margin: 0 3px;
  font-size: 22px;
  color: #001b48;
  cursor: pointer;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table .coluna-acoes .icone.motorista {
  color: black;
}

.container-grupos-motoristas .grupos-motoristas .container-tabela-grupos-motoristas table .coluna-acoes .icone:hover {
  filter: brightness(120%);
}

.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas {
  margin-top: 20px;
}

.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>.active>a {
  background-color: #0d78f2;
  color: black;
}

.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>li>a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>.active>a,
.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>.active>span,
.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>.active>a:hover,
.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>.active>span:hover,
.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>.active>a:focus,
.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>.active>span:focus {
  background-color: #001b48;
  color: #fff;
  border-color: #fff;
  outline: none;
}

.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>li>a,
.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>li>span {
  background-color: #9ab2c6;
  color: black;
}

.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>li>a:hover {
  filter: brightness(120%);
}

.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>li:first-child>a,
.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>li:first-child>span,
.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>li:last-child>a,
.container-grupos-motoristas .grupos-motoristas .container-paginacao-grupos-motoristas .pagination>li:last-child>span {
  border-radius: 5px
}


.alert-remover-grupo-motoristas  {
  z-index: 2000;
  animation-duration: 0.2s;
}

.alert-remover-grupo-motoristas  .react-confirm-alert-body {
  color: #1c2732;
  padding: 30px 50px;
}

.alert-remover-grupo-motoristas button {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.alert-remover-grupo-motoristas button:hover {
  filter: brightness(120%);
}

.alert-remover-grupo-motoristas button.botao-confirmar {
  background-color: #cc3300;
}

.alert-remover-grupo-motoristas button.botao-cancelar {
  background-color: #001b48;
}

@media (max-width: 670px) {
  .container-grupos-motoristas{
    padding: 25px 10px;
    overflow-y: hidden;
  }
  .container-grupos-motoristas .grupos-motoristas .container-topo {
    flex-direction: column;
    text-align: center;
  }
  .container-grupos-motoristas .grupos-motoristas .container-topo .btn-novo-grupo {
    width: 100%;
    justify-content: center;
    margin-top: 15px;
  }
  .container-grupos-motoristas .grupos-motoristas .container-search {
    flex-direction: column;
    width: 100%;
  }
  .container-grupos-motoristas .grupos-motoristas .container-search .input-search {
    width: 100%;
  }
  .container-grupos-motoristas .grupos-motoristas .container-search .btn-buscar {
    width: 100%;
    margin: 10px 0 0 0;
  }
}