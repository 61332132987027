.container-tabela-ctes {
  margin-top: 20px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-tabela-ctes table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  text-align: center;
}

.container-tabela-ctes table thead tr th {
  padding: 10px 3px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  border-right: 1px solid #fff;
  background-color: #3d8af7;
}

.container-tabela-ctes table thead tr th:first-of-type {
  border-top-left-radius: 5px;
}

.container-tabela-ctes table thead tr th:last-of-type {
  border-top-right-radius: 5px;
}

.container-tabela-ctes table tbody tr td {
  padding: 15px;
  font-size: 12px;
  border-right: 1px solid #fff;
}

.container-tabela-ctes table tbody tr.linha td {
  background-color: #d6e8ee;
}

.container-tabela-ctes table tbody tr.linha.notas,
.container-tabela-ctes table tbody tr.linha-rodape {
  border-top: 1px solid #fff;
}

.container-tabela-ctes table tbody tr.linha-rodape td {
  background-color: #9ec5fb;
}

.container-tabela-ctes table tbody tr.linha.notas td {
  text-align: left;
}

.container-tabela-ctes table tbody tr.linha.notas span {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: #001b48;
}

.container-tabela-ctes table .coluna-destaque {
  font-weight: bold;
}

.container-tabela-ctes table .coluna-destaque.carga span {
  cursor: pointer;
  text-decoration: underline;
}

.container-tabela-ctes table .coluna-acoes {
  padding: 5px;
}

.container-tabela-ctes table .coluna-acoes img {
  height: 25px;
}

.container-tabela-ctes table .coluna-acoes .icone {
  font-size: 25px;
}

.container-tabela-ctes table .coluna-acoes .icone.remover {
  color: #ff0000;
}

.container-tabela-ctes table .coluna-acoes img:hover,
.container-tabela-ctes table .coluna-acoes .icone:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.container-tabela-ctes table td.coluna-informacoes {
  text-align: left;
}

.container-tabela-ctes table td.coluna-informacoes span {
  color: #3d8af7;
}

.container-tabela-ctes table td.coluna-motorista span {
  color: #ff0000;
  text-decoration: underline;
  cursor: pointer;
}

.container-tabela-ctes .container-loading-documentos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.coluna-notas-pad-left {
  padding-left: 10px;
}
