.pagamento-pix {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.pagamento-pix .titulo {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #001b48;
}

.pagamento-pix .subtitulo {
  font-weight: 500;
  color: #001b48;
  margin-bottom: 15px;
}

.pagamento-pix .subtitulo.centralizado {
  text-align: center;
}

.pagamento-pix .quadro-totais {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #3eedf7;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.pagamento-pix .quadro-totais p {
  font-size: 16px;
  font-weight: bold;
  color: #fff
}

.pagamento-pix .quadro-totais input {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.192) 0px 2px 5px;
  text-align: center;
  font-weight: bold;
}

.pagamento-pix .quadro-totais input::placeholder {
  font-size: 12px;
}

.pagamento-pix .separador {
  border: 1px solid #ced9e5;
  background-color: #ced9e5;
  margin: 0 0 15px 0;
}

.pagamento-pix .icone-pagamento {
  margin: 0 auto 20px auto;
  height: 50px;
  width: 50px;
}

.pagamento-pix .icone-qr-code {
  margin: 20px auto;
  height: 120px;
  width: 120px;
}

.pagamento-pix .codigo {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.pagamento-pix .codigo input {
  background-color: #f1f4fa;
  padding: 0 10px;
  border: none;
  border-radius: 8px;
  height: 60px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: #001b48;
  pointer-events: none;
}

.pagamento-pix .codigo img {
  height: 35px;
  width: 35px;
  margin-left: 5px;
  cursor: pointer;
}

.pagamento-pix button {
  margin: auto auto 0 auto;
  border: none;
  border-radius: 8px;
  background-color: #018abe;
  padding: 12px 0;
  width: 200px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 5px 0px;
}

.pagamento-pix button:hover {
  filter: brightness(120%);
}